<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">名目详情</div>
    </div>
    <!-- 配件信息 -->
    <div class="nav">
      <div class="nav-left">
        <img src="https://img01.yzcdn.cn/vant/ipad.jpeg" alt="" class="pic" />
      </div>
      <div class="nav-right">
        <div class="hint">
          <div class="hint-title">货物名称:{{ it.goods_name }}</div>
          <div class="hint-title">货物编号:{{ it.goods_no }}</div>
          <div class="hint-title">货物详情:{{ it.goods_desc }}</div>
        </div>
      </div>
    </div>

    <!-- 仓库可以信息 -->
    <div class="tit">公共仓库</div>
    <!-- 列表 -->
    <van-loading type="spinner" color="#1989fa" v-if="loading" />
    <div class="main">
      <div class="main-one">仓库名称</div>
      <div class="main-two">库存数量</div>
      <div class="main-ser">可出库数量</div>
    </div>

    <div class="main clo" v-for="(item, index) in list" :key="index">
      <div class="main-one">{{ item.house_name }}</div>
      <div class="main-two">{{ item.goods_num }}</div>
      <div class="main-ser">{{ item.batch_sum }}</div>
    </div>

    <div class="xia">
      <van-button type="info" class="btns" @click="$router.go(-1)">返回上一层</van-button>
    </div>
  </div>
</template>

<script>
import { Huofen } from "../../api/store";
export default {
  data() {
    return {
      it: {},
      id: "",
      list: [],
      loading: true,
    };
  },
  created() {
    this.it = this.$route.query; // 获取上个页面传过来的id
    this.Huofenlist(this.it.id);
  },
  beforeRouteLeave(to, from, next) {
    to.meta.keepAlive = true;
    next(0);
  },

  methods: {
    async Huofenlist(id) {
      const { data } = await Huofen({ goods_id: id });
      // console.log(data);
      this.list = data.data;
      this.loading = false;
    },
  },
};
</script>

<style scoped  lang="less">
.box {
  background-color: #fff;
  box-sizing: border-box;
  .nav {
    background-color: #f5f5f5;
    width: 100%;
    height: 30vh;
    box-sizing: border-box;
    display: flex;
    .nav-left {
      width: 40%;
      // background-color: aqua;
      .pic {
        margin-top: 10%;
        width: 100%;
        height: 80%;
      }
    }
    .nav-right {
      width: 60%;
      // background-color: chocolate;
      box-sizing: border-box;
      .hint {
        width: 95%;
        height: 80%;
        // border: 1px solid #000;
        box-sizing: border-box;
        margin-top: 5%;
        padding: 10% 3%;
        .hint-title {
          text-align: left;
          font-size: 15px;
          width: 100%;
          // border: 1px solid #000;
          margin-top: 5%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .tit {
    width: 80%;
    text-align: left;
    margin-left: 3%;
    font-size: 19px;
    margin-top: 3%;
  }
  .main {
    box-sizing: border-box;
    width: 100%;
    height: 6vh;
    // background-color: palevioletred;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .main-one {
      width: 40%;
    }
    .main-two {
      width: 35%;
    }
    .main-ser {
      width: 35%;
    }
  }
  .clo {
    background-color: #f5f5f5;
  }
}
</style>
