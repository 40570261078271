<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">货物报损</div>
    </div>
    <div class="user">
      <div class="user-left">
        <div class="user-left-one">报损人</div>
        <div>{{ username }}</div>
      </div>
      <div class="user-left">
        <div class="user-left-one">入库仓库</div>
        <div>{{ rucangname }}</div>
      </div>

      <div class="user-left" @click="tan">
        <div class="user-left-one">出库仓库</div>
        <div>{{ cangname }}</div>
      </div>
      <div class="user-left">
        <div class="user-left-one">责任仓库</div>
        <div>{{ cangname }}</div>
      </div>

      <div class="user-left" @click="type">
        <div class="user-left-one">报损类型</div>
        <div>{{ typename }}</div>
      </div>

      <van-popup
        v-model="typeshwo"
        position="bottom"
        :style="{ height: '30%' }"
        close-on-click-overlay
      >
        <van-picker
          title="选择报损类型"
          value-key="reason"
          show-toolbar
          :columns="typelist"
          @confirm="onConfirmtype"
          @cancel="onCanceltype"
        />
      </van-popup>

      <van-popup
        v-model="show"
        position="bottom"
        :style="{ height: '30%' }"
        close-on-click-overlay
      >
        <van-picker
          title="选择仓库"
          value-key="name"
          show-toolbar
          :columns="cang"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
    </div>
    <van-loading type="spinner" color="#1989fa" v-if="loading" />

    <div class="hint bold" style="color:red">调配待扫码货物信息</div>
    <div class="list-box">
      <div class="huolist">
        <div class="huo-ones bold">批次号</div>
        <div class="huo-onrs bold">货物名称</div>
        <div class="huo-onts bold">货物详情</div>
        <div class="huo-onys bold">数量</div>
      </div>
      <div class="huolist lie" v-for="item in goods_list" :key="item.index">
        <div class="huo-ones size">{{ item.batch_no }}</div>
        <div class="huo-onrs size">{{ item.goods_name }}</div>
        <div class="huo-onts size">{{ item.goods_desc }}</div>
        <div class="huo-onys size">
          <input type="number" v-model="item.item_num" />
        </div>
      </div>
    </div>

    <!-- <div class="users"> -->
    <!-- <div class="user-left" @click="type">
        <div class="user-left-one">报损类型</div>
        <div>{{ typename }}</div>
      </div> -->
    <!-- <div class="user-left" @click="zetan">
        <div class="user-left-one">选择责任仓库</div>
        <div>{{ zehouse }}</div>
      </div> -->
    <!-- </div> -->
    <!-- <van-popup
      v-model="typeshwo"
      position="bottom"
      :style="{ height: '30%' }"
      close-on-click-overlay
    >
      <van-picker
        title="选择报损类型"
        value-key="name"
        show-toolbar
        :columns="typelist"
        @confirm="onConfirmtype"
        @cancel="onCanceltype"
      />
    </van-popup> -->

    <!-- <van-popup
      v-model="zeshow"
      position="bottom"
      :style="{ height: '30%' }"
      close-on-click-overlay
    >
      <van-picker
        title="选择责任仓库"
        value-key="name"
        show-toolbar
        :columns="cang"
        @confirm="onConfirmze"
        @cancel="onCancelzeren"
      />
    </van-popup> -->

    <div class="xia">
      <van-button type="info" @click="fanggetScroll" class="btns">提交</van-button>
    </div>
  </div>
</template>

<script>
import { Debounce } from "@/utils/common.js";
import { Dialog, Notify } from "vant";
import { Cang } from "../../api/index.js";
import { Daichuli, Deployscrap, Cause } from "../../api/store.js";
export default {
  data() {
    return {
      inp: "",
      show: false,
      username: "",
      cangname: "",
      cang: [],
      hose: "",
      loading: true,
      goods_list: [],
      typename: "",
      typeshwo: false,
      zehouse: "",
      zeshow: false,
      rucangname: "",
      typelist: [],
      dfrom: {
        order_id: "",
        house_id: "",
        items: [],
        reason_type: "",
        reason_remark: "",
      },
    };
  },
  created() {
    this.rucangname = this.$route.query.name;
    this.Canglist();
    this.username = localStorage.getItem("username");
    this.id = this.$route.query.id;
    this.Causes();
  },
  mounted() {
    this.dfrom.order_id = this.id;
  },
  methods: {
    fanggetScroll: Debounce(function () {
      this.tijiao();
    }, 600),

    // 报损类型
    type() {
      this.typeshwo = true;
    },
    // 弹出层
    tan() {
      this.show = true;
    },
    // 责任
    zetan() {
      this.zeshow = true;
    },

    // 提交
    tijiao() {
      if (this.dfrom.house_id === "") {
        Dialog.alert({
          title: "提示",
          message: `请选择出库仓库`,
        }).then(() => {
          // on close
        });
      } else if (this.dfrom.reason_type === "") {
        Dialog.alert({
          title: "提示",
          message: `请选择报损类型`,
        }).then(() => {
          // on close
        });
      } else {
        this.dfrom.items = [];
        for (let i = 0; i < this.goods_list.length; i++) {
          if (this.goods_list[i].item_num !== "") {
            let obj = {
              batch_no: this.goods_list[i].batch_no,
              goods_id: this.goods_list[i].goods_id,
              item_num: Number(this.goods_list[i].item_num),
            };
            this.dfrom.items.push(obj);
          }
        }
        if (this.dfrom.items.length === 0) {
          Dialog.alert({
            title: "提示",
            message: `请填写报损数量`,
          }).then(() => {
            // on close
          });
        } else {
          this.Deployscraps(this.dfrom);
          this.loading = true;
        }
      }
    },

    // 仓库确认
    onConfirm(val) {
      this.goods_list.length = 0;
      // console.log(val);
      this.cangname = val.name;
      this.dfrom.house_id = val.value;
      this.show = false;
      this.Daichulilist(val.value);
    },

    // 类型确认
    onConfirmtype(val) {
      // console.log(val);
      this.dfrom.reason_type = val.tag;
      this.typeshwo = false;
      this.typename = val.reason;
    },

    // 责任确定
    onConfirmze(val) {
      // console.log(val);
      this.zeshow = false;
      this.zehouse = val.name;
    },

    // 取消
    onCancelzeren() {
      this.zeshow = false;
    },

    onCancel() {
      this.show = false;
    },
    onCanceltype() {
      this.typeshow = false;
    },

    // 拉取仓库列表
    async Canglist() {
      const { data } = await Cang();
      this.cang = data.data;
      this.loading = false;
    },

    async Causes() {
      const { data } = await Cause();
      // console.log(data);
      this.typelist = data.data;
    },

    // 配件调仓报损
    async Deployscraps(v) {
      // let ss = JSON.parse(JSON.stringify(v));
      // ss.reason_type = JSON.stringify(ss.reason_type);
      // ss.reason_remark = JSON.stringify(ss.reason_remark);
      const { data } = await Deployscrap(v);
      console.log(data);
      this.loading = false;
      if (data.code === 200) {
        Dialog.alert({
          title: "提示",
          message: `报损成功。`,
        }).then(() => {
          // on close
          this.dfrom.items = [];
          this.$router.go(-1);
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: `${data.message},报损失败,请重新提交`,
        }).then(() => {
          this.dfrom.items = [];
        });
      }
    },

    // 拉取待扫码信息
    async Daichulilist(house_id) {
      const { data } = await Daichuli({
        order_id: this.id,
        addition_type: "scrap",
        house_id: house_id,
      });
      // console.log(data);
      this.loading = false;
      let list = data.data;
      list.forEach((item) => {
        let obj = {
          batch_no: item.batch_no,
          goods_desc: item.goods_desc,
          goods_id: item.goods_id,
          goods_name: item.goods_name,
          goods_no: item.goods_no,
          goods_num: item.goods_num,
          item_num: "",
        };
        this.goods_list.push(obj);
      });
      // console.log(this.goods_list);
      if (data.data.length === 0) {
        Dialog.alert({
          title: "提示",
          message: `没有找到数据`,
        }).then(() => {
          // on close
          // this.$router.go(-1);
        });
      }
    },
  },
};
</script>

<style scoped lang='less' >
.top {
  width: 100%;
  height: 8vh;
  /* border: 1px solid #000; */
  display: flex;
  position: fixed;
  top: 0%;
  left: 0%;
  align-items: center;
  background-color: #dbd5d5;
  z-index: 1000;
}
.user {
  margin-top: 15%;
  height: 32vh;
}
.list-box {
  font-size: 14px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
}
.hint {
  width: 80%;
  margin-left: 5px;
  text-align: left;
}
.nav {
  width: 100%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  padding: 10px 5px;
}
.bold {
  font-weight: bold;
}
.huo-ones {
  width: 25%;
}
.huo-onrs {
  width: 32%;
  text-align: left;
}

.huo-onts {
  width: 32%;
  text-align: left;
}

.huo-onys {
  width: 10%;
}
.xia {
  width: 100%;
  height: 10vh;
  margin-top: 15px;
  text-align: center;
  line-height: 10vh;
}
.btns {
  border-radius: 5px;
  width: 40%;
}
.flex {
  display: flex;
  justify-content: space-around;
}
.hint {
  width: 80%;
  /* margin-left: 10px; */
  text-align: left;
  height: 5vh;
  line-height: 5vh;
  margin-top: 10px;
}
input {
  width: 38px;
  border-radius: 5px;
}
.user {
  margin-top: 15%;
  width: 100%;
  height: 27vh;
  /* border: 1px solid #000; */
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px 10px;
}
.user-left {
  display: flex;
  margin-top: 15px;
  align-items: center;
}
.user-left-one {
  width: 20%;
  text-align: left;
  margin-right: 10px;
}
.huolist {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px 5px;
}
</style>