import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import index from '../views/index.vue'
import dailist from '../views/dailist.vue'
import chujilu from '../views/chujilu.vue'
import rujilu from '../views/rujilu.vue'
import chusao from '../views/chusao.vue'
import daixiang from '../views/daixiang.vue'
import ruxiang from '../views/ruxiang.vue'
import chuxiang from '../views/chuxiang.vue'
import inventory from '../views/stoer/inventory.vue'
import accessories from '../views/stoer/accessories.vue'
import deploylist from '../views/stoer/deploylist.vue'
import deployfor from '../views/stoer/deployfor.vue'
import shop from '../views/stoer/shop.vue'
import shopxiang from '../views/stoer/shopxiang.vue'
import trade from '../views/stoer/trade.vue'
import deployQR from '../views/stoer/deployQR.vue'
import checkQR from '../views/stoer/checkQR.vue'
import frmLoss from '../views/stoer/frmLoss.vue'
import items from '../views/stoer/items.vue'
import itemsxiang from '../views/stoer/itemsxiang.vue'
import fromtype from '../views/stoer/fromtype.vue'
import fromlist from '../views/stoer/fromlist.vue'
import approve from '../views/stoer/approve.vue'
import newwork from '../views/maintain-work/newwork.vue'
import select from '../views/maintain-work/select.vue'
import workdetail from '../views/maintain-work/workdetail.vue'
import ingredient from '../views/ingredient/index.vue'
import purchase from '../views/ingredient/purchase.vue'
import the from '../views/ingredient/the.vue'
import outsource from '../views/ingredient/outsource.vue'
import business from '../views/ingredient/business.vue'
import delivery from '../views/ingredient/delivery.vue'
import busdetail from '../views/ingredient/business-detail.vue'  // 业务费用详情
import deldetail from '../views/ingredient/delivery-detail.vue'  // 出库货物详情
import outdetail from '../views/ingredient/outsource-detail.vue' // 外协事项详情
import purdetail from '../views/ingredient/purchase-detail.vue'  // 自行采购详情
import order from '../views/order/index.vue'
import neworder from '../views/order/neworder.vue'
import orderdetail from '../views/order/orderdetail.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: login
  },
  {
    path: '/index',
    name: 'index',
    component: index
  },
  {
    path: '/dailist',
    name: 'dailist',
    component: dailist
  }, {
    path: '/chujilu',
    name: 'chujilu',
    component: chujilu
  }, {
    path: '/rujilu',
    name: 'rujilu',
    component: rujilu
  }, {
    path: '/chusao',
    name: 'chusao',
    component: chusao
  },
  {
    path: '/daixiang',
    name: 'daixiang',
    component: daixiang
  },
  {
    path: '/ruxiang',
    name: 'ruxiang',
    component: ruxiang
  },
  {
    path: '/chuxiang',
    name: 'chuxiang',
    component: chuxiang
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: inventory,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/accessories',
    name: 'accessories',
    component: accessories
  },
  {
    path: '/deploylist',
    name: 'deploylist',
    component: deploylist
  },
  {
    path: '/deployfor',
    name: 'deployfor',
    component: deployfor
  },
  {
    path: '/shop',
    name: 'shop',
    component: shop
  },
  {
    path: '/shopxiang',
    name: 'shopxiang',
    component: shopxiang
  },
  {
    path: '/trade',
    name: 'trade',
    component: trade
  },
  {
    path: '/deployQR',
    name: 'deployQR',
    component: deployQR
  },
  {
    path: '/checkQR',
    name: 'checkQR',
    component: checkQR
  },
  {
    path: '/frmLoss',
    name: 'frmLoss',
    component: frmLoss
  },
  {
    path: '/items',
    name: 'items',
    component: items
  },
  {
    path: '/itemsxiang',
    name: 'itemsxiang',
    component: itemsxiang
  },
  {
    path: '/fromtype',
    name: 'fromtype',
    component: fromtype
  },
  {
    path: '/fromlist',
    name: 'fromlist',
    component: fromlist
  },
  {
    path: '/approve',
    name: 'approve',
    component: approve
  },
  {
    path: '/newwork',
    name: 'newwork',
    component: newwork,
    // meta: {
    //   keepAlive: true,
    // }
  },
  {
    path: '/select',
    name: 'select',
    component: select,
    // meta: {
    //   keepAlive: true,
    //   isBack: false
    // }
  },
  // {
  //   path: '/workdetail',
  //   name: '/workdetail',
  //   component: workdetail
  // },
  {
    path: '/workdetail',
    name: '/workdetail',
    component: workdetail
  },
  {
    path: '/ingredient',
    name: '/ingredient',
    component: ingredient,
  },
  {
    path: '/purchase',
    name: '/purchase',
    component: purchase
  },
  {
    path: '/the',
    name: '/the',
    component: the,
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/outsource',
    name: '/outsource',
    component: outsource,
  },
  {
    path: '/business',
    name: '/business',
    component: business,
  },
  {
    path: '/delivery',
    name: '/delivery',
    component: delivery,
  },
  {
    path: '/busdetail',
    name: '/busdetail',
    component: busdetail,
  },
  {
    path: '/deldetail',
    name: '/deldetail',
    component: deldetail,
  },
  {
    path: '/outdetail',
    name: '/outdetail',
    component: outdetail,
  },
  {
    path: '/purdetail',
    name: '/purdetail',
    component: purdetail,
  },
  {
    path: '/order',
    name: '/order',
    component: order,
  },
  {
    path: '/neworder',
    name: '/neworder',
    component: neworder,
  },
  {
    path: '/orderdetail',
    name: '/orderdetail',
    component: orderdetail,
  }

]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // 判断有没有登录
  if (!localStorage.getItem("token")) {
    if (to.path == "/") {
      next();
    } else {
      router.push("/");
    }
  } else {
    next();
  }
});
export default router
