<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">维修工单详情</div>
    </div>
    <div class="froms">
      <van-field v-model="order_user_name" label="维修工程师" readonly />

      <van-field v-model="create_time" label="维修时间" readonly />

      <van-field v-model="device_name" label="维修设备名称" readonly />

      <van-field v-model="device_date" label="维修设备生产日期" readonly />
      <van-field
        v-model="fault_desc"
        rows="2"
        autosize
        label="故障描述"
        type="textarea"
        maxlength="500"
        show-word-limit
        readonly
      />
      <van-field
        v-model="maintain_scheme"
        rows="2"
        autosize
        label="维修方案"
        type="textarea"
        maxlength="500"
        show-word-limit
        readonly
      />
      <div class="hint">故障图片</div>
      <div class="image">
        <van-image
          v-for="item in fault_images"
          :key="item"
          width="100"
          height="100"
          :src="item"
          class="img"
          @click="guclick"
        />
      </div>
      <div class="hint">完成图片</div>
      <div class="image">
        <van-image
          v-for="item in maintain_images"
          :key="item"
          width="100"
          height="100"
          :src="item"
          class="img"
          @click="wanclick"
        />
      </div>
      <div class="hint" style="color: red">货物消耗</div>
      <section class="content">
        <div class="lings">
          <div class="item blod">货物编号</div>
          <div class="item blod">货物名称</div>
          <div class="item blod">单价</div>
          <div class="item blod">数量</div>
          <div class="item blod">小计</div>
        </div>
        <div
          v-for="(item, index) in this.goods_items"
          :key="index"
          class="lings"
        >
          <div class="item">{{ item.goods_no }}</div>
          <div class="item">{{ item.goods_name }}</div>
          <div class="item">{{ item.goods_price / 100 }}</div>
          <div class="item">{{ item.goods_num }}</div>
          <div class="item">{{ item.amount / 100 }}</div>
        </div>
      </section>

      <div class="mayns">
        <div>货物总计</div>
        <div>{{ totalCount / 100}} 元</div>
      </div>
    </div>

    <div class="hint" style="color: red">
      维修费用
    </div>
    <div class="worktime">
      <div>项目</div>
      <div>单价(元)</div>
      <div>时长(h)</div>
      <div>合计(元)</div>
    </div>
    <div class="worktime" style="border-top: none">
      <div>工单工时</div>
      <div>{{ work_time_price / 100 }}</div>
      <div>{{ work_time_num }}</div>
      <div>{{ work_time_amount / 100 }}</div>
    </div>

    <div class="car" style="border-top: none">
      <div>出车费</div>
      <div>{{ car_fee / 100 }}</div>
      <div>{{ car_fee / 100 }}</div>
    </div>

    <div class="wokertitle">
      <div>本次维修工单含税总报价</div>
      <div>{{ amount / 100 }} 元</div>
    </div>

    <div class="xia">
      <van-button type="info" class="btns" @click="$router.go(-1)"
        >返回上一层</van-button
      >
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { Workdetaill } from "../../api/work.js";
export default {
  data() {
    return {
      order_user_name: "",
      device_name: "",
      device_date: "",
      fault_desc: "",
      maintain_scheme: "",
      fault_images: [],
      maintain_images: [],
      create_time: "",
      goods_items: [],
      amount: "",
      work_time_price: "", // 工时单价
      work_time_num: "", // 工时时长
      work_time_amount: "", // 工时价格总计
      car_fee: "", // 出车费用
    };
  },
  created() {
    let order_id = this.$route.query.order_id;
    this.Workdetaills(order_id);
  },
  computed: {
    totalCount() {
      var total = 0;
      this.goods_items.forEach((item) => {
        total += item.amount
      });
      return total.toFixed(2);
    },
  },
  methods: {
    guclick() {
      ImagePreview(this.fault_images);
    },
    wanclick() {
      ImagePreview(this.maintain_images);
    },
    async Workdetaills(order_id) {
      const { data } = await Workdetaill({ order_id: order_id });
      this.order_user_name = data.data.order_user_name;
      this.device_name = data.data.device_name;
      this.device_date = data.data.device_date;
      this.fault_desc = data.data.fault_desc;
      this.maintain_scheme = data.data.maintain_scheme;
      this.fault_images = data.data.fault_images;
      this.maintain_images = data.data.maintain_images;
      this.create_time = data.data.create_time;
      this.goods_items = data.data.goods_items;
      this.amount = data.data.amount;
      this.work_time_price = data.data.work_time_price;
      this.work_time_num = data.data.work_time_num;
      this.work_time_amount = data.data.work_time_amount;
      this.car_fee = data.data.car_fee;
    },
  },
};
</script>

<style scoped>
.top {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
.box {
  background-color: #fff;
}
.froms {
  width: 100%;
  margin-top: 18%;
}
.hint {
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 14px;
  color: #646566;
  margin: 10px 0px;
  width: 100%;
  background-color: #fff;
  text-align: left;
}
.image {
  box-sizing: border-box;
  padding-left: 10px;
  width: 100%;
  text-align: left;
  background-color: #fff;
}
.img {
  margin-right: 5px;
}
.content {
  width: 100%;
  background-color: #fff;
}
.item {
  display: inline-block;
  margin: 10px 0px;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
}
.lings {
  display: flex;
  flex-wrap: nowrap;
}
.lings :nth-of-type(1) {
  width: 27%;
}
.lings :nth-of-type(2) {
  width: 27%;
}
.lings :nth-of-type(3) {
  width: 15%;
}
.lings :nth-of-type(4) {
  width: 15%;
}
.lings :nth-of-type(5) {
  width: 15%;
}
.mayns {
  box-sizing: border-box;
  width: 100%;
  height: 5vh;
  line-height: 5vh;
  border: 1px solid #999;
  display: flex;
  justify-content: space-around;
  font-size: 14px;
}
.worktime {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #999;
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-size: 14px;
}
.worktime div {
  line-height: 5vh !important;
}
.worktime :nth-of-type(1) {
  width: 25%;
}
.worktime :nth-of-type(2) {
  width: 25%;
  border-left: 1px solid #999;
}
.worktime :nth-of-type(3) {
  width: 25%;
  border-left: 1px solid #999;
}
.worktime :nth-of-type(4) {
  border-left: 1px solid #999;
  width: 25%;
}
.car {
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  width: 100%;
  border: 1px solid #999;
  text-align: center;
  font-size: 14px;
  line-height: 5vh !important;
  margin-bottom: 20px;
}
.car :nth-of-type(1) {
  width: 25%;
  border-right: 1px solid #999;
}
.car :nth-of-type(2) {
  box-sizing: border-box;
  padding-left: 9%;
  width: 51%;
  border-right: 1px solid #999;
}
.car :nth-of-type(3) {
  width: 25%;
}
.inp {
  box-sizing: border-box;
  padding-left: 25%;
}
.maring {
  margin-bottom: 20px;
}
.wokertitle {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}
.hint {
  width: 80%;
  text-align: left;
  height: 5vh;
  margin-left: 15px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}
</style>