<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">配件详情</div>
    </div>
    <!-- 配件信息 -->
    <div class="nav">
      <div class="nav-left">
        <img src="https://img01.yzcdn.cn/vant/ipad.jpeg" alt="" class="pic" />
      </div>
      <div class="nav-right">
        <div class="hint">
          <div class="hint-title">货物名称:{{ it.goods_name }}</div>
          <div class="hint-title">货物编号:{{ it.goods_no }}</div>
          <div class="hint-title">货物详情:{{ it.goods_desc }}</div>
        </div>
      </div>
    </div>

    <!-- 仓库可以信息 -->
    <div class="tit">公共仓库</div>
    <!-- 列表 -->
    <van-loading type="spinner" color="#1989fa" v-if="loading" />
    <div class="main">
      <div class="main-one">仓库名称</div>
      <div class="main-two">库存数量</div>
      <div class="main-ser">需求数量</div>
    </div>

    <div class="main clo" v-for="(item, index) in list" :key="index">
      <div class="main-one">{{ item.house_name }}</div>
      <div class="main-two">{{ item.goods_num }}</div>
      <div class="main-ser zeng">
        <div class="bian" @click="jian(item)">
          <van-icon name="minus" size="18" />
        </div>
        <div class="num">{{ item.require_num }}</div>
        <div class="bian" @click="jia(item)">
          <van-icon name="plus" size="18" />
        </div>
      </div>
    </div>

    <div class="xia">
      <van-button type="info" class="btns" @click="oks">返回上一层</van-button>
    </div>
    <div class="bottms">
      <van-icon name="shopping-cart" size="45" />
      <div class="navs" @click="che">
        <div>共</div>
        <div class="zhongs">{{ totalCount }}</div>
        <div>件</div>
      </div>
      <van-goods-action-button type="danger" text="确定" @click="oks" />
    </div>
    <!-- {{ shopping }} -->

    <van-popup
      v-model="bottomshow"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="mains">
        <div class="xu">序号</div>
        <div class="name">名称</div>
        <div class="main-one" style="text-align: left">仓库名称</div>
        <div class="main-two">库存数量</div>
        <div class="main-ser">需求数量</div>
      </div>

      <div class="mains clo" v-for="(item, index) in shopping" :key="index">
        <div class="xu">{{ index + 1 }}</div>
        <div class="name chaosum" style="text-align: left">
          {{ item.goods_name }}
        </div>
        <div class="main-one chaosum" style="text-align: left">
          {{ item.house_name }}
        </div>
        <div class="main-two chaosum">{{ item.goods_num }}</div>
        <div class="main-ser zeng">
          <div class="bian" @click="jian(item)">
            <van-icon name="minus" size="18" />
          </div>
          <div class="num">{{ item.require_num }}</div>
          <div class="bian" @click="jia(item)">
            <van-icon name="plus" size="18" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Icon, Notify } from "vant";
import { Huofen } from "../../api/store";
import ruxiangVue from "../ruxiang.vue";
export default {
  data() {
    return {
      zongshu: 0,
      it: {},
      id: "",
      list: [],
      loading: true,
      shopping: [],
      bottomshow: false,
    };
  },
  created() {
    this.it = this.$route.query; // 获取上个页面传过来的id
    this.Huofenlist(this.it.id);
    let ss = JSON.parse(sessionStorage.getItem("obj"));
    // console.log(ss);
    if (ss == null) {
      console.log("1111");
      this.shopping = [];
    } else {
      this.shopping = ss.shopping;
    }
  },

  mounted() {},
  beforeRouteLeave(to, from, next) {
    to.meta.keepAlive = true;
    next(0);
  },
  computed: {
    totalCount() {
      var total = 0;
      this.shopping.forEach((item) => {
        total += item.require_num;
        // console.log(this.shopping);
      });
      return total;
    },
  },

  methods: {
    oks() {
      var obj = {
        data: 111,
        shopping: this.shopping,
      };
      sessionStorage.setItem("obj", JSON.stringify(obj));
      this.$router.go(-1);
    },
    jia(item) {
      // console.log(item);
      // item.require_num++
      if (this.shopping.length === 0) {
        this.shopping.push(item);
        item.require_num++;
        // console.log(this.shopping);
      } else {
        if (item.house_id === this.shopping[0].house_id) {
          let pos = -1;
          for (let i = 0; i < this.shopping.length; i++) {
            if (item.batch_sum === this.shopping[i].batch_sum) {
              pos = i;
              if (this.shopping[i].require_num < this.shopping[i].goods_num) {
                this.shopping[i].require_num += 1;
              } else {
                Notify({ type: "warning", message: "不能超过库存数量" });
              }
              break;
            }
          }
          if (pos === -1) {
            item.require_num++;
            this.shopping.push(item);
          }
        } else {
          Notify({ type: "warning", message: "一条订单只能选择一个仓库" });
        }
      }
    },

    jian(item) {
      console.log(item);
      if (item.require_num > 0) {
        item.require_num--;
      } else {
        for (let i = 0; i < this.shopping.length; i++) {
          if (item.batch_sum === this.shopping[i].batch_sum) {
            if (this.shopping[i].require_num > 0) {
              this.shopping[i].require_num--;
            } else if (this.shopping[i].require_num <= 0) {
              Notify({ type: "danger", message: "已经减完了" });
              this.shopping.splice(i, 1);
            }
          }
        }
      }
    },

    che() {
      this.bottomshow = true;
    },

    async Huofenlist(id) {
      const { data } = await Huofen({ goods_id: id });
      // console.log(data);
      this.list = data.data;
      // console.log(this.list);
      this.loading = false;
      if (this.shopping.length === 0) {
        console.log("1111");
      } else {
        for (let i = 0; i < this.shopping.length; i++) {
          for (let j = 0; j < this.list.length; j++) {
            if (this.list[j].batch_sum === this.shopping[i].batch_sum) {
              this.list[j] = this.shopping[i];
            } else {
              console.log("222");
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped  lang="less">
.box {
  background-color: #fff;
  box-sizing: border-box;
  .top {
    background-color: #dbd5d5;
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 1000;
  }
  .inp {
    margin-top: 15%;
  }
  .nav {
    background-color: #f5f5f5;
    width: 100%;
    height: 30vh;
    box-sizing: border-box;
    display: flex;
    margin-top: 14%;
    .nav-left {
      width: 40%;
      // background-color: aqua;
      .pic {
        margin-top: 10%;
        width: 100%;
        height: 80%;
      }
    }
    .nav-right {
      width: 60%;
      // background-color: chocolate;
      box-sizing: border-box;
      .hint {
        width: 95%;
        height: 80%;
        // border: 1px solid #000;
        box-sizing: border-box;
        margin-top: 5%;
        padding: 10% 3%;
        .hint-title {
          text-align: left;
          font-size: 15px;
          width: 100%;
          // border: 1px solid #000;
          margin-top: 5%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .tit {
    width: 80%;
    text-align: left;
    margin-left: 3%;
    font-size: 19px;
    margin-top: 3%;
  }
  .main {
    box-sizing: border-box;
    width: 100%;
    height: 6vh;
    // background-color: palevioletred;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .name {
      width: 30%;
    }
    .main-one {
      width: 40%;
    }
    .main-two {
      width: 35%;
    }
    .main-ser {
      width: 35%;
    }

    .zeng {
      box-sizing: border-box;
      padding-left: 7%;
      display: flex;
      align-items: center;
      .bian {
        width: 20%;
        height: 10%;
        border: 1px solid #000;
        border-radius: 50%;
      }
      .num {
        width: 15%;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  .clo {
    background-color: #f5f5f5;
  }
  .bottms {
    padding-left: 10px;
    padding-right: 20px;
    height: 10vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0%;
    left: 0%;
    /* border: 1px solid #000; */
    background-color: #dbd5d5;
  }
  .zhongs {
    width: 30%;
    height: 6vh;
    border: 1px solid #000;
    line-height: 6vh;
    border-radius: 60%;
    margin-left: 5px;
    margin-right: 5px;
  }
  .navs {
    box-sizing: border-box;
    width: 40%;
    display: flex;
    align-items: center;
  }

  .mains {
    height: 6vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .xu {
      width: 15%;
    }
    .name {
      width: 30%;
    }
    .main-one {
      width: 30%;
    }
    .main-two {
      width: 25%;
    }
    .main-ser {
      width: 25%;
    }
    .chaosum {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .zeng {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .bian {
        width: 30%;
        height: 3%;
        border: 1px solid #000;
        border-radius: 50%;
      }
      .num {
        width: 15%;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  .btns{
    margin-top: 10%;
    border-radius: 5px;
  }
}
</style>
