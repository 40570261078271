<template>
  <div class="box">
    <van-overlay :show="zheshow">
      <div class="wrapper">
        <van-loading type="spinner" v-if="loading" color="#0094ff"
          >加载中....</van-loading
        >
      </div>
    </van-overlay>
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="fans" />
      <div class="title">自行采购</div>
    </div>
    <section class="content">
      <div class="lings">
        <div class="item blod">货物编号</div>
        <div class="item blod">货物名称</div>
        <div class="item blod">数量</div>
        <div class="item blod">小计</div>
      </div>
      <div v-for="(item, index) in this.parts" :key="index" class="lings">
        <div class="item">{{ item.goods_no }}</div>
        <div class="item">{{ item.goods_name }}</div>
        <div class="item">{{ item.require_num }}</div>
        <div class="item">
          <input
            v-model="item.goods_price"
            @input="item.goods_price = inputCheck(item.goods_price)"
            class="inpsus"
          />
        </div>
      </div>
    </section>
    <div class="botn">
      <van-icon name="add-o" size="40" @click="opend" />
    </div>
    <div class="mayns">
      <div>费用总计</div>
      <div>{{ totalCount }} 元</div>
    </div>

    <div class="from">
      <van-form @submit="onSubmit">
        <van-field
          readonly
          clickable
          name="picker"
          :value="house"
          label="责任仓库"
          placeholder="点击选择责任仓库"
          @click="showPicker = true"
          :rules="[{ required: true, message: '请选择责任仓库' }]"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            title="选择仓库"
            value-key="name"
            show-toolbar
            :columns="cang"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>

        <van-field
          name="guuploader"
          label="支付截图"
          :rules="[{ required: true, message: '请上传支付截图' }]"
        >
          <template #input>
            <van-uploader
              v-model="guuploader"
              :after-read="guafterRead"
              :before-delete="gubefore"
            />
          </template>
        </van-field>

        <van-field
          name="guuploader"
          label="收据截图"
          :rules="[{ required: true, message: '请上传收据截图' }]"
        >
          <template #input>
            <van-uploader
              v-model="uploader"
              :after-read="afterRead"
              :before-delete="before"
            />
          </template>
        </van-field>

        <van-field name="radio" label="自行采购发票">
          <template #input>
            <van-radio-group
              v-model="radio"
              direction="horizontal"
              @change="radiolick"
            >
              <van-radio name="1">有</van-radio>
              <van-radio name="2">无</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Billof } from "../../api/work.js";
import { Cang } from "../../api/index.js";
import { Dialog, Notify } from "vant";
import { Debounce } from "@/utils/common.js";
import { Upimg } from "@/api/upimg.js";
export default {
  data() {
    return {
      loading: false,
      zheshow: false,
      parts: [],
      radio: 0,
      uploader: [],
      guuploader: [],
      cang: [],
      showPicker: false,
      house: "",
      dfrom: {
        ticket_order: "",
        matter_type: "buy",
        house_id: "",
        buy_bill: "", // 有无发票
        pay_images: [], // 支付截图
        receipt_images: [], // 收据截图
        buy_items: [],
      },
    };
  },
  created() {
    this.dfrom.ticket_order = this.$route.query.ticket_order;
    // console.log("自行采购", this.dfrom.ticket_order);
    this.Canglist();
    let ss = JSON.parse(sessionStorage.getItem("sle"));
    // console.log(ss);
    if (ss == null) {
      console.log("1111");
    } else {
      let arrs = ss.parts;
      arrs.map((item) => {
        item.goods_price = 0;
      });
      this.parts = arrs;
    }
  },

  //   // 保留状态
  //   beforeRouteLeave(to, from, next) {
  //     to.meta.keepAlive = true;
  //     next(0);
  //   },

  computed: {
    totalCount() {
      var total = 0;
      this.parts.forEach((item) => {
        total += Number(item.goods_price);
      });
      return total;
    },
  },

  // 实例被激活时使用，用于重复激活一个实例的时候
  activated() {
    this.dfrom.ticket_order = this.$route.query.ticket_order;
    // console.log("自行采购", this.dfrom.ticket_order);
    let ss = JSON.parse(sessionStorage.getItem("sle"));
    // console.log(ss);
    if (ss == null) {
      console.log("1111");
      this.parts = [];
    } else {
      let arrs = ss.parts;
      arrs.map((item) => {
        item.goods_price = 0;
      });
      this.parts = arrs;
    }
  },
  methods: {
    // 仓库选择器
    onConfirm(value) {
      // console.log(value);
      this.showPicker = false;
      this.house = value.name;
      this.dfrom.house_id = value.value;
    },
    // 输入框
    inputCheck(value) {
      return value
        .replace(/^0[0-9]+/, (val) => val[1])
        .replace(/^(\.)+/, "")
        .replace(/[^\d.]/g, "")
        .replace(/\.+/, ".")
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
    },
    // 点击跳转选择货物
    opend() {
      // console.log("点击了");
      this.$router.push({
        path: `/the`,
      });
    },

    // 返回主页 清空缓存
    fans() {
      this.dfrom.buy_items = [];
      this.parts = [];
      this.dfrom.pay_images = [];
      this.dfrom.receipt_images = [];
      this.dfrom.buy_bill = "";
      this.dfrom.house_id = "";
      this.house = "";
      this.radio = 0;
      this.uploader = [];
      this.guuploader = [];
      this.$router.go(-1);
      sessionStorage.clear();
    },

    // 表单数据
    onSubmit: Debounce(function (values) {
      this.dfrom.buy_items = [];
      this.parts.forEach((item, index) => {
        // console.log(item, index);
        let obj = {
          goods_id: item.id,
          goods_amount: item.goods_price * 100,
          goods_num: item.require_num,
        };
        this.dfrom.buy_items.push(obj);
      });
      if (this.dfrom.buy_items.length !== 0) {
        let poss = -1;
        for (let i = 0; i < this.dfrom.buy_items.length; i++) {
          if (this.dfrom.buy_items[i].goods_amount === 0) {
            poss = i;
          }
        }
        if (poss === -1) {
          this.Billofs();
          this.zheshow = true;
          this.loading = true;
        } else {
          Dialog.alert({
            title: "提示",
            message: `请填写小计金额`,
          }).then(() => {
            // on close
          });
        }
      } else {
        Dialog.alert({
          title: "提示",
          message: `清添加购买的配件`,
        }).then(() => {
          // on close
        });
      }
      // console.log(this.dfrom);
    }, 600),

    // 单选框选中
    radiolick(e) {
      // console.log(e);
      this.dfrom.buy_bill = e;
    },

    // 故障照片
    gubefore(file, detail) {
      // console.log(file, detail);
      this.dfrom.pay_images.forEach((item, index) => {
        if (detail.index === index) {
          this.dfrom.pay_images.splice(index, 1);
        }
      });
      this.guuploader.forEach((item, index) => {
        if (detail.index === index) {
          this.guuploader.splice(index, 1);
        }
      });
      // console.log(this.guuploader, this.dfrom);
    },

    // 故障图片上传
    async guafterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.zheshow = true;
      this.loading = true;
      // console.log(file);
      const fd = new FormData();
      fd.append("file[]", file.file);
      const data = await Upimg(fd);
      // console.log(data);
      if (data.status === 200) {
        this.zheshow = false;
        this.loading = false;
        // let obj = {
        //   url: data.data[0].url,
        // };
        this.dfrom.pay_images.push(data.data[0].url);
        // console.log(this.dfrom);
      } else {
        this.zheshow = false;
        this.loading = false;
      }
    },

    // 完成照片
    before(file, detail) {
      // console.log(file, detail);
      this.dfrom.receipt_images.forEach((item, index) => {
        // console.log(item, index);
        if (detail.index === index) {
          this.dfrom.receipt_images.splice(index, 1);
        }
      });
      this.uploader.forEach((item, index) => {
        // console.log(item, index);
        if (detail.index === index) {
          this.uploader.splice(index, 1);
        }
        // console.log(this.uploader);
      });
      // console.log(this.uploader, this.dfrom);
    },

    // 完成图片上传
    async afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.zheshow = true;
      this.loading = true;
      // console.log(file);
      const fd = new FormData();
      fd.append("file[]", file.file);
      const data = await Upimg(fd);
      // console.log(data);
      if (data.status === 200) {
        this.zheshow = false;
        this.loading = false;
        // let obj = {
        //   url: data.data[0].url,
        // };
        this.dfrom.receipt_images.push(data.data[0].url);
        // console.log(this.dfrom);
      } else {
        this.zheshow = false;
        this.loading = false;
      }
    },

    // 拉取仓库列表
    async Canglist() {
      const { data } = await Cang();
      this.cang = data.data;
    },

    // 新售后用料单交
    async Billofs() {
      const { data } = await Billof({ ...this.dfrom });
      // console.log(data);
      this.zheshow = false;
      this.loading = false;
      if (data.code === 200) {
        Dialog.alert({
          title: "提示",
          message: `提交成功,将返回订单详情页`,
        }).then(() => {
          // on close
          this.dfrom.buy_items = [];
          this.parts = [];
          this.dfrom.pay_images = [];
          this.dfrom.receipt_images = [];
          this.dfrom.buy_bill = "";
          this.dfrom.house_id = "";
          this.house = "";
          this.radio = 0;
          this.uploader = [];
          this.guuploader = [];
          this.$router.go(-2);
          sessionStorage.clear();
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: `${data.message}请重新提交`,
        }).then(() => {
          // on close
        });
      }
    },
  },
};
</script>

<style scoped>
.box {
  background-color: #fff;
}
.content {
  width: 100%;
  margin-top: 15%;
  background-color: #fff;
}
.item {
  display: inline-block;
  margin: 10px 0px;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
}
.lings {
  display: flex;
  flex-wrap: nowrap;
}
.lings :nth-of-type(1) {
  width: 30%;
}
.lings :nth-of-type(2) {
  width: 40%;
}
.lings :nth-of-type(3) {
  width: 15%;
}
.lings :nth-of-type(4) {
  width: 15%;
}
.blod {
  font-weight: bold;
}
.mayns {
  box-sizing: border-box;
  width: 100%;
  height: 5vh;
  line-height: 5vh;
  border: 1px solid #999;
  display: flex;
  justify-content: space-around;
  font-size: 14px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.from {
  margin-top: 15px;
}
.inpsus {
  width: 60% !important;
}
</style>