<template>
  <div class="box">
    <van-overlay :show="zheshow">
      <div class="wrapper">
        <van-loading type="spinner" v-if="loading" color="#0094ff"
          >加载中....</van-loading
        >
      </div>
    </van-overlay>
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">出库货物</div>
    </div>
    <div class="froms">
    <van-field v-model="username" label="出库人" readonly class="tis" />
    <van-field
      readonly
      clickable
      name="picker"
      :value="cangname"
      label="出库仓库"
      placeholder="点击选择出库仓库"
      @click="showPicker = true"
      :rules="[{ required: true, message: '请选择出库仓库' }]"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        title="选择仓库"
        value-key="name"
        show-toolbar
        :columns="cang"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    </div>

    <!-- 扫码出库 -->
    <div class="qrcode">
      <div class="xia">
        <van-button type="info" @click="clickCode" class="btns"
          >扫码出库</van-button
        >
      </div>
      <qrcode
        :qrcode="qrcode"
        v-show="qrcode"
        :camera="camera"
        :torchActive="torchActive"
        @switchCamera="switchCamera"
        @turnCameraOff="turnCameraOff"
        @onDecode="onDecode"
        @onInit="onInit"
      />
    </div>
    <div class="biao">已扫码信息</div>

    <div class="main">
      <div class="main-one">批次编号</div>
      <div class="main-two">货物编号</div>
      <div class="main-ser">货物名称</div>
      <div class="main-for">货物详情</div>
      <div class="main-fum">数量</div>
    </div>
    <div class="biggestBox">
      <ul>
        <!-- data-type=0 隐藏删除按钮 data-type=1 显示删除按钮 -->
        <li
          class="li_vessel"
          v-for="(item, index) in chuList"
          data-type="0"
          :key="index"
        >
          <!-- "touchstart" 当手指触摸屏幕时候触发  "touchend"  当手指从屏幕上离开的时候触发  "capture" 用于事件捕获-->
          <div
            @touchstart.capture="touchStart"
            @touchend.capture="touchEnd"
            @click="oneself"
          >
            <div class="contant">
              <div class="main">
                <div class="main-one chaosum" style="text-align: left">
                  {{ item.item_no }}
                </div>
                <div class="main-two chaosum" style="text-align: left">
                  {{ item.goods_no }}
                </div>
                <div class="main-ser chaosum" style="text-align: left">
                  {{ item.goods_name }}
                </div>
                <div class="main-for chaosum" style="text-align: left">
                  {{ item.goods_desc }}
                </div>
                <div class="main-fuw chaosum">{{ item.goods_num }}</div>
              </div>
            </div>
          </div>
          <div class="removeBtn" @click="remove(index)">删除</div>
        </li>
      </ul>
    </div>
    <div class="xia">
      <van-button type="info" @click="fanggetScroll" class="btns"
        >提交</van-button
      >
    </div>

    <div class="xia">
      <van-button type="info" @click="fan" class="btns">返回上一层</van-button>
    </div>
  </div>
</template>

<script>
import { Debounce } from "@/utils/common.js";
import { Billof } from "../../api/work.js";
import { Icon, Notify, Dialog } from "vant";
import { Cang, Pimingxiang, Huowuchuku } from "../../api/index.js";
export default {
  data() {
    return {
      startX: 0, //滑动开始
      endX: 0, //滑动结束
      zheshow: false,
      loading: false,
      cang: [],
      showPicker: false,
      qrcode: false,
      torchActive: false,
      camera: "off",
      chuList: [],
      cangname: "",
      house_id: "", // 选中仓库id
      item_no: "", // 扫码扫出来的
      dfrom: {
        ticket_order: "",
        matter_type: "output",
        house_id: "",
        output_items: [],
      },
    };
  },
  created() {
    this.username = localStorage.getItem("username");
    this.dfrom.ticket_order = this.$route.query.ticket_order;
    // console.log("出库货物", this.dfromticket_order);
  },
  mounted() {
    this.Canglist();
  },

  methods: {
    // 返回上一层
    fan() {
      this.$router.go(-1);
    },
    fanggetScroll: Debounce(function () {
      this.tijiao();
    }, 600),
    // 出库提交
    tijiao() {
      if (this.chuList.length !== 0) {
        this.dfrom.house_id = this.house_id;
        // console.log(this.chuList);
        this.chuList.forEach((item, index) => {
          // console.log(item, index);
          let obj = {
            item_no: item.item_no,
            goods_num: item.goods_num,
          };
          this.dfrom.output_items.push(obj);
        });
        this.zheshow = true;
        this.loading = true;
        this.Billofs();
      } else {
        Dialog.alert({
          title: "提示",
          message: `提交数据不能为空`,
        }).then(() => {
          // on close
        });
      }
    },

    // 仓库选中
    onConfirm(val) {
      // console.log(val);
      this.cangname = val.name;
      this.house_id = val.value;
      this.showPicker = false;
    },

    // 扫码结果回调
    onDecode(result) {
      // result, 扫描结果，可以根据自己的需求来实现相应的功能
      this.item_no = result;
      // console.log(this.item_no);
      this.turnCameraOff();
      this.Pimingdan();
    },
    // 拉取仓库列表
    async Canglist() {
      const { data } = await Cang();
      this.cang = data.data;
    },
    // 打开相机
    clickCode() {
      if (this.cangname == "") {
        Dialog.alert({
          title: "提示",
          message: `请选择出库仓库`,
        }).then(() => {
          // on close
        });
      } else {
        this.qrcode = true;
        this.camera = "rear";
      }
    },
    // 相机反转
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
        default:
          this.$toast("错误");
      }
    },
    // 关闭相机？？？？？？
    turnCameraOff() {
      this.camera = "off";
      this.qrcode = false;
    },

    // 检查是否调用摄像头
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "StreamApiNotSupportedError") {
        } else if (error.name === "NotAllowedError") {
          this.errorMessage = "Hey! I need access to your camera";
        } else if (error.name === "NotFoundError") {
          this.errorMessage = "Do you even have a camera on your device?";
        } else if (error.name === "NotSupportedError") {
          this.errorMessage =
            "Seems like this page is served in non-secure context (HTTPS, localhost or file://)";
        } else if (error.name === "NotReadableError") {
          this.errorMessage =
            "Couldn't access your camera. Is it already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.errorMessage =
            "Constraints don't match any installed camera. Did you asked for the front camera although there is none?";
        } else {
          this.errorMessage = "UNKNOWN ERROR: " + error.message;
        }
      }
    },

    // 出库扫码
    async Billofs() {
      const { data } = await Billof({ ...this.dfrom });
      this.zheshow = false;
      this.loading = false;
      if (data.code === 200) {
        Dialog.alert({
          title: "提示",
          message: `提交成功,将返回订单详情页`,
        }).then(() => {
          this.$router.go(-2);
          this.chuList = [];
          this.dfrom.output_items = [];
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: `${data.message}请重新提交`,
        }).then(() => {
          this.chuList = [];
          this.dfrom.output_items = [];
        });
      }
    },

    async Pimingdan() {
      const { data } = await Pimingxiang({
        house_id: this.house_id,
        item_no: this.item_no,
      });
      if (data.code !== 200) {
        Dialog.alert({
          title: "提示",
          message: `${data.message}`,
        }).then(() => {
          // on close
        });
      } else {
        const obj = {
          goods_desc: data.data.goods_desc,
          goods_name: data.data.goods_name,
          goods_no: data.data.goods_no,
          item_no: data.data.item_no,
          goods_num: 1,
        };
        if (this.chuList.length !== 0) {
          let pos = -1;
          for (let i = 0; i < this.chuList.length; i++) {
            if (this.item_no != this.chuList[i].item_no) {
              continue;
            }
            pos = i;
          }
          if (pos >= 0) {
            this.chuList[pos].goods_num += 1;
          } else {
            const objs = JSON.parse(JSON.stringify(obj));
            objs.goods_num = 1;
            this.chuList.push(objs);
            // console.log(this.chuList);
          }
        } else {
          const objs = JSON.parse(JSON.stringify(obj));
          objs.goods_num = 1;
          this.chuList.push(obj);
        }
        console.log(this.chuList);
      }
    },
    // 向左滑动出现删除按钮时，点击商品信息区域取消删除
    oneself() {
      if (this.checkSlide()) {
        this.restSlide();
      } else {
        // 点击商品信息弹出弹框
        // console.log("点击当前商品触发事件...");
      }
    },
    //滑动开始
    touchStart(e) {
      // 记录初始位置
      this.startX = e.touches[0].clientX;
    },
    //滑动结束
    touchEnd(e) {
      // 当前滑动的父级元素
      let parentElement = e.currentTarget.parentElement;
      // 记录结束位置
      this.endX = e.changedTouches[0].clientX;
      // 左滑大于30距离删除出现
      if (parentElement.dataset.type == 0 && this.startX - this.endX > 30) {
        this.restSlide();
        parentElement.dataset.type = 1;
      }
      // 右滑
      if (parentElement.dataset.type == 1 && this.startX - this.endX < -30) {
        this.restSlide();
        parentElement.dataset.type = 0;
      }
      this.startX = 0;
      this.endX = 0;
    },
    //判断当前是否有滑块处于滑动状态
    checkSlide() {
      let listItems = document.querySelectorAll(".li_vessel");
      for (let i = 0; i < listItems.length; i++) {
        if (listItems[i].dataset.type == 1) {
          return true;
        }
      }
      return false;
    },
    //复位滑动状态
    restSlide() {
      let listItems = document.querySelectorAll(".li_vessel");
      // 复位
      for (let i = 0; i < listItems.length; i++) {
        listItems[i].dataset.type = 0;
      }
    },
    //删除数据信息
    remove(index) {
      // console.log(index);
      // 当前索引值
      // 复位
      this.restSlide();
      // 删除数组lists中一个数据
      this.chuList.splice(index, 1);
      // console.log(this.chuList);
    },
  },
  components: {
    // 注册
    qrcode: () => import("@/components/QrcodeReader"),
  },
};
</script>

<style scoped  lang="less">
.top {
  width: 100%;
  height: 8vh;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  background-color: #dbd5d5;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
.title {
  width: 80%;
  text-align: center;
  font-size: 24px;
}

.box {
  background-color: #fff;
}
.xia {
  width: 100%;
  height: 10vh;
  margin-top: 15px;
  text-align: center;
  line-height: 10vh;
}
.biao {
  width: 100%;
  height: 5vh;
  line-height: 5vh;
  box-sizing: border-box;
  padding-left: 10px;
  text-align: left;
}
#camera-video {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  object-fit: cover;
  z-index: 1000;
}
.btns {
  border-radius: 5px;
  width: 40%;
}
.main {
  z-index: 1000;
  width: 100%;
  height: 7vh;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 5vh;
  .chaosum {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .main-one {
    width: 25%;
  }
  .main-two {
    width: 25%;
  }
  .main-ser {
    width: 20%;
  }
  .main-for {
    width: 20%;
  }
  .main.fuw {
    width: 10%;
  }
}
.host {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0px !important;
  .main-one {
    width: 25%;
  }
  .main-two {
    width: 25%;
  }
  .main-ser {
    width: 20%;
  }
  .main-for {
    width: 20%;
  }
  .main.fuw {
    width: 20%;
  }
}
.tis {
  margin-top: 15%;
}
.biggestBox {
  overflow: hidden;
  /*超出部分隐藏*/
}

ul {
  /* 消除 ul 默认样式 */
  list-style: none;
  padding: 0;
  margin: 0;
}

.li_vessel {
  /* 全部样式 0.2秒 缓动*/
  transition: all 0.2s;
}

/* =0隐藏 */
.li_vessel[data-type="0"] {
  transform: translate3d(0, 0, 0);
}

/* =1显示 */
.li_vessel[data-type="1"] {
  /* -64px 设置的越大可以左滑的距离越远，最好与下面删除按钮的宽度以及定位的距离设置一样的值*/
  transform: translate3d(-64px, 0, 0);
}

/* 删除按钮 */
.li_vessel .removeBtn {
  width: 64px;
  height: 39px;
  background: #ff4949;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 22px;
  position: absolute;
  top: 0px;
  right: -64px;
  line-height: 39px;
  text-align: center;
  border-radius: 2px;
}

/* 左边的图片样式 */
.host {
  overflow: hidden;
  /*消除图片带来的浮动*/
  padding: 10px;
  background: #ffffff;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.froms{
  margin-top: 17%;
}
</style>