import { render, staticRenderFns } from "./purchase.vue?vue&type=template&id=54730733&scoped=true&"
import script from "./purchase.vue?vue&type=script&lang=js&"
export * from "./purchase.vue?vue&type=script&lang=js&"
import style0 from "./purchase.vue?vue&type=style&index=0&id=54730733&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54730733",
  null
  
)

export default component.exports