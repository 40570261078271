import { render, staticRenderFns } from "./delivery.vue?vue&type=template&id=19b12f7c&scoped=true&"
import script from "./delivery.vue?vue&type=script&lang=js&"
export * from "./delivery.vue?vue&type=script&lang=js&"
import style0 from "./delivery.vue?vue&type=style&index=0&id=19b12f7c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19b12f7c",
  null
  
)

export default component.exports