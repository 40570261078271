<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">入库记录</div>
    </div>

    <div class="shai">
      <div class="shai-left" @click="cangshai">
        <div class="let">仓库筛选</div>
        <div>{{ cangxinxi.name }}</div>
      </div>
      <!-- <div class="shai-right" @click="rishai">
        <div class="let">时间筛选</div>
        <div>{{ timeValue }}</div>
      </div> -->
    </div>

    <div class="nav">
      <div
        class="list"
        v-for="item in tablist"
        :key="item.batch_id"
        @click="xiang(item.order_id)"
      >
        <div class="list-left">
          <div class="let-one">入库类型:{{ item.rulei }}</div>
          <div class="let-two">入库人:{{ item.order_username }}</div>
          <div class="let-two">货物:{{ item.goods_info }}</div>
          <div class="let-two">入库仓库:{{ item.house_info }}</div>
        </div>
        <div class="list-right">
          <div class="zhuang">{{ item.batch_status_desc }}</div>
        </div>
      </div>
    </div>
    <!-- <van-pagination
      v-model="page"
      :total-items="number_page"
      :items-per-page="perpage"
      @change="pagechange"
    /> -->
    <van-loading color="#0094ff" v-show="logs" />

    <van-popup
      v-model="show"
      position="bottom"
      :style="{ height: '30%' }"
      close-on-click-overlay
    >
      <van-picker
        title="选择仓库"
        value-key="name"
        show-toolbar
        :columns="cang"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <van-popup
      v-model="riqiflag"
      position="bottom"
      :style="{ height: '30%' }"
      close-on-click-overlay
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @confirm="rique"
        @cancel="riqu"
        :columns-order="['year', 'day', 'nonth']"
      />
    </van-popup>
  </div>
</template>

<script>
import { Debounce } from "@/utils/common.js";
import { Icon, Notify } from "vant";
import { Churulist, Cang } from "../api/index";
export default {
  data() {
    return {
      Fullscreen: true,
      logs: true,
      show: false,
      cang: [],
      cangxinxi: {},
      riqiflag: false,
      currentDate: new Date(2021, 0, 17),
      timeValue: "",
      tablist: [],
      page: 1,
      number_page: 0, // 总记录数
      perpage: 10, // 	每页记录数
      cangid: "",
    };
  },
  created() {
    this.Canglist();
  },
  mounted() {
    this.timeFormat(new Date());
    this.Churulists();
    window.addEventListener("scroll", this.fanggetScroll);
    let windowHeight =
      document.documentElement.clientHeight || document.body.clientHeight; //变量windowHeight是 可视区的高度
    let scrollHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight; //变量scrollHeight是 滚动条的总高度 /可视化的高度与溢出的距离（总高度）
    if (windowHeight < scrollHeight) {
      this.Fullscreen = false; //是否满屏
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.fanggetScroll);
  },
  methods: {
    fanggetScroll: Debounce(function () {
      this.getScroll();
    }, 600),

    getScroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; //变量scrollTop是滚动条滚动时，距离顶部的距离
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight; //变量windowHeight是 可视区的高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight; //变量scrollHeight是 滚动条的总高度 /可视化的高度与溢出的距离（总高度）
      //滚动条到底部的条件
      setTimeout(() => {
        if (scrollTop + windowHeight + 150 >= scrollHeight) {
          console.log("触底了");
          this.logs = true;

          this.page = this.page + 1;
          this.Churulists();
        }
      }, 300);
    },

    xiang(id) {
      console.log(id);
      this.$router.push({
        path: `/ruxiang?id=${id}`,
      });
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + "-" + month + "-" + day;
    },
    rishai() {
      this.riqiflag = true;
    },
    cangshai() {
      this.show = true;
    },
    // 弹出层
    tan() {
      this.show = true;
    },
    // 仓库选中
    onConfirm(val) {
      // console.log(val);
      this.cangxinxi = val;
      this.cangid = val.value;
      this.show = false;
      this.Churulists();
    },

    // 日期选中确定
    rique(value) {
      // console.log(value);
      this.riqiflag = false;
      this.timeValue = this.timeFormat(this.currentDate);
    },

    // 日期取消
    riqu() {
      this.riqiflag = false;
    },

    onCancel() {
      this.show = false;
    },

    // 入库记录列表
    async Churulists() {
      const { data } = await Churulist({
        order_type: 1,
        page: this.page,
        size: this.perpage,
        house_id: this.cangid,
      });
      this.logs = false;
      if (data.data.data.length == 0) {
        Notify({ type: "success", message: "没有数据了" });
      } else {
        if (this.page == 1) {
          this.tablist = data.data.data;
          console.log("6666");
        } else {
          console.log("8888");
          const datas = data.data.data;
          for (let i = 0; i < datas.length; i++) {
            this.tablist.push(datas[i]);
          }
        }
      }
      // this.tablist = data.data.data;
      // this.number_page = data.data.count;
    },
    // 拉取仓库列表
    async Canglist() {
      const { data } = await Cang();
      this.cang = data.data;
      // console.log(this.cang);
    },
    // 页面参数发生改变时
    pagechange() {
      // console.log("111");
      this.Churulists();
    },
  },
};
</script>

<style scoped>
.shai-left {
  width: 49%;
  display: flex;
  align-items: center;
}
.shai-right {
  box-sizing: border-box;
  width: 49%;
  display: flex;
  align-items: center;
}
.let {
  margin-left: 5px;
  margin-right: 6px;
}
.shai {
  margin-top: 15%;
  width: 100%;
  height: 8vh;
  display: flex;
  box-sizing: border-box;
}
.top {
  width: 100%;
  height: 8vh;
  /* border: 1px solid #000; */
  display: flex;
  position: fixed;
  top: 0%;
  left: 0%;
  align-items: center;
  background-color: #dbd5d5;
  z-index: 1000;
}
.title {
  width: 80%;
  text-align: center;
  font-size: 24px;
}

.box {
  background-color: #f3f3f3;
}
.nav {
  width: 100%;
  box-sizing: border-box;
  /* padding: 2px 6px; */
  margin-top: 5px;
}
.list {
  width: 100%;
  background-color: #ffffff;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  margin-top: 7px;
  box-sizing: border-box;
  padding: 6px 10px;
}
.list-left {
  width: 75%;
  /* border: 1px solid red; */
  text-align: left;
}
.list-right {
  width: 24%;
  height: 6vh;
  line-height: 6vh;
  /* text-align: center; */
}
.box {
  background-color: #f3f3f3;
}
.let-one {
  font-size: 17px;
  margin-top: 5px;
}
.let-two {
  font-size: 15px;
  color: #999;
  margin-top: 3px;
}
.zhuang {
  background-color: #0052d9;
  color: white;
  border-radius: 5px;
}
</style>