<template>
  <div class="box">
    <div class="boos">
      <img
        src="https://qiniu.haiwojiamei.com/logo/JAMI%20logo.png"
        alt=""
        id="pic"
      />
    </div>
    <div class="nav">
      <div class="btns on1" @click="dai()">待入库列表</div>
      <div class="btns on2" @click="ru()">入库记录</div>
      <div class="btns on3" @click="chusao()">出库扫码</div>
      <div class="btns on4" @click="chu()">出库记录</div>
      <div class="btns on1" @click="inventory()">库存信息</div>
      <div class="btns on2" @click="items()">货物名目</div>
      <div class="btns on3" @click="deploy()">货物调配</div>
      <!-- <div class="btns on4" @click="newwork()">维修工单</div> -->
      <!-- <div class="btns on1" @click="ingredient()">用料审批单</div> -->
      <div class="btns on2" @click="order()">售后订单</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    dai() {
      this.$router.push({
        path: `/dailist`,
      });
    },
    ru() {
      this.$router.push({
        path: `/rujilu`,
      });
    },
    chusao() {
      this.$router.push({
        path: `/chusao`,
      });
    },
    chu() {
      this.$router.push({
        path: `/chujilu`,
      });
    },
    inventory() {
      this.$router.push({
        path: `/inventory`,
      });
    },
    deploy() {
      this.$router.push({
        path: `/deploylist`,
      });
    },
    items() {
      this.$router.push({
        path: `/items`,
      });
    },
    newwork() {
      this.$router.push({
        path: `/newwork`,
      });
    },
    ingredient() {
      this.$router.push({
        path: `/ingredient`,
      });
    },
    order() {
      this.$router.push({
        path: `/order`,
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.box {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}
.boos {
  margin-top: 10px;
  width: 100%;
  height: 10vh;
  text-align: center;
}
#pic {
  width: 60%;
  height: 10vh;
}
.nav {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btns {
  width: 75%;
  height: 60px;
  margin-top: 30px;
  line-height: 60px;
  text-align: center;
  border-radius: 5px;
}
.on1 {
  background-color: #00a870;
  color: white;
}
.on2 {
  background-color: #f3f3f3;
  border: 1px solid #3aba8f;
}
.on3 {
  background-color: #0052d9;
  color: white;
}
.on4 {
  background-color: #f3f3f3;
  border: 1px solid #3aba8f;
  color: #0052d9;
}
</style>