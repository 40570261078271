<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">新建售后订单</div>
    </div>
    <van-overlay :show="zheshow">
      <div class="wrapper">
        <van-loading type="spinner" v-if="loading" color="#0094ff"
          >加载中....</van-loading
        >
      </div>
    </van-overlay>
    <div class="froms">
      <van-form @submit="onSubmit">
        <van-field
          v-model="dfrom.work_date"
          name="date"
          label="工作日期"
          readonly
        />
        <van-field
          readonly
          clickable
          name="picker"
          :value="firm"
          label="业务公司"
          placeholder="点击业务公司"
          @click="showPicker = true"
          :rules="[{ required: true, message: '请选择业务公司' }]"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            title="业务公司"
            value-key="name"
            :columns="companis"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>

        <van-field
          class="inpding"
          v-model="client"
          name="客户名称"
          label="客户名称"
          placeholder="请填写客户名称"
          @input="inps"
          :rules="[{ required: true, message: '请填写客户名称' }]"
        />

        <div class="suggest" v-show="flag" style="overflow: scroll">
          <ul>
            <li
              v-for="(item, index) in this.soulist"
              :key="index"
              @click="liClick(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>

        <van-field
          v-model="dfrom.customer_linkman"
          name="客户联系人"
          label="客户联系人"
          placeholder="客户联系人"
          :rules="[{ required: true, message: '请填写客户联系人' }]"
        />

        <van-field
          v-model="dfrom.customer_contact"
          name="联系方式"
          type="digit"
          label="联系方式"
          placeholder="联系人联系方式"
          :rules="[{ required: true, message: '请填写客户联系方式' }]"
        />

        <van-field
          v-model="dfrom.maintain_address"
          name="维修地点"
          label="维修地点"
          placeholder="维修地点"
          :rules="[{ required: true, message: '请输入维修地点' }]"
        />

        <van-field name="radio" label="维修类型">
          <template #input>
            <van-radio-group
              v-model="radio"
              direction="horizontal"
              @change="radiolick"
            >
              <van-radio name="contract">合同维修</van-radio>
              <van-radio name="temp">临时维修</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
          v-if="radio == 'contract'"
          v-model="dfrom.contract_name"
          name="所属合同"
          label="所属合同"
          placeholder="所属合同"
        />

        <van-field
          v-if="radio == 'contract'"
          v-model="dfrom.contract_no"
          name="合同编号"
          label="合同编号"
          placeholder="合同编号"
        />

        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Dialog, Notify } from "vant";
import { Debounce } from "@/utils/common.js";
import { Reimburse, Client, Ordercreate } from "../../api/work.js";
export default {
  data() {
    return {
      flag: false,
      date: "",
      firm: "", // 业务公司
      client: "", // 客户名称
      companis: [],
      soulist: [],
      linkman: "",
      contact: "",
      radio: "",
      showPicker: false,
      zheshow: false,
      loading: false,
      dfrom: {
        business_company: "", // 业务公司id
        work_date: "", // 工作日期
        customer_id: "", // 客户id
        maintain_address: "", // 维修地点
        maintain_type: "", // 维修类型 contract-合同维修 temp-临时维修
        contract_name: "", // 合同名称
        contract_no: "", // 合同编号
        customer_linkman: "", // 客户联系人
        customer_contact: "", // 客户联系方式
      },
    };
  },
  created() {
    this.Reimburses();
  },
  mounted() {
    this.getDate();
  },
  methods: {
    // 提交
    onSubmit: Debounce(function (values) {
      // console.log(this.dfrom);
      this.zheshow = true;
      this.loading = true;
      this.Ordercreates(this.dfrom);
    }),

    // 选中业务公司
    onConfirm(value) {
      // console.log(value);
      this.dfrom.business_company = value.value;
      this.firm = value.name;
      this.showPicker = false;
    },

    // 输入框事件
    inps: Debounce(function (value) {
      if (this.client !== "") {
        // console.log(value);
        this.soulist = [];
        this.Fenzusou(value);
        // this.zheshow = true;
        // this.loading = true;
      } else {
        this.soulist = [];
        this.flag = false;
      }
    }, 600),

    // 选中搜索后的数据
    liClick(item) {
      // console.log(item);
      this.dfrom.customer_id = item.id;
      this.client = item.name;
      this.flag = false;
      this.dfrom.customer_contact = item.contact;
      this.dfrom.customer_linkman = item.linkman;
    },

    // 获取当前日期
    getDate() {
      var time = new Date();
      this.dfrom.work_date = `${time.getFullYear()}-${
        time.getMonth() + 1
      }-${time.getDate()}`;
      //   console.log(this.date);
    },

    // 业务公司
    async Reimburses() {
      const { data } = await Reimburse();
      this.companis = data.data.companis;
    },

    // 单选框选中
    radiolick(e) {
      // console.log(e);
      this.dfrom.maintain_type = e;
    },

    // 客户搜索
    async Fenzusou(name) {
      const { data } = await Client({ name: name, check_all: 1 });
      // this.zheshow = false;
      // this.loading = false;
      if (data.data.length !== 0) {
        this.flag = true;
        this.soulist = data.data.list;
      } else {
        Notify({ type: "success", message: "没有找到此设备" });
        this.soulist = [];
        this.flag = false;
      }
    },

    // 新售后订单提交
    async Ordercreates(v) {
      const { data } = await Ordercreate(v);
      // console.log(data);
      this.zheshow = false;
      this.loading = false;
      if (data.code === 200) {
        Dialog.alert({
          title: "提示",
          message: `提交成功,将返回订单详情页`,
        }).then(() => {
          this.$router.go(-1);
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: `${data.message}请重新提交`,
        }).then(() => {
          // on close
        });
      }
    },
  },
};
</script>

<style scopde>
.box {
  background-color: #fff;
}
.top {
  background-color: #dbd5d5;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
.froms {
  margin-top: 18%;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.suggest {
  position: absolute;
  top: 29%;
  left: 0%;
  width: 70%;
  border-radius: 5px;
  /* height: 25vh; */
  background-color: #fff;
  border: 1px solid #d3d4d5;
  margin-left: 17%;
  overflow: hidden;
  z-index: 1000;
  height: 30vh;
}
li {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0px 5px;
}
</style>