<template>
  <div class="box">
    <van-overlay :show="zheshow">
      <div class="wrapper">
        <van-loading type="spinner" v-if="loading" color="#0094ff"
          >加载中....</van-loading
        >
      </div>
    </van-overlay>
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">待入库详情</div>
    </div>
    <div class="user">
      <div class="user-left">
        <div class="user-left-one">验收人</div>
        <div>{{ companyList.receive_name }}</div>
      </div>
      <div class="user-left">
        <div class="user-left-one">入库人</div>
        <div>{{ username }}</div>
      </div>
    </div>
    <div v-show="flag">
      <div class="xia">
        <van-button type="info" @click="daochu" class="btns"
          >导出二维码文件</van-button
        >
      </div>
      <div class="biao">货物信息</div>
      <!-- <div class="list-box">
        <div class="huolist">
          <div class="huo-one">批次号</div>
          <div class="huo-onr">货物名称</div>
          <div class="huo-ont">货物详情</div>
          <div class="huo-ony">数量</div>
        </div>
        <div
          class="huolist lie"
          v-for="item in companyList.goods_list"
          :key="item.index"
        >
          <div class="huo-one size">{{ item.item_no }}</div>
          <div class="huo-onr size">{{ item.goods_name }}</div>
          <div class="huo-ont size">{{ item.goods_desc }}</div>
          <div class="huo-ony size">{{ item.goods_num }}</div>
        </div>
      </div> -->
      <section class="content">
        <div class="lings">
          <div class="item blod">批次号</div>
          <div class="item blod">货物名称</div>
          <div class="item blod">货物详情</div>
          <div class="item blod">数量</div>
        </div>
        <div
          v-for="(item, index) in companyList.goods_list"
          :key="index"
          class="lings"
        >
          <div class="item">{{ item.item_no }}</div>
          <div class="item">{{ item.goods_name }}</div>
          <div class="item">{{ item.goods_desc }}</div>
          <div class="item">{{ item.goods_num }}</div>
        </div>
      </section>
    </div>

    <div class="qrcode">
      <div class="xia">
        <van-button type="info" @click="clickCode" class="btns"
          >识别二维码</van-button
        >
      </div>
      <qrcode
        :qrcode="qrcode"
        v-show="qrcode"
        :camera="camera"
        :torchActive="torchActive"
        @switchCamera="switchCamera"
        @ClickFlash="ClickFlash"
        @turnCameraOff="turnCameraOff"
        @onDecode="onDecode"
        @onInit="onInit"
      />
    </div>
    <div class="biao">已扫码信息</div>
    <!-- <div class="list-box">
      <div class="huolist">
        <div class="huo-one">批次号</div>
        <div class="huo-onr">货物名称</div>
        <div class="huo-ont">货物详情</div>
        <div class="huo-ony">数量</div>
      </div>
      <div class="huolist lie" v-for="item in ruList" :key="item.index">
        <div class="huo-one size">{{ item.item_no }}</div>
        <div class="huo-onr size">{{ item.goods_name }}</div>
        <div class="huo-ont size">{{ item.goods_desc }}</div>
        <div class="huo-ony size">{{ item.goods_num }}</div>
      </div>
    </div> -->

    <section class="content">
      <div class="lings">
        <div class="item blod">批次号</div>
        <div class="item blod">货物名称</div>
        <div class="item blod">货物详情</div>
        <div class="item blod">数量</div>
      </div>
      <div v-for="(item, index) in ruList" :key="index" class="lings">
        <div class="item ajs">{{ item.item_no }}</div>
        <div class="item">{{ item.goods_name }}</div>
        <div class="item">{{ item.goods_desc }}</div>
        <div class="item">{{ item.goods_num }}</div>
      </div>
    </section>

    <div class="xia">
      <van-button type="info" @click="fanggetScroll" class="btns"
        >提交</van-button
      >
    </div>

    <div class="xia">
      <van-button type="info" @click="fan" class="btns">返回上一层</van-button>
    </div>
  </div>
</template>

<script>
import { Debounce } from "@/utils/common.js";
import { AddressEdit, Notify, Dialog } from "vant";
import { Dailist, Huopiru, Daidetaill } from "../api/index";
import { Icon } from "vant";
export default {
  data() {
    return {
      companyList: {}, // 全部数据
      ids: "", // 去对应的数据id
      goodsList: [], // 货物信息数据
      xiaurl: "", // 下载地址
      username: "", // 入库人
      qrcode: false,
      torchActive: false,
      camera: "off",
      flag: true,
      ruList: [],
      item_list: [],
      zheshow: false,
      loading: false,
    };
  },
  created() {
    this.ids = this.$route.query.id; // 获取上个页面传过来的id
    this.Dailists(this.ids);
    this.zheshow = true;
    this.loading = true;
    this.username = localStorage.getItem("username");
  },

  methods: {
    fanggetScroll: Debounce(function () {
      this.tijiao();
    }, 600),
    // 提交
    tijiao() {
      if (this.ruList.length !== 0) {
        for (let i = 0; i < this.ruList.length; i++) {
          let obj = {
            item_no: this.ruList[i].item_no,
            goods_num: this.ruList[i].goods_num,
          };
          this.item_list.push(obj);
        }
        setTimeout(() => {
          console.log(this.item_list);
          this.Huopirulist();
          this.loading = true;
          this.zheshow = true;
        }, 300);
      } else {
        // Notify({ type: "danger", message: "提交数据不能为空" });
        Dialog.alert({
          title: "提示",
          message: `提交数据不能为空`,
        }).then(() => {
          // on close
        });
      }
    },

    // 返回上一层
    fan() {
      this.$router.go(-1);
    },
    // 打开相机
    clickCode() {
      this.qrcode = true;
      this.camera = "rear";
      this.flag = false;
    },
    // 扫码结果回调
    onDecode(result) {
      console.log(result);
      this.flag = true;
      const saoid = result;
      this.turnCameraOff();
      //   var eves = this.goodsList.every(({ item_no }) => item_no !== saoid);
      //   if (eves) {
      //     Notify({ type: "primary", message: "待入库列表没有当条数据" });
      //   }
      const ecas = this.goodsList.filter((item) => {
        return item.item_no === saoid;
      });
      if (ecas.length <= 0) {
        Dialog.alert({
          title: "提示",
          message: `待入库列表没有当条数据`,
        }).then(() => {
          // on close
        });
      } else {
        for (let i = 0; i < this.goodsList.length; i++) {
          // 判断扫码得到的信息等于货物信息里面的信息
          if (saoid == this.goodsList[i].item_no) {
            if (this.ruList.length !== 0) {
              let pos = -1;
              for (let j = 0; j < this.ruList.length; j++) {
                if (saoid != this.ruList[j].item_no) {
                  continue;
                }
                pos = j;
              }
              if (pos >= 0) {
                if (this.ruList[pos].goods_num < this.goodsList[i].goods_num) {
                  this.ruList[pos].goods_num += 1;
                } else {
                  Dialog.alert({
                    title: "提示",
                    message: `当条数据已达最大限制`,
                  }).then(() => {
                    // on close
                  });
                }
              } else {
                const objs = JSON.parse(JSON.stringify(this.goodsList[i]));
                objs.goods_num = 1;
                this.ruList.push(objs);
                // console.log(this.ruList);
              }
            } else {
              const obj = JSON.parse(JSON.stringify(this.goodsList[i]));
              obj.goods_num = 1;
              this.ruList.push(obj);
              // console.log(this.ruList);
              continue;
            }
          }
        }
      }
    },
    // 相机反转
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
        default:
          this.$toast("错误");
      }
    },
    // 关闭相机？？？？？？
    turnCameraOff() {
      this.camera = "off";
      this.qrcode = false;
    },
    // 打开手电筒
    ClickFlash() {
      switch (this.torchActive) {
        case true:
          this.torchActive = false;
          break;
        case false:
          this.torchActive = true;
          break;
        default:
          this.$toast("错误");
      }
    },

    // 检查是否调用摄像头
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "StreamApiNotSupportedError") {
        } else if (error.name === "NotAllowedError") {
          this.errorMessage = "Hey! I need access to your camera";
        } else if (error.name === "NotFoundError") {
          this.errorMessage = "Do you even have a camera on your device?";
        } else if (error.name === "NotSupportedError") {
          this.errorMessage =
            "Seems like this page is served in non-secure context (HTTPS, localhost or file://)";
        } else if (error.name === "NotReadableError") {
          this.errorMessage =
            "Couldn't access your camera. Is it already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.errorMessage =
            "Constraints don't match any installed camera. Did you asked for the front camera although there is none?";
        } else {
          this.errorMessage = "UNKNOWN ERROR: " + error.message;
        }
      }
    },

    daochu() {
      console.log("111");
      const urls = this.xiaurl;
      // const urls =  this.xiaurl + "? response-content-type=application/octet-stream";
      const x = new window.XMLHttpRequest();
      x.open("GET", urls, true);
      x.responseType = "blob";
      x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${this.companyList.goods_info}`;
        a.click();
      };
      x.send();
      // window.open(this.xiaurl);
      // console.log('sssss');
    },
    async Dailists(id) {
      const { data } = await Daidetaill({ id: id });
      this.zheshow = false;
      this.loading = false;
      const list = data.data;
      this.companyList = data.data;
      this.goodsList = data.data.goods_list;
      this.xiaurl = data.data.pdf_url;
    },

    async Daidetaills(id) {
      const { data } = await Daidetaill({ id: id });
      console.log(data);
    },

    // 批次入库
    async Huopirulist() {
      const { data } = await Huopiru({ item_list: this.item_list });
      // console.log(data);
      this.zheshow = false;
      this.loading = false;
      if (data.code == 200) {
        Dialog.alert({
          title: "提示",
          message: `入库成功`,
        }).then(() => {
          this.fan();
        });
        this.ruList = [];
      } else {
        Dia.alert({
          title: "提示",
          message: `${data.message}`,
        }).then(() => {
          // on close
        });
        this.ruList = [];
      }
    },
  },
  components: {
    // 注册
    qrcode: () => import("@/components/QrcodeReader"),
  },
};
</script>

<style scoped>
.top {
  width: 100%;
  height: 8vh;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  background-color: #dbd5d5;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
.title {
  width: 80%;
  text-align: center;
  font-size: 24px;
}

.box {
  background-color: #f3f3f3;
}
.user {
  margin-top: 15%;
  width: 100%;
  /* border: 1px solid #000; */
  background-color: #ffffff;
  height: 15vh;
  box-sizing: border-box;
  padding: 5px 10px;
}
.user-left {
  display: flex;
  margin-top: 15px;
  align-items: center;
}
.user-left-one {
  width: 20%;
}
.xia {
  width: 100%;
  height: 10vh;
  margin-top: 15px;
  text-align: center;
  line-height: 10vh;
}
.biao {
  width: 100%;
  height: 5vh;
  line-height: 5vh;
  box-sizing: border-box;
  padding-left: 10px;
  text-align: left;
}
.huolist {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px 5px;
}
.huo-one {
  width: 30%;
}
.huo-onr {
  width: 30%;
}

.huo-ont {
  width: 30%;
}

.huo-ony {
  width: 10%;
}
.size {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.lie {
  margin-top: 15px;
}
.list-box {
  background-color: #ffffff;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
}
#camera-video {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  object-fit: cover;
  z-index: 1000;
}
.btns {
  border-radius: 5px;
  width: 40%;
}
.content {
  margin-top: 1%;
  width: 100%;
  background-color: #fff;
}
.item {
  display: inline-block;
  margin: 10px 0px;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
  padding: 5px 5px;
}
.lings {
  display: flex;
  flex-wrap: nowrap;
}
.lings :nth-of-type(1) {
  width: 30%;
  word-break: break-all;
}
.lings :nth-of-type(2) {
  width: 30%;
}
.ajs {
  word-break: break-all;
}
.lings :nth-of-type(3) {
  width: 30%;
}
.lings :nth-of-type(4) {
  width: 15%;
}
.blod {
  font-weight: bold;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>