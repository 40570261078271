<template>
  <div class="box">
    <van-overlay :show="zheshow">
      <div class="wrapper">
        <van-loading type="spinner" v-if="loading" color="#0094ff"
          >加载中....</van-loading
        >
      </div>
    </van-overlay>
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">出库扫码</div>
    </div>
    <div class="user">
      <div class="user-left">
        <div class="user-left-one">出库人</div>
        <div>{{ username }}</div>
      </div>
      <div class="user-left" @click="tan">
        <div class="user-left-one">出库仓库</div>
        <div>{{ cangname }}</div>
      </div>
      <van-popup
        v-model="show"
        position="bottom"
        :style="{ height: '30%' }"
        close-on-click-overlay
      >
        <van-picker
          title="选择仓库"
          value-key="name"
          show-toolbar
          :columns="cang"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
    </div>

    <!-- 扫码出库 -->
    <div class="qrcode">
      <div class="xia">
        <van-button type="info" @click="clickCode" class="btns"
          >扫码出库</van-button
        >
      </div>
      <qrcode
        :qrcode="qrcode"
        v-show="qrcode"
        :camera="camera"
        :torchActive="torchActive"
        @switchCamera="switchCamera"
        @ClickFlash="ClickFlash"
        @turnCameraOff="turnCameraOff"
        @onDecode="onDecode"
        @onInit="onInit"
      />
    </div>
    <div class="biao">已扫码信息</div>
    <!-- <div class="list-box">
      <div class="huolist">
        <div class="huo-one">批次号</div>
        <div class="huo-onr">货物名称</div>
        <div class="huo-ont">货物详情</div>
        <div class="huo-ony">数量</div>
      </div>
      <div class="huolist lie" v-for="item in chuList" :key="item.index">
        <div class="huo-one size">{{ item.item_no }}</div>
        <div class="huo-onr size">{{ item.goods_name }}</div>
        <div class="huo-ont size">{{ item.goods_desc }}</div>
        <div class="huo-ony size">{{ item.goods_num }}</div>
      </div>
    </div> -->

    <section class="content">
      <div class="lings">
        <div class="item blod">批次号</div>
        <div class="item blod">货物名称</div>
        <div class="item blod">货物详情</div>
        <div class="item blod">数量</div>
      </div>
      <div v-for="(item, index) in chuList" :key="index" class="lings">
        <div class="item">{{ item.item_no }}</div>
        <div class="item">{{ item.goods_name }}</div>
        <div class="item">{{ item.goods_desc }}</div>
        <div class="item">{{ item.goods_num }}</div>
      </div>
    </section>

    <div class="xia">
      <van-button type="info" @click="fanggetScroll" class="btns"
        >提交</van-button
      >
    </div>

    <div class="xia">
      <van-button type="info" @click="fan" class="btns">返回上一层</van-button>
    </div>
  </div>
</template>

<script>
import { Debounce } from "@/utils/common.js";
import { Icon, Notify, Dialog } from "vant";
import { Cang, Pimingxiang, Huowuchuku } from "../api/index";
import dailistVue from "./dailist.vue";
export default {
  data() {
    return {
      zheshow: false,
      loading: false,
      cang: [],
      show: false,
      chengecang: {},
      qrcode: false,
      torchActive: false,
      camera: "off",
      chuList: [],
      cangname: "",
      house_id: "", // 选中仓库id
      item_no: "", // 扫码扫出来的
      item_list: [],
    };
  },
  created() {
    this.ids = this.$route.query.id; // 获取上个页面传过来的id
    this.username = localStorage.getItem("username");
  },
  mounted() {
    this.Canglist();
  },

  methods: {
    // 返回上一层
    fan() {
      this.$router.go(-1);
    },

    fanggetScroll: Debounce(function () {
      this.tijiao();
    }, 600),
    // 出库提交
    tijiao() {
      if (this.chuList.length !== 0) {
        for (let i = 0; i < this.chuList.length; i++) {
          let obj = {
            item_no: this.chuList[i].item_no,
            goods_num: this.chuList[i].goods_num,
          };
          this.item_list.push(obj);
        }
        setTimeout(() => {
          console.log(this.item_list);
          this.loading = true;
          this.zheshow = true;
          this.Huowuchukuya();
        }, 300);
      } else {
        // Notify({ type: "danger", message: "提交数据不能为空" });
        Dialog.alert({
          title: "提示",
          message: `提交数据不能为空`,
        }).then(() => {
          // on close
        });
      }
    },
    // 弹出层
    tan() {
      this.show = true;
    },
    // 仓库选中
    onConfirm(val) {
      // console.log(val);
      this.chengecang = val;
      this.cangname = val.name;
      this.house_id = val.value;
      this.show = false;
    },

    onCancel() {
      this.show = false;
    },

    // 扫码结果回调
    onDecode(result) {
      // result, 扫描结果，可以根据自己的需求来实现相应的功能
      this.item_no = result;
      // console.log(this.item_no);
      this.turnCameraOff();
      this.Pimingdan();
    },
    // 拉取仓库列表
    async Canglist() {
      const { data } = await Cang();
      this.cang = data.data;
    },
    // 打开相机
    clickCode() {
      if (this.cangname == "") {
        Dialog.alert({
          title: "提示",
          message: `请选择出库仓库`,
        }).then(() => {
          // on close
        });
      } else {
        this.qrcode = true;
        this.camera = "rear";
      }
    },
    // 相机反转
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
        default:
          this.$toast("错误");
      }
    },
    // 关闭相机？？？？？？
    turnCameraOff() {
      this.camera = "off";
      this.qrcode = false;
    },
    // 打开手电筒
    ClickFlash() {
      switch (this.torchActive) {
        case true:
          this.torchActive = false;
          break;
        case false:
          this.torchActive = true;
          break;
        default:
          this.$toast("错误");
      }
    },

    // 检查是否调用摄像头
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "StreamApiNotSupportedError") {
        } else if (error.name === "NotAllowedError") {
          this.errorMessage = "Hey! I need access to your camera";
        } else if (error.name === "NotFoundError") {
          this.errorMessage = "Do you even have a camera on your device?";
        } else if (error.name === "NotSupportedError") {
          this.errorMessage =
            "Seems like this page is served in non-secure context (HTTPS, localhost or file://)";
        } else if (error.name === "NotReadableError") {
          this.errorMessage =
            "Couldn't access your camera. Is it already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.errorMessage =
            "Constraints don't match any installed camera. Did you asked for the front camera although there is none?";
        } else {
          this.errorMessage = "UNKNOWN ERROR: " + error.message;
        }
      }
    },

    async Huowuchukuya() {
      const { data } = await Huowuchuku({
        house_id: this.house_id,
        item_list: this.item_list,
      });
      // console.log(data);
      this.loading = false;
      this.zheshow = false;
      if (data.code == 200) {
        Dialog.alert({
          title: "提示",
          message: `出库成功`,
        }).then(() => {
          // on close
          this.$router.go(-1);
          this.chuList = [];
          this.cangname = "";
          this.house_id = "";
          this.chengecang = {};
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: `${data.message}`,
        }).then(() => {
          // on close
        });
        this.chuList = [];
        this.cangname = "";
        this.house_id = "";
        this.chengecang = {};
      }
    },

    async Pimingdan() {
      const { data } = await Pimingxiang({
        house_id: this.house_id,
        item_no: this.item_no,
      });
      // console.log(data);
      if (data.code !== 200) {
        Dialog.alert({
          title: "提示",
          message: `${data.message}`,
        }).then(() => {
          // on close
        });
      } else {
        const obj = {
          goods_desc: data.data.goods_desc,
          goods_name: data.data.goods_name,
          goods_no: data.data.goods_no,
          item_no: data.data.item_no,
          goods_num: 1,
        };
        if (this.chuList.length !== 0) {
          let pos = -1;
          for (let i = 0; i < this.chuList.length; i++) {
            if (this.item_no != this.chuList[i].item_no) {
              continue;
            }
            pos = i;
          }
          if (pos >= 0) {
            this.chuList[pos].goods_num += 1;
          } else {
            const objs = JSON.parse(JSON.stringify(obj));
            objs.goods_num = 1;
            this.chuList.push(objs);
            console.log(this.chuList);
          }
        } else {
          const objs = JSON.parse(JSON.stringify(obj));
          objs.goods_num = 1;
          this.chuList.push(obj);
        }
        console.log(this.chuList);
      }
    },
  },
  components: {
    // 注册
    qrcode: () => import("@/components/QrcodeReader"),
  },
};
</script>

<style scoped>
.top {
  width: 100%;
  height: 8vh;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  background-color: #dbd5d5;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
.title {
  width: 80%;
  text-align: center;
  font-size: 24px;
}

.box {
  background-color: #f3f3f3;
}
.user {
  margin-top: 15%;
  width: 100%;
  /* border: 1px solid #000; */
  background-color: #ffffff;
  height: 15vh;
  box-sizing: border-box;
  padding: 5px 10px;
}
.user-left {
  display: flex;
  margin-top: 15px;
  align-items: center;
}
.user-left-one {
  text-align: left;
  width: 25%;
}
.xia {
  width: 100%;
  height: 10vh;
  margin-top: 15px;
  text-align: center;
  line-height: 10vh;
}
.biao {
  width: 100%;
  height: 5vh;
  line-height: 5vh;
  box-sizing: border-box;
  padding-left: 10px;
  text-align: left;
}
.huolist {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px 5px;
}
.huo-one {
  width: 30%;
}
.huo-onr {
  width: 30%;
}

.huo-ont {
  width: 30%;
}

.huo-ony {
  width: 10%;
}
.size {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.lie {
  margin-top: 15px;
}
.list-box {
  background-color: #ffffff;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
}
#camera-video {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  object-fit: cover;
  z-index: 1000;
}
.btns {
  border-radius: 5px;
  width: 40%;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.content {
  margin-top: 1%;
  width: 100%;
  background-color: #fff;
}
.item {
  display: inline-block;
  margin: 10px 0px;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
  padding: 5px 5px;
}
.lings {
  display: flex;
  flex-wrap: nowrap;
}
.lings :nth-of-type(1) {
  width: 30%;
  word-break: break-all
}
.lings :nth-of-type(2) {
  width: 30%;
}
.lings :nth-of-type(3) {
  width: 30%;
}
.lings :nth-of-type(4) {
  width: 15%;
}
.blod {
  font-weight: bold;
}
</style>