<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">自行采购详情</div>
    </div>
    <section class="content">
      <div class="lings">
        <div class="item blod">货物编号</div>
        <div class="item blod">货物名称</div>
        <div class="item blod">货物数量</div>
        <div class="item blod">小计</div>
      </div>
      <div v-for="(item, index) in this.items" :key="index" class="lings">
        <div class="item">{{ item.goods_no }}</div>
        <div class="item">{{ item.goods_name }}</div>
        <div class="item">{{ item.goods_num }}</div>
        <div class="item">{{ item.goods_amount / 100 }}</div>
      </div>
    </section>
    <div class="mayns">
      <div>费用总计</div>
      <div>{{ amount }} 元</div>
    </div>
    <div class="froms">
      <div class="hint">支付图片</div>
      <div class="image">
        <van-image
          v-for="item in pay_images"
          :key="item"
          width="100"
          height="100"
          :src="item"
          class="img"
          @click="pings"
        />
      </div>
      <div class="hint">收据图片</div>
      <div class="image">
        <van-image
          v-for="item in receipt_images"
          :key="item"
          width="100"
          height="100"
          :src="item"
          class="img"
          @click="regimg"
        />
      </div>
    </div>
    <van-field name="radio" label="自行采购发票">
      <template #input>
        <van-radio-group v-model="buy_bill" direction="horizontal" disabled>
          <van-radio name="1">有</van-radio>
          <van-radio name="2">无</van-radio>
        </van-radio-group>
      </template>
    </van-field>

    <div class="xia">
      <van-button type="info" @click="$router.go(-1)" class="btns"
        >返回上一层</van-button
      >
    </div>
  </div>
</template>

<script>
// 用料单类型 output-出库 buy-自行采购 help-外协 business-业务费用
import { Dialog } from "vant";
import { ImagePreview } from "vant";
import { Orderinfo } from "../../api/work.js";
export default {
  data() {
    return {
      items: [],
      amount: "",
      pay_images: [], // 支付截图
      receipt_images: [], // 收据截图
      buy_bill: "",
    };
  },
  created() {
    let order_id = this.$route.query.order_id;
    this.Orderinfos(order_id);
  },
  methods: {
    // 预览
    pings() {
      ImagePreview(this.pay_images);
    },

    regimg() {
      ImagePreview(this.receipt_images);
    },
    // 详情
    async Orderinfos(order_id) {
      const { data } = await Orderinfo({ order_id: order_id });
      if (data.data.matter_type === "buy") {
        this.items = data.data.items;
        this.amount = data.data.amount / 100;
        this.pay_images = data.data.pay_images; // 支付截图
        this.receipt_images = data.data.receipt_images; // 收据截图
        this.buy_bill = String(data.data.buy_bill);
      } else {
        Dialog.alert({
          title: "提示",
          message: `暂无数据`,
        }).then(() => {
          this.$router.go(-1);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background-color: #fff;
}
.content {
  margin-top: 16%;
  width: 100%;
  background-color: #fff;
}
.item {
  display: inline-block;
  margin: 10px 0px;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
}
.lings {
  display: flex;
  flex-wrap: nowrap;
}
.lings :nth-of-type(1) {
  width: 30%;
}
.lings :nth-of-type(2) {
  width: 40%;
}
.lings :nth-of-type(3) {
  width: 15%;
}
.lings :nth-of-type(4) {
  width: 15%;
}
.blod {
  font-weight: bold;
}
.mayns {
  box-sizing: border-box;
  width: 100%;
  height: 5vh;
  line-height: 5vh;
  border: 1px solid #999;
  display: flex;
  justify-content: space-around;
  font-size: 14px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.hint {
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 14px;
  color: #646566;
  margin: 5px 0px;
  width: 100%;
  background-color: #fff;
  text-align: left;
}
.image {
  box-sizing: border-box;
  padding-left: 20px;
  width: 100%;
  text-align: left;
  background-color: #fff;
}
.froms {
  width: 100%;
  margin-top: 5%;
}
.van-image__img {
  margin-left: 5px;
}
</style>