<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">货物调配详情</div>
    </div>
    <div class="nav">
      <van-loading type="spinner" color="#1989fa" v-if="loading" />
      <div class="user">
        <div class="user-left">
          <div class="user-left-one">申请人</div>
          <div>{{ apply_user.user_name }}</div>
        </div>
        <div class="user-left">
          <div class="user-left-one">调入仓库</div>
          <div>{{ input_house.house_name }}</div>
        </div>
        <!-- <div class="user-left">
          <div class="user-left-one">审核人</div>
          <div>{{ auth_user.auth_username }}</div>
        </div> -->
      </div>
      <div class="buttons">
        <van-button type="info" class="btns">审核详情</van-button>
        <van-button type="info" class="btns" @click="deployQR"
          >扫码出库</van-button
        >
        <van-button type="info" class="btns" @click="checkQR"
          >货物入库</van-button
        >
      </div>
      <div class="hint">需求货物信息</div>
      <section class="content">
        <div class="lings">
          <div class="item blod">序号</div>
          <div class="item blod">货物编号</div>
          <div class="item blod">货物名称</div>
          <div class="item blod">货物详情</div>
          <div class="item blod">调出仓库</div>
          <div class="item blod">可用数量</div>
          <div class="item blod">需求数量</div>
          <div class="item blod">调配数量</div>
          <div class="item blod">状态</div>
        </div>
        <div v-for="(item, index) in this.require" :key="index" class="lings">
          <div class="item">{{ index + 1 }}</div>
          <div class="item">{{ item.goods_no }}</div>
          <div class="item">{{ item.goods_name }}</div>
          <div class="item">{{ item.goods_desc }}</div>
          <div class="item">{{ item.house_name }}</div>
          <div class="item">{{ item.avail_num }}</div>
          <div class="item">{{ item.require_num }}</div>
          <div class="item">{{ item.provide_num }}</div>
          <div class="item">{{ item.status }}</div>
        </div>
      </section>

      <div class="hint">已出库货物清单</div>
      <section class="content">
        <div class="lings">
          <div class="item blod">序号</div>
          <div class="item blod">批次编号</div>
          <div class="item blod">货物编号</div>
          <div class="item blod">货物名称</div>
          <div class="item blod">货物详情</div>
          <div class="item blod">调出仓库</div>
          <div class="item blod">出库数量</div>
          <div class="item blod">出库时间</div>
        </div>
        <div v-for="(item, index) in this.output" :key="index" class="lings">
          <div class="item">{{ index + 1 }}</div>
          <div class="item">{{ item.batch_no }}</div>
          <div class="item">{{ item.goods_no }}</div>
          <div class="item">{{ item.goods_name }}</div>
          <div class="item">{{ item.goods_desc }}</div>
          <div class="item">{{ item.house_name }}</div>
          <div class="item">{{ item.output_num }}</div>
          <div class="item">{{ item.time }}</div>
        </div>
      </section>

      <!--  -->
      <div class="hint">已入库货物清单</div>
      <section class="content">
        <div class="lings">
          <div class="item blod">序号</div>
          <div class="item blod">批次编号</div>
          <div class="item blod">货物编号</div>
          <div class="item blod">货物名称</div>
          <div class="item blod">货物详情</div>
          <div class="item blod">调出仓库</div>
          <div class="item blod">入库数量</div>
          <div class="item blod">入库时间</div>
        </div>
        <div v-for="(item, index) in this.input" :key="index" class="lings">
          <div class="item">{{ index + 1 }}</div>
          <div class="item">{{ item.batch_no }}</div>
          <div class="item">{{ item.goods_no }}</div>
          <div class="item">{{ item.goods_name }}</div>
          <div class="item">{{ item.goods_desc }}</div>
          <div class="item">{{ item.house_name }}</div>
          <div class="item">{{ item.input_num }}</div>
          <div class="item">{{ item.time }}</div>
        </div>
      </section>
      <!--  -->

      <!-- 报损 -->
      <div class="hint">报损货物清单</div>
      <section class="content">
        <div class="lings">
          <div class="item blod">序号</div>
          <div class="item blod">批次编号</div>
          <div class="item blod">货物编号</div>
          <div class="item blod">货物名称</div>
          <div class="item blod">货物详情</div>
          <div class="item blod">调出仓库</div>
          <div class="item blod">报损数量</div>
          <div class="item blod">报损时间</div>
        </div>
        <div v-for="(item, index) in this.scrap" :key="index" class="lings">
          <div class="item">{{ index + 1 }}</div>
          <div class="item">{{ item.batch_no }}</div>
          <div class="item">{{ item.goods_no }}</div>
          <div class="item">{{ item.goods_name }}</div>
          <div class="item">{{ item.goods_desc }}</div>
          <div class="item">{{ item.house_name }}</div>
          <div class="item">{{ item.input_num }}</div>
          <div class="item">{{ item.time }}</div>
        </div>
      </section>
      <!--  -->

      <div class="hint">审批流程</div>
      <van-collapse v-model="activeNames" class="coll">
        <van-collapse-item
          v-for="(item, index) in auth_list"
          :key="index"
          :title="item.type"
        >
          <div class="shen">
            <div class="shen-left">
              <div>{{ item.type }}:{{ item.user_name }}</div>
              <div>提交时间{{ item.time }}</div>
            </div>
            <div class="shne-right">
              <div>{{ item.auth_status }}</div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { Diaodetail, Diaofushu } from "../../api/store.js";
export default {
  data() {
    return {
      activeNames: ["1"],
      id: "",
      loading: true,
      apply_user: {}, // 申请人
      input_house: {}, // 入库仓库
      auth_user: {}, // 审核人
      item_list: [], // 调配配件详情
      auth_list: [], // 审批流程
      addition_type: ["require", "output", "input", "scrap"],
      // addition_type: ["require"],
      require: [], // 需求数量
      output: [],
      input: [],
      scrap:[]
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.Diaodetaillist(this.id);
    this.Diaofushudan(this.id);
  },
  methods: {
    // 扫码出库
    deployQR() {
      this.$router.push({
        path: `/deployQR?id=${this.id}`,
      });
    },

    // 货物入库
    checkQR() {
      this.$router.push({
        path: `/checkQR?id=${this.id}&name=${this.input_house.house_name}`,
      });
    },

    async Diaodetaillist(id) {
      const { data } = await Diaodetail({ id: id });
      this.loading = false;
      this.apply_user = data.data.apply_user;
      this.input_house = data.data.input_house;
      this.auth_user = data.data.auth_user;
      this.item_list = data.data.item_list;
      this.auth_list = data.data.auth_list;
    },

    async Diaofushudan(id) {
      const { data } = await Diaofushu({
        order_id: id,
        addition_type: this.addition_type,
      });
      console.log(data);
      this.require = data.data.require;
      this.output = data.data.output;
      this.input = data.data.input;
      this.scrap = data.data.scrap
    },
  },
};
</script>

<style scoped lang="less">
.user {
  margin-top: 0% !important;
  width: 100%;
  height: 15vh;
  /* border: 1px solid #000; */
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px 10px;
}
.user-left {
  display: flex;
  margin-top: 15px;
  align-items: center;
}
.user-left-one {
  width: 20%;
  text-align: left;
  margin-right: 10px;
}
.top {
  width: 100%;
  height: 8vh;
  /* border: 1px solid #000; */
  display: flex;
  position: fixed;
  top: 0%;
  left: 0%;
  align-items: center;
  background-color: #dbd5d5;
  z-index: 1000;
}
.nav {
  margin-top: 15%;
}
.buttons {
  width: 100%;
  .btns {
    width: 60%;
    margin-top: 10px;
    border-radius: 5px;
  }
}
.hint {
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  text-align: left;
  font-size: 14px;
  margin: 20px 0px;
  color: red;
  font-weight: bold;
}
.shen {
  width: 100%;
  display: flex;
  align-items: center;
  .shen-left {
    width: 70%;
    text-align: left;
  }
  .shen-right {
    width: 29%;
  }
}
.coll {
  text-align: left !important;
}
.content {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  background-color: #fff;
}
.item {
  display: inline-block;
  margin: 10px;
  height: 3vh;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
}
.lings :nth-of-type(1) {
  width: 8%;
}
.lings :nth-of-type(2) {
  width: 27%;
}
.lings :nth-of-type(3) {
  width: 30%;
}
.lings :nth-of-type(4) {
  width: 30%;
}
.lings :nth-of-type(5) {
  width: 30%;
}
.lings :nth-of-type(6) {
  width: 15%;
}
.lings :nth-of-type(7) {
  width: 15%;
}
.lings :nth-of-type(8) {
  width: 25%;
}
.blod {
  font-weight: bold;
}
</style>