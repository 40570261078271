<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">库存信息</div>
    </div>
    <van-search
      class="inp"
      v-model="inp"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="swipes">
      <van-swipe :loop="false" :width="80" :show-indicators="false">
        <van-swipe-item
          :class="{ active: ss == item.title }"
          v-for="(item, index) in list"
          :key="index"
          @click="dao(item.list, item)"
          >{{ item.title }}</van-swipe-item
        >
      </van-swipe>
    </div>
    <van-loading type="spinner" color="#1989fa" v-if="loading" />
    <div class="main">
      <div class="main-left" style="overflow: scroll">
        <van-sidebar v-model="activeKey">
          <van-sidebar-item
            class="let-title"
            v-for="(items, index) in arr"
            :key="index"
            :title="items.title"
            @click="onChange(items)"
          />
        </van-sidebar>
      </div>
      <div class="main-right" style="overflow: scroll">
        <div
          class="content"
          v-for="(it, index) in rightlist"
          :key="index"
          @click="accessories(it)"
        >
          <div class="content-left">
            <img
              src="https://img01.yzcdn.cn/vant/ipad.jpeg"
              class="pic"
              alt=""
            />
          </div>
          <div class="content-right">
            <div
              v-for="(ts, index) in it.avail_list"
              :key="index"
              class="content-right-top"
            >
              <div class="right-top-cang">{{ ts.house_name }}:</div>
              <div class="right-top-cang">{{ ts.avail_num }}件</div>
            </div>
            <div>
              <div class="content-right-title">{{ it.goods_name }}</div>
              <div class="content-right-title">{{ it.goods_desc }}</div>
              <div class="content-right-title">{{ it.goods_no }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Notify } from "vant";
import { Fenzu } from "../../api/store";
export default {
  data() {
    return {
      inp: "",
      activeKey: 0,
      list: [],
      arr: [],
      rightlist: [],
      ss: "",
      loading: true,
    };
  },
  mounted() {},
  created() {
    this.Fenzulist();
  },
  methods: {
    // 点击搜索
    onSearch(inp) {
      // console.log(this.inp);
      this.Fenzusou(this.inp);
      this.loading = true;
    },

    // 配件详情
    accessories(it) {
      this.$router.push({
        path: `/accessories`,
        query: it,
      });
    },

    // 点击侧边导航
    onChange(items) {
      this.rightlist = items.list;
      // console.log(this.rightlist);
    },

    // 点击轮播导航
    dao(itemlist, os) {
      // console.log(os);
      this.ss = os.title;
      // console.log(itemlist);
      this.arr.length = 0;
      for (let i = 0; i < itemlist.length; i++) {
        for (let j = 0; j < itemlist[i].list.length; j++) {
          this.arr.push(itemlist[i].list[j]);
        }
      }
      // console.log(this.arr);
    },
    // 供应商分组搜索
    async Fenzulist() {
      const { data } = await Fenzu();
      // console.log(data);
      this.list = data.data;
      let itemlist = data.data[0].list;
      for (let i = 0; i < itemlist.length; i++) {
        for (let j = 0; j < itemlist[i].list.length; j++) {
          this.arr.push(itemlist[i].list[j]);
        }
      }
      this.rightlist = this.arr[0].list;
      this.ss = this.list[0].title;
      this.loading = false;
    },

    async Fenzusou(name) {
      const { data } = await Fenzu({ name: name ,check_all:1,class:'SH'});
      // console.log(data);
      this.loading = false;
      if (data.data.length !== 0) {
        this.rightlist.length = 0;
        let soudata = data.data;
        for (let i = 0; i < soudata.length; i++) {
          for (let j = 0; j < soudata[i].list.length; j++) {
            for (let k = 0; k < soudata[i].list[j].list.length; k++) {
              for (
                let index = 0;
                index < soudata[i].list[j].list[k].list.length;
                index++
              ) {
                this.rightlist.push(soudata[i].list[j].list[k].list[index]);
              }
            }
          }
        }
      } else {
        Notify({ type: "success", message: "没有找到此配件" });
      }
    },
  },
};
</script>

<style scoped >
.swipes {
  width: 100%;
  height: 50px;
  line-height: 50px;
  /* border: 1px solid #000; */
}
.main {
  width: 100%;
  height: 100%;
  /* border: 1px solid #000; */
  display: flex;
}
.main-left {
  width: 25%;
  min-width: 25%;
  height: 90vh;
  /* border: 1px solid red; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main-right {
  width: 74%;
  height: 90vh;
  /* border: 1px solid cornflowerblue; */
}
.van-swipe-item {
  height: 47px !important;
  width: 80px !important;
  background-color: #f7f8fa;
}
.box {
  background-color: white;
}
.let-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content {
  width: 100%;
  /* border: 1px solid #000; */
  height: 160px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #f7f8fa;
  margin-top: 10px;
}
.content-left {
  min-width: 40%;
  width: 40%;
  height: 75%;
  line-height: 60%;
}
.pic {
  width: 100%;
  height: 100%;
}
.content-right {
  box-sizing: border-box;
  padding: 20px 0px;
  width: 59%;
  height: 100%;
  text-align: center;
}
.content-right-title {
  text-align: left;
  width: 100%;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right-top-cang {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content-right-top {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 5%;
}
.active {
  color: red;
  background-color: white;
  overflow: hidden;
  font-weight: bold;
}
.top {
  background-color: #dbd5d5;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
.inp {
  margin-top: 15%;
}
</style>