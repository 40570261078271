<template>
  <div>
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">添加货物类型</div>
    </div>
    <div class="nav">
      <van-loading type="spinner" color="#1989fa" v-if="loading" />
      <van-form @submit="submit">
        <van-field
          v-model="title"
          name="title"
          label="货物标题"
          placeholder="货物标题"
          :rules="[{ required: true, message: '请填写货物标题' }]"
        />
        <van-field
          v-model="tag"
          name="tag"
          label="标识"
          placeholder="标识"
          :rules="[{ required: true, message: '请填写唯一标识,两位大写字母' }]"
        />
        <div class="hint">不选为默认为顶级</div>
        <van-field
          v-model="fieldValue"
          is-link
          readonly
          label="上级归属"
          name="shang"
          placeholder="请选择上级归属"
          @click="show = true"
        />
        <van-popup v-model="show" round closeable position="bottom">
          <van-cascader
            v-model="cascaderValue"
            title="上级归属"
            :options="options"
            :field-names="fieldNames"
            @finish="onFinish"
          />
        </van-popup>

        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { Huotree, Huocreate } from "../../api/store.js";
export default {
  data() {
    return {
      show: false,
      fieldValue: "",
      cascaderValue: "",
      title: "",
      tag: "",
      dfrom: {
        title: "",
        parent_id: 0,
        tag: "",
      },
      loading:false,
      options: [],
      fieldNames: {
        text: "title",
        value: "id",
        children: "list",
      },
    };
  },
  created() {
    this.Huotrees();
  },
  methods: {
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions[selectedOptions.length - 1].title;
      this.dfrom.parent_id = selectedOptions[selectedOptions.length - 1].id;
    },

    submit(values) {
    //   console.log("submit", values);
      this.dfrom.title = values.title;
      this.dfrom.tag = values.tag;
    //   console.log(this.dfrom);
      this.Huocreates();
      this.loading = true
    },

    // 拉取货物类型树
    async Huotrees() {
      const { data } = await Huotree();
    //   console.log(data);
      this.options = data.data;
    },

    // 创建货物类型
    async Huocreates() {
      const { data } = await Huocreate({ ...this.dfrom });
    //   console.log(data);
      this.loading = false
      if (data.code === 200) {
        Dialog.alert({
          title: "提示",
          message: `创建货物类型成功`,
        }).then(() => {
          // on close
          this.$router.go(-1);
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: `创建货物类型失败,${data.message}`,
        }).then(() => {
          this.dfrom.title = "";
          this.dfrom.tag = "";
          this.title = "";
          this.tag = "";
          this.fieldValue = "";
          this.dfrom.parent_id = 0;
        });
      }
    },
  },
};
</script>

<style scoped>
.nav {
  margin-top: 16%;
}

.hint{
  width: 80%;
  margin: 10px 15px;
  text-align: left;
  font-size: 14px;
  color: red;
}
</style>