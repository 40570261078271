<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">待入库列表</div>
    </div>
    <div class="nav">
      <div
        class="list"
        v-for="item in companyList"
        :key="item.batch_id"
        @click="xiang(item.batch_id)"
      >
        <div class="list-left">
          <div class="let-one">入库类型:{{ item.rulei }}</div>
          <div class="let-two">验收人:{{ item.receive_name }}</div>
          <div class="let-two">货物:{{ item.goods_info }}</div>
          <div class="let-two">入库仓库:{{ item.house_name }}</div>
        </div>
        <div class="list-right">
          <div class="zhuang">{{ item.batch_status_desc }}</div>
        </div>
      </div>
      <van-loading color="#0094ff" v-show="logs" />
      <!-- <van-pagination
        v-model="page"
        :total-items="number_page"
        :items-per-page="perpage"
        @change="pagechange"
      /> -->
    </div>
  </div>
</template>

<script>
import { Debounce } from "@/utils/common.js";
import { Dailist } from "../api/index";
import { Icon, Notify } from "vant";
export default {
  data() {
    return {
      companyList: [],
      page: 1,
      number_page: 0, //   总记录数
      perpage: 10, //  每页记录数
      Fullscreen: true,
      logs: true,
    };
  },
  created() {
    this.Dailists();
  },
  mounted() {
    window.addEventListener("scroll", this.fanggetScroll);
    let windowHeight =
      document.documentElement.clientHeight || document.body.clientHeight; //变量windowHeight是 可视区的高度
    let scrollHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight; //变量scrollHeight是 滚动条的总高度 /可视化的高度与溢出的距离（总高度）
    if (windowHeight < scrollHeight) {
      this.Fullscreen = false; //是否满屏
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.fanggetScroll);
  },
  methods: {
    fanggetScroll: Debounce(function () {
      this.getScroll();
    }, 600),

    getScroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; //变量scrollTop是滚动条滚动时，距离顶部的距离
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight; //变量windowHeight是 可视区的高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight; //变量scrollHeight是 滚动条的总高度 /可视化的高度与溢出的距离（总高度）
      //滚动条到底部的条件
      setTimeout(() => {
        if (scrollTop + windowHeight + 150 >= scrollHeight) {
          console.log("触底了");
          this.logs = true;

          this.page = this.page + 1;
          this.Dailists();
        }
      }, 500);
    },
    // 页面参数发生改变时
    pagechange() {
      // console.log('111');
      this.Dailists();
    },

    xiang(batch_id) {
      // console.log(batch_id);
      this.$router.push({
        path: `/daixiang?id=${batch_id}`,
      });
    },

    async Dailists() {
      const { data } = await Dailist({
        status: 2,
        page: this.page,
        size: this.perpage,
      });
      this.logs = false;
      if (data.data.data.length == 0) {
        Notify({ type: "success", message: "没有数据了" });
      } else {
        if (this.page == 1) {
          this.companyList = data.data.data;
          console.log("6666");
        } else {
          console.log("8888");
          const datas = data.data.data;
          for (let i = 0; i < datas.length; i++) {
            this.companyList.push(datas[i]);

            // console.log(this.companyList);
          }
        }

        // this.number_page = data.data.count;
      }
    },
  },
};
</script>

<style scoped>
.top {
  width: 100%;
  height: 8vh;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  background-color: #dbd5d5;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
.title {
  width: 80%;
  text-align: center;
  font-size: 24px;
}

.box {
  background-color: #f3f3f3;
}
.nav {
  width: 100%;
  box-sizing: border-box;
  /* padding: 2px 6px; */
  margin-top: 15%;
}
.list {
  width: 100%;
  background-color: #ffffff;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  margin-top: 7px;
  box-sizing: border-box;
  padding: 6px 10px;
}
.list-left {
  width: 75%;
  /* border: 1px solid red; */
  text-align: left;
}
.list-right {
  width: 24%;
  height: 6vh;
  line-height: 6vh;
  /* text-align: center; */
}
.box {
  background-color: #f3f3f3;
}
.let-one {
  font-size: 17px;
  margin-top: 5px;
}
.let-two {
  font-size: 15px;
  color: #999;
  margin-top: 3px;
}
.zhuang {
  background-color: #0052d9;
  color: white;
  border-radius: 5px;
}
</style>