<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">货物添加</div>
    </div>
    <van-search
      v-model="inp"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
      class="inp"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="swipes">
      <van-swipe :loop="false" :width="80" :show-indicators="false">
        <van-swipe-item
          :class="{ active: ss == item.title }"
          v-for="(item, index) in list"
          :key="index"
          @click="dao(item.list, item)"
          >{{ item.title }}</van-swipe-item
        >
      </van-swipe>
    </div>
    <van-loading type="spinner" color="#1989fa" v-if="loading" />
    <div class="main">
      <div class="main-left" style="overflow: scroll">
        <van-sidebar v-model="activeKey">
          <van-sidebar-item
            class="let-title"
            v-for="(items, index) in arr"
            :key="index"
            :title="items.title"
            @click="onChange(items)"
          />
        </van-sidebar>
      </div>
      <div class="main-right" style="overflow: scroll">
        <div
          class="content"
          v-for="(it, index) in rightlist"
          :key="index"
          @click="accessories(it)"
        >
          <div class="content-left">
            <img
              src="https://img01.yzcdn.cn/vant/ipad.jpeg"
              class="pic"
              alt=""
            />
          </div>
          <div class="content-right">
            <div
              v-for="(ts, index) in it.avail_list"
              :key="index"
              class="content-right-top"
            >
              <div class="right-top-cang">{{ ts.house_name }}:</div>
              <div class="right-top-cang">{{ ts.avail_num }}件</div>
            </div>
            <div>
              <div class="content-right-title">{{ it.goods_name }}</div>
              <div class="content-right-title">{{ it.goods_desc }}</div>
              <div class="content-right-title">{{ it.goods_no }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottms">
      <van-icon name="shopping-cart" size="45" />
      <div class="navs" @click="che">
        <div>共</div>
        <div class="zhongs">{{ totalCount }}</div>
        <div>件</div>
      </div>
      <van-goods-action-button type="danger" text="确定" @click="oks" />
    </div>

    <van-popup
      v-model="bottomshow"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="mains">
        <div class="xu">序号</div>
        <div class="name">名称</div>
        <div class="main-one" style="text-align: left">仓库名称</div>
        <div class="main-two">库存数量</div>
        <div class="main-ser">需求数量</div>
      </div>

      <div class="mains clo" v-for="(item, index) in shopping" :key="index">
        <div class="xu">{{ index + 1 }}</div>
        <div class="name chaosum" style="text-align: left">
          {{ item.goods_name }}
        </div>
        <div class="main-one chaosum" style="text-align: left">
          {{ item.house_name }}
        </div>
        <div class="main-two chaosum">{{ item.goods_num }}</div>
        <div class="main-ser zeng">
          <div class="bian" @click="jian(item)">
            <van-icon name="minus" size="18" />
          </div>
          <div class="num">{{ item.require_num }}</div>
          <div class="bian" @click="jia(item)">
            <van-icon name="plus" size="18" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Icon, Notify } from "vant";
import { Fenzu } from "../../api/store";
export default {
  data() {
    return {
      inp: "",
      activeKey: 0,
      list: [],
      arr: [],
      rightlist: [],
      ss: "",
      loading: true,
      bottomshow: false,
      shopping: [],
    };
  },
  mounted() {
    // console.log("asdasdasda");
  },
  created() {
    this.Fenzulist();
    let ss = JSON.parse(sessionStorage.getItem("obj"));
    // console.log(ss);
    if (ss == null) {
      console.log("1111");
    } else {
      this.shopping = ss.shopping;
    }
  },
  activated() {
    // console.log("实例被激活时使用，用于重复激活一个实例的时候");
    let ss = JSON.parse(sessionStorage.getItem("obj"));
    // console.log(ss);
    if (ss == null) {
      console.log("1111");
      this.shopping = [];
    } else {
      this.shopping = ss.shopping;
    }
  },
  computed: {
    totalCount() {
      var total = 0;
      this.shopping.forEach((item) => {
        total += item.require_num;
        // console.log(this.shopping);
      });
      return total;
    },
  },
  methods: {
    jia(item) {
      // console.log(item);
      if (item.require_num < item.goods_num) {
        item.require_num++;
      } else {
        Notify({ type: "warning", message: "不能超过库存数量" });
      }
    },
    jian(item) {
      // console.log(item);
      if (item.require_num > 0) {
        item.require_num--;
      } else {
        for (let i = 0; i < this.shopping.length; i++) {
          if (item.batch_sum === this.shopping[i].batch_sum) {
            if (this.shopping[i].require_num > 0) {
              this.shopping[i].require_num--;
            } else if (this.shopping[i].require_num <= 0) {
              Notify({ type: "danger", message: "已经减完了" });
              this.shopping.splice(i, 1);
            }
          }
        }
      }
    },
    oks() {
      // console.log("确定");
      var obj = {
        data: 111,
        shopping: this.shopping,
      };
      sessionStorage.setItem("obj", JSON.stringify(obj));
      this.$router.go(-1);
    },

    che() {
      this.bottomshow = true;
    },

    // 点击搜索
    onSearch(inp) {
      // console.log(this.inp);
      this.Fenzusou(this.inp);
      this.loading = true;
    },

    // 配件详情
    accessories(it) {
      this.$router.push({
        path: `/shopxiang`,
        query: it,
      });
    },

    // 点击侧边导航
    onChange(items) {
      this.rightlist = items.list;
      // console.log(this.rightlist);
    },

    // 点击轮播导航
    dao(itemlist, os) {
      // console.log(os);
      this.ss = os.title;
      // console.log(itemlist);
      this.arr.length = 0;
      for (let i = 0; i < itemlist.length; i++) {
        for (let j = 0; j < itemlist[i].list.length; j++) {
          this.arr.push(itemlist[i].list[j]);
        }
      }
      // console.log(this.arr);
    },
    // 供应商分组搜索
    async Fenzulist() {
      const { data } = await Fenzu();
      // console.log(data);
      this.list = data.data;
      let itemlist = data.data[0].list;
      for (let i = 0; i < itemlist.length; i++) {
        for (let j = 0; j < itemlist[i].list.length; j++) {
          this.arr.push(itemlist[i].list[j]);
        }
      }
      this.rightlist = this.arr[0].list;
      this.ss = this.list[0].title;
      this.loading = false;
    },

    async Fenzusou(name) {
      const { data } = await Fenzu({ name: name });
      console.log(data);
      this.loading = false;
      if (data.data.length !== 0) {
        this.rightlist.length = 0;
        let soudata = data.data;
        for (let i = 0; i < soudata.length; i++) {
          for (let j = 0; j < soudata[i].list.length; j++) {
            for (let k = 0; k < soudata[i].list[j].list.length; k++) {
              for (
                let index = 0;
                index < soudata[i].list[j].list[k].list.length;
                index++
              ) {
                this.rightlist.push(soudata[i].list[j].list[k].list[index]);
              }
            }
          }
        }
      } else {
        Notify({ type: "success", message: "没有找到此配件" });
      }
    },
  },
};
</script>

<style scoped lang="less">
.swipes {
  width: 100%;
  height: 50px;
  line-height: 50px;
  /* border: 1px solid #000; */
}
.main {
  width: 100%;
  height: 100%;
  /* border: 1px solid #000; */
  display: flex;
}
.main-left {
  width: 25%;
  min-width: 25%;
  height: 90vh;
  /* border: 1px solid red; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main-right {
  width: 74%;
  height: 90vh;
  /* border: 1px solid cornflowerblue; */
}
.van-swipe-item {
  height: 47px !important;
  width: 80px !important;
  background-color: #f7f8fa;
}
.box {
  background-color: white;
}
.let-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content {
  width: 100%;
  /* border: 1px solid #000; */
  height: 160px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #f7f8fa;
  margin-top: 10px;
}
.content-left {
  min-width: 40%;
  width: 40%;
  height: 75%;
  line-height: 60%;
}
.pic {
  width: 100%;
  height: 100%;
}
.content-right {
  box-sizing: border-box;
  padding: 20px 0px;
  width: 59%;
  height: 100%;
  text-align: center;
}
.content-right-title {
  text-align: left;
  width: 100%;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right-top-cang {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content-right-top {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 5%;
}
.active {
  color: red;
  background-color: white;
  overflow: hidden;
  font-weight: bold;
}
.bottm {
  padding-left: 10px;
  padding-right: 20px;
  height: 10vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0%;
  left: 0%;
  /* border: 1px solid #000; */
  background-color: #dbd5d5;
}
.zhong {
  width: 30%;
  height: 6vh;
  border: 1px solid #000;
  line-height: 6vh;
  border-radius: 60%;
  margin-left: 5px;
  margin-right: 5px;
}
.nav {
  box-sizing: border-box;
  width: 40%;
  display: flex;
  align-items: center;
}
.top {
  background-color: #dbd5d5;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
.inp {
  margin-top: 15%;
}
.bottms {
  padding-left: 10px;
  padding-right: 20px;
  height: 10vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0%;
  left: 0%;
  /* border: 1px solid #000; */
  background-color: #dbd5d5;
}
.zhongs {
  width: 30%;
  height: 6vh;
  border: 1px solid #000;
  line-height: 6vh;
  border-radius: 60%;
  margin-left: 5px;
  margin-right: 5px;
}
.navs {
  box-sizing: border-box;
  width: 40%;
  display: flex;
  align-items: center;
}
.mains {
  height: 6vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .xu {
    width: 15%;
  }
  .name {
    width: 30%;
  }
  .main-one {
    width: 30%;
  }
  .main-two {
    width: 25%;
  }
  .main-ser {
    width: 25%;
  }
  .chaosum {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .zeng {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .bian {
      width: 30%;
      height: 3%;
      border: 1px solid #000;
      border-radius: 50%;
    }
    .num {
      width: 15%;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
</style>