<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">新增用料审批单</div>
    </div>
    <div>
      <div class="nav">
        <div class="btns on1" @click="delivery()">出库货物</div>
        <div class="btns on2" @click="purchase()">自行采购</div>
        <div class="btns on3" @click="outsource()">外协事项</div>
        <div class="btns on4" @click="business()">业务费用</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  created() {
    this.ticket_order = this.$route.query.ticket_order;
  },
  methods: {
    delivery() {
      this.$router.push({
        path: `/delivery?ticket_order=${this.ticket_order}`,
      });
    },
    purchase() {
      this.$router.push({
        path: `/purchase?ticket_order=${this.ticket_order}`,
      });
    },
    outsource() {
      this.$router.push({
        path: `/outsource?ticket_order=${this.ticket_order}`,
      });
    },
    business() {
      this.$router.push({
        path: `/business?ticket_order=${this.ticket_order}`,
      });
    },
    update() {},
  },
};
</script>

<style scoped>
.box {
  background-color: #fff;
}
.nav {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btns {
  width: 75%;
  height: 60px;
  margin-top: 40px;
  line-height: 60px;
  text-align: center;
  border-radius: 5px;
}
.on1 {
  background-color: #00a870;
  color: white;
}
.on2 {
  background-color: #f3f3f3;
  border: 1px solid #3aba8f;
}
.on3 {
  background-color: #0052d9;
  color: white;
}
.on4 {
  background-color: #f3f3f3;
  border: 1px solid #3aba8f;
  color: #0052d9;
}
</style>