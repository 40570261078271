<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">出库货物详情</div>
    </div>
    <div class="froms">
      <van-field v-model="house_name" label="出库仓库" readonly />
    </div>
    <!-- <div class="main"> -->
    <!-- <div class="main-one">批次编号</div> -->
    <!-- <div class="main-two">货物编号</div>
      <div class="main-ser">货物名称</div>
      <div class="main-for">货物详情</div>
      <div class="main-fum">数量</div>
    </div> -->
    <!-- <div class="main" v-for="(item, index) in this.items" :key="index"> -->
    <!-- <div class="main-one chaosum" style="text-align: left">
        {{ item.item_no }}
      </div> -->
    <!-- <div class="main-two chaosum" style="text-align: left">
        {{ item.goods_no }}
      </div>
      <div class="main-ser chaosum" style="text-align: left">
        {{ item.goods_name }}
      </div>
      <div class="main-for chaosum" style="text-align: left">
        {{ item.goods_desc }}
      </div>
      <div class="main-fuw chaosum">{{ item.goods_num }}</div>
    </div> -->

    <section class="content">
      <div class="lings">
        <div class="item blod">货物编号</div>
        <div class="item blod">货物名称</div>
        <div class="item blod">货物详情</div>
        <div class="item blod">数量</div>
      </div>
      <div
        v-for="(item, index) in this.items"
        :key="index"
        class="lings"
      >
        <div class="item">{{ item.goods_no }}</div>
        <div class="item">{{ item.goods_name }}</div>
        <div class="item">{{ item.goods_desc }}</div>
        <div class="item">{{ item.goods_num }}</div>
      </div>
    </section>

    <div class="xia">
      <van-button type="info" @click="$router.go(-1)" class="btns"
        >返回上一层</van-button
      >
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
// 用料单类型 output-出库 buy-自行采购 help-外协 business-业务费用
import { Orderinfo } from "../../api/work.js";
export default {
  data() {
    return {
      house_name: "",
      items: [],
    };
  },
  created() {
    let order_id = this.$route.query.order_id;
    this.Orderinfos(order_id);
  },
  methods: {
    async Orderinfos(order_id) {
      const { data } = await Orderinfo({ order_id: order_id });
      if (data.data.matter_type === "output") {
        this.items = data.data.items;
        this.house_name = data.data.house_name;
      } else {
        Dialog.alert({
          title: "提示",
          message: `暂无数据`,
        }).then(() => {
          this.$router.go(-1);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background-color: #fff;
}
.main {
  z-index: 1000;
  width: 100%;
  height: 7vh;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 5vh;
  .chaosum {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .main-one {
    width: 25%;
  }
  .main-two {
    width: 25%;
  }
  .main-ser {
    width: 20%;
  }
  .main-for {
    width: 20%;
  }
  .main.fuw {
    width: 10%;
  }
}
.content {
  margin-top: 1%;
  width: 100%;
  background-color: #fff;
}
.item {
  display: inline-block;
  margin: 10px 0px;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
}
.lings {
  display: flex;
  flex-wrap: nowrap;
}
.lings :nth-of-type(1) {
  width: 30%;
}
.lings :nth-of-type(2) {
  width: 30%;
}
.lings :nth-of-type(3) {
  width: 30%;
}
.lings :nth-of-type(4) {
  width: 15%;
}
.blod {
  font-weight: bold;
}
</style>