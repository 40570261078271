<template>
  <div>
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">货物入库</div>
    </div>
    <div class="user">
      <div class="user-left">
        <div class="user-left-one">入库人</div>
        <div>{{ username }}</div>
      </div>
      <!-- <div class="user-left" @click="tan">
        <div class="user-left-one">出库仓库</div>
        <div>{{ cangname }}</div>
      </div> -->
      <div class="user-left">
        <div class="user-left-one">入库仓库</div>
        <div>{{ cangname }}</div>
      </div>

      <van-popup
        v-model="show"
        position="bottom"
        :style="{ height: '30%' }"
        close-on-click-overlay
      >
        <van-picker
          title="选择仓库"
          value-key="name"
          show-toolbar
          :columns="cang"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
      <van-loading type="spinner" color="#1989fa" v-if="loading" />
    </div>

    <div class="nav" v-show="flag">
      <div class="hint bold" style="color:red;margin-bottom:20px">调配待扫码货物信息</div>
      <div class="list-box">
        <div class="huolist">
          <div class="huo-ones bold">批次号</div>
          <div class="huo-onrs bold">货物名称</div>
          <div class="huo-onts bold">货物详情</div>
          <div class="huo-onys bold">数量</div>
        </div>
        <div class="huolist lie" v-for="item in goods_list" :key="item.index">
          <div class="huo-one size">{{ item.batch_no }}</div>
          <div class="huo-onr size">{{ item.goods_name }}</div>
          <div class="huo-ont size">{{ item.goods_desc }}</div>
          <div class="huo-ony size">{{ item.goods_num }}</div>
        </div>
      </div>
    </div>

    <div class="qrcode">
      <div class="xia flex">
        <van-button type="info" @click="clickCode" class="btns"
          >识别二维码</van-button
        >

        <van-button type="info" @click="frmLoss" class="btns"
          >货物报损</van-button
        >
      </div>
      <qrcode
        :qrcode="qrcode"
        v-show="qrcode"
        :camera="camera"
        :torchActive="torchActive"
        @switchCamera="switchCamera"
        @ClickFlash="ClickFlash"
        @turnCameraOff="turnCameraOff"
        @onDecode="onDecode"
        @onInit="onInit"
      />
    </div>
    <div class="hint bold">已扫码信息</div>
    <div class="list-box">
      <div class="huolist">
        <div class="huo-ones bold">批次号</div>
        <div class="huo-onrs bold">货物名称</div>
        <div class="huo-onts bold">货物详情</div>
        <div class="huo-onys bold">数量</div>
      </div>
      <div class="huolist lie" v-for="item in taDatalist" :key="item.index">
        <div class="huo-ones size">{{ item.batch_no }}</div>
        <div class="huo-onrs size">{{ item.goods_name }}</div>
        <div class="huo-onts size">{{ item.goods_desc }}</div>
        <div class="huo-onys size">{{ item.item_num }}</div>
      </div>
    </div>
    <div class="xia">
      <van-button type="info" @click="fanggetScroll" class="btns"
        >提交</van-button
      >
    </div>
  </div>
</template>

<script>
import { Debounce } from "@/utils/common.js";
import { Cang } from "../../api/index.js";
import { Daichuli, Huopixiang, Deployinput } from "../../api/store";
import { Dialog, Notify } from "vant";
export default {
  data() {
    return {
      username: "",
      id: "",
      cangname: "",
      show: false,
      cang: [],
      usershow: "",
      loading: true,
      goods_list: [],
      qrcode: false,
      torchActive: false,
      camera: "off",
      flag: true,
      taDatalist: [],
      dfrom: {
        order_id: "",
        items: [],
      },
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.cangname = this.$route.query.name;
    this.dfrom.order_id = this.$route.query.id;
    this.username = localStorage.getItem("username");
    this.Daichulilist();
  },
  methods: {
    fanggetScroll: Debounce(function () {
      this.tijiao();
    }, 600),

    // 提交
    tijiao() {
      // console.log("提交");
      if (this.taDatalist.length === 0) {
        Dialog.alert({
          title: "提示",
          message: `入库货品不能为空`,
        }).then(() => {
          // on close
        });
      } else {
        this.taDatalist.forEach((item) => {
          // console.log(item);
          let obj = {
            item_no: item.item_no,
            item_num: item.item_num,
          };
          this.dfrom.items.push(obj);
        });
        this.loading = true;
        this.Deployinputs();
        // console.log(this.dfrom);
      }
    },

    frmLoss() {
      // console.log("报损");
      this.$router.push({
        path: `/frmLoss?id=${ this.id}&name=${this.cangname}`,
      });
    },

    // 弹出层
    tan() {
      this.show = true;
    },

    // 选中仓库确认
    onConfirm(val) {
      this.cangname = val.name;
      // console.log(val);
      this.show = false;
      this.loading = true;
    },

    // 选中仓库取消
    onCancel() {
      this.show = false;
    },

    // 打开相机
    clickCode() {
      if (this.goods_list.length === 0) {
        Dialog.alert({
          title: "提示",
          message: `暂无待入库信息`,
        }).then(() => {
          // on close
        });
      } else {
        this.qrcode = true;
        this.camera = "rear";
        this.flag = false;
      }
    },

    // 扫码结果回调
    onDecode(result) {
      // console.log(result);
      this.Huopixianglist(result);
      this.flag = true;
      this.turnCameraOff();
    },

    // 相机反转
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
        default:
          this.$toast("错误");
      }
    },
    // 关闭相机？？？？？？
    turnCameraOff() {
      this.camera = "off";
      this.qrcode = false;
    },
    // 打开手电筒
    ClickFlash() {
      switch (this.torchActive) {
        case true:
          this.torchActive = false;
          break;
        case false:
          this.torchActive = true;
          break;
        default:
          this.$toast("错误");
      }
    },

    // 检查是否调用摄像头
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "StreamApiNotSupportedError") {
        } else if (error.name === "NotAllowedError") {
          this.errorMessage = "Hey! I need access to your camera";
        } else if (error.name === "NotFoundError") {
          this.errorMessage = "Do you even have a camera on your device?";
        } else if (error.name === "NotSupportedError") {
          this.errorMessage =
            "Seems like this page is served in non-secure context (HTTPS, localhost or file://)";
        } else if (error.name === "NotReadableError") {
          this.errorMessage =
            "Couldn't access your camera. Is it already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.errorMessage =
            "Constraints don't match any installed camera. Did you asked for the front camera although there is none?";
        } else {
          this.errorMessage = "UNKNOWN ERROR: " + error.message;
        }
      }
    },

    async Daichulilist() {
      const { data } = await Daichuli({
        order_id: this.id,
        addition_type: "input",
      });
      // console.log(data);
      this.loading = false;
      this.goods_list = data.data;
      if (data.data.length === 0) {
        Dialog.alert({
          title: "提示",
          message: `没有找到待入库的数据`,
        }).then(() => {
          // on close
          // this.$router.go(-1);
        });
      }
    },

    // 拉取仓库列表
    async Canglist() {
      const { data } = await Cang();
      this.cang = data.data;
    },
    async Huopixianglist(item_no) {
      const { data } = await Huopixiang({ item_no: item_no });
      let info = data.data.info;
      let poss = -1;
      for (let i = 0; i < this.goods_list.length; i++) {
        if (info.goods_no === this.goods_list[i].goods_no) {
          poss = i;
          if (this.taDatalist.length === 0) {
            let obj = {
              batch_id: info.batch_id,
              batch_no: info.batch_no,
              goods_desc: info.goods_desc,
              goods_id: info.goods_id,
              goods_name: info.goods_name,
              goods_no: info.goods_no,
              item_no: info.item_no,
              item_num: 1,
            };
            this.taDatalist.push(obj);
          } else {
            let pos = -1;
            for (let j = 0; j < this.taDatalist.length; j++) {
              if (
                info.batch_id === this.taDatalist[j].batch_id &&
                info.goods_id === this.taDatalist[j].goods_id
              ) {
                pos = j;
                if (
                  this.taDatalist[j].item_num < this.goods_list[i].goods_num
                ) {
                  this.taDatalist[j].item_num += 1;
                } else {
                  Dialog.alert({
                    title: "提示",
                    message: `不能超过待扫码货物数量，请核实入库数量`,
                  }).then(() => {
                    // on close
                  });
                  break;
                }
              }
            }
            if (pos === -1) {
              let obj = {
                batch_id: info.batch_id,
                batch_no: info.batch_no,
                goods_desc: info.goods_desc,
                goods_id: info.goods_id,
                goods_name: info.goods_name,
                goods_no: info.goods_no,
                item_no: info.item_no,
                item_num: 1,
              };
              this.taDatalist.push(obj);
            }
          }
        }
      }
      if (poss === -1) {
        Dialog.alert({
          title: "提示",
          message: `扫码货物在表单中不存在，请选择正确货物进行扫码`,
        }).then(() => {
          // on close
        });
      }
    },

    async Deployinputs() {
      const { data } = await Deployinput({ ...this.dfrom });
      // console.log(data);
      this.loading = false;
      if (data.code === 200) {
        Dialog.alert({
          title: "提示",
          message: `入库成功。`,
        }).then(() => {
          // on close
          this.dfrom.items = [];
          this.taDatalist = [];
          this.$router.go(-1);
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: `${data.message}`,
        }).then(() => {});
        this.dfrom.items = [];
        this.taDatalist = [];
        // console.log(this.dfrom);
        // console.log(this.taDatalist);
      }
    },
  },
  components: {
    // 注册
    qrcode: () => import("@/components/QrcodeReader"),
  },
};
</script>

<style lang='less' scoped>
.top {
  width: 100%;
  height: 8vh;
  /* border: 1px solid #000; */
  display: flex;
  position: fixed;
  top: 0%;
  left: 0%;
  align-items: center;
  background-color: #dbd5d5;
  z-index: 1000;
}
.user {
  margin-top: 15%;
  width: 100%;
  height: 15vh;
  /* border: 1px solid #000; */
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px 10px;
}
.user-left {
  display: flex;
  margin-top: 15px;
  align-items: center;
}
.user-left-one {
  width: 20%;
  text-align: left;
  margin-right: 10px;
}
.huolist {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px 5px;
}
.size {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.lie {
  margin-top: 15px;
}
.list-box {
  font-size: 14px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
}
.hint {
  width: 80%;
  margin-left: 5px;
  text-align: left;
}
.nav {
  width: 100%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  padding: 10px 5px;
}
.bold {
  font-weight: bold;
}
.huo-ones {
  width: 25%;
}
.huo-onrs {
  width: 32%;
  text-align: left;
}

.huo-onts {
  width: 32%;
  text-align: left;
}

.huo-onys {
  width: 10%;
}
.xia {
  width: 100%;
  height: 10vh;
  margin-top: 15px;
  text-align: center;
  line-height: 10vh;
}
.btns {
  border-radius: 5px;
  width: 40%;
}
.flex {
  display: flex;
  justify-content: space-around;
}
</style>