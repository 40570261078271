import { render, staticRenderFns } from "./outsource-detail.vue?vue&type=template&id=173a9b68&scoped=true&"
import script from "./outsource-detail.vue?vue&type=script&lang=js&"
export * from "./outsource-detail.vue?vue&type=script&lang=js&"
import style0 from "./outsource-detail.vue?vue&type=style&index=0&id=173a9b68&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "173a9b68",
  null
  
)

export default component.exports