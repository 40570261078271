<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">外协事项详情</div>
    </div>
    <div class="froms">
      <van-field v-model="help_name" label="外协事务名称" readonly />
      <van-field
        v-model="help_detail"
        rows="2"
        autosize
        label="外协事务详情"
        type="textarea"
        maxlength="500"
        show-word-limit
        readonly
      />
      <van-field v-model="amount" label="外协费用" readonly />
    </div>
    <div class="hint">支付图片</div>
    <div class="image">
      <van-image
        v-for="item in pay_images"
        :key="item"
        width="100"
        height="100"
        :src="item"
        class="img"
        @click="pings"
      />
    </div>
    <div class="hint">收据图片</div>
    <div class="image">
      <van-image
        v-for="item in receipt_images"
        :key="item"
        width="100"
        height="100"
        :src="item"
        class="img"
        @click="regimg"
      />
    </div>
    <van-field name="radio" label="自行采购发票">
      <template #input>
        <van-radio-group v-model="buy_bill" direction="horizontal">
          <van-radio name="1">有</van-radio>
          <van-radio name="2">无</van-radio>
        </van-radio-group>
      </template>
    </van-field>

    <div class="xia">
      <van-button type="info" @click="$router.go(-1)" class="btns"
        >返回上一层</van-button
      >
    </div>
  </div>
</template>

<script>
// 用料单类型 output-出库 buy-自行采购 help-外协 business-业务费用
import { Dialog } from "vant";
import { ImagePreview } from "vant";
import { Orderinfo } from "../../api/work.js";
export default {
  data() {
    return {
      help_name: "",
      help_detail: "",
      amount: "",
      buy_bill: "",
      pay_images: [], // 支付
      receipt_images: [],
    };
  },
  created() {
    let order_id = this.$route.query.order_id;
    this.Orderinfos(order_id);
  },
  methods: {
    // 预览
    pings() {
      ImagePreview(this.pay_images);
    },

    regimg() {
      ImagePreview(this.receipt_images);
    },

    async Orderinfos(order_id) {
      const { data } = await Orderinfo({ order_id: order_id });
      if (data.data.matter_type === "help") {
        this.help_name = data.data.help_name;
        this.help_detail = data.data.help_detail;
        this.amount = data.data.amount / 100;
        this.buy_bill = String(data.data.buy_bill);
        this.pay_images = data.data.pay_images;
        this.receipt_images = data.data.receipt_images;
      } else {
        Dialog.alert({
          title: "提示",
          message: `暂无数据`,
        }).then(() => {
          this.$router.go(-1);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  background-color: #fff;
}
.hint {
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 14px;
  color: #646566;
  margin: 5px 0px;
  width: 100%;
  background-color: #fff;
  text-align: left;
}
.image {
  box-sizing: border-box;
  padding-left: 20px;
  width: 100%;
  text-align: left;
  background-color: #fff;
}
.van-image__img {
  margin-left: 5px !important;
}
</style>