import request from "@/utils/request";

// 登录
export const login = data => {
    return request({
        url: `/sale_ticket/login`,
        method: 'post',
        data
    })
}

//  待入库列表
export const Dailist = data => {
    return request({
        url: `/storehouse/batch/list`,
        method: 'post',
        data
    })
}

// 货物批次明细入库
export const Huopiru = data => {
    return request({
        url: `/storehouse/batch/item/order`,
        method: 'post',
        data
    })
}

// 仓库列表 
export const Cang = params => {
    return request({
        url: '/storehouse/search',
        method: 'get',
        params
    })
}


// 可用货物批次明细详情 
export const Pimingxiang = data => {
    return request({
        url: `/storehouse/batch/item/available/info`,
        method: 'post',
        data
    })
}


// 出库 
export const Huowuchuku = data => {
    return request({
        url: `/storehouse/batch/item/shipment`,
        method: 'post',
        data
    })
}


// 出入库列表 
export const Churulist = data => {
    return request({
        url: `/storehouse/order/list`,
        method: 'post',
        data
    })
}

// 待入库详情 
export const Daidetaill = data => {
    return request({
        url: `/storehouse/batch/info`,
        method: 'post',
        data
    })
}