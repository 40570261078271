<template>
  <div class="box">
    <van-overlay :show="zheshow">
      <div class="wrapper">
        <van-loading type="spinner" v-if="loading" color="#0094ff"
          >加载中....</van-loading
        >
      </div>
    </van-overlay>
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">业务费用</div>
    </div>

    <div class="from">
      <van-form @submit="onSubmit">
        <van-field
          v-model="dfrom.amount"
          type="number"
          label="业务费用"
          placeholder="请输入业务费用"
          :rules="[{ required: true, message: '请输入业务费用' }]"
        />

        <van-field
          class="lalal"
          v-model="dfrom.business_detail"
          rows="2"
          autosize
          name="业务费用详情"
          label="业务费用详情"
          type="textarea"
          maxlength="500"
          placeholder="请输入业务费用详情"
          show-word-limit
          :rules="[{ required: true, message: '请填写业务费用详情' }]"
        />

        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Dialog, Notify } from "vant";
import { Billof } from "../../api/work.js";
import { Debounce } from "@/utils/common.js";
export default {
  data() {
    return {
      zheshow: false,
      loading: false,
      dfrom: {
        ticket_order: "",
        matter_type: "business",
        amount: "",
        business_detail: "",
      },
    };
  },
  created() {
    this.dfrom.ticket_order = this.$route.query.ticket_order;
    // console.log("业务费用", this.dfrom.ticket_order);
  },
  methods: {
    onSubmit: Debounce(function (values) {
      this.zheshow = true;
      this.loading = true;
      //   console.log(this.dfrom);
      this.Billofs(this.dfrom);
    }),

    async Billofs(v) {
      let ss = JSON.parse(JSON.stringify(v));
      ss.amount = JSON.stringify(ss.amount * 100);
      const { data } = await Billof(ss);
      this.zheshow = false;
      this.loading = false;
      if (data.code === 200) {
        Dialog.alert({
          title: "提示",
          message: `提交成功,将返回订单详情页`,
        }).then(() => {
          // on close
          this.$router.go(-2);
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: `${data.message}请重新提交`,
        }).then(() => {
          // on close
        });
      }
    },
  },
};
</script>

<style scoped>
.box {
  background-color: #fff;
}
.from {
  margin-top: 16%;
}
.lalal {
  border-bottom: 1px solid #999;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>