import { render, staticRenderFns } from "./accessories.vue?vue&type=template&id=efe55f60&scoped=true&"
import script from "./accessories.vue?vue&type=script&lang=js&"
export * from "./accessories.vue?vue&type=script&lang=js&"
import style0 from "./accessories.vue?vue&type=style&index=0&id=efe55f60&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efe55f60",
  null
  
)

export default component.exports