<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">订单详情页</div>
    </div>
    <van-overlay :show="zheshow">
      <div class="wrapper">
        <van-loading type="spinner" v-if="loading" color="#0094ff"
          >加载中....</van-loading
        >
      </div>
    </van-overlay>

    <!-- 用料审批单 -->
    <!-- <div class="nav"> -->
      <!-- <div class="hint chen">用料审批单<div style=" margin-left: 20% ">已完成成本总计{{orderlist.matter_amount / 100}}元</div></div> -->
      <!-- <van-list> -->
        <!-- <van-cell v-for="(item, index) in matter_items" :key="index"> -->
          <!-- <template> -->
            <!-- <div class="list" @click="detaile(item.id, item.matter_type)"> -->
              <!-- <div class="list-left"> -->
                <!-- <div class="let-one">审批单编号:{{ item.matter_order_no }}</div> -->
                <!-- <div class="let-two">申请人:{{ item.user_name }}</div> -->
                <!-- <div class="let-two" v-if="item.matter_type !== 'output'"> -->
                  <!-- 提报事项:{{ item.matter_type_desc }}-{{ item.amount / 100 }}元 -->
                <!-- </div> -->
                <!-- <div class="let-two" v-if="item.matter_type === 'output'"> -->
                  <!-- 提报事项:{{ item.matter_type_desc }} -->
                <!-- </div> -->
              <!-- </div> -->
              <!-- <div class="list-right"> -->
                <!-- <div class="zhuang">已完成</div> -->
              <!-- </div> -->
            <!-- </div> -->
          <!-- </template> -->
        <!-- </van-cell> -->
      <!-- </van-list> -->
      <!-- <div class="botn"> -->
        <!-- <van-icon -->
          <!-- name="add-o" -->
          <!-- size="40" -->
          <!-- color="#1989fa" -->
          <!-- @click="ingredient()" -->
        <!-- /> -->
      <!-- </div> -->
    <!-- </div> -->

    <div class="froms">
      <van-form>
        <van-field
          v-model="froms.work_date"
          name="date"
          label="工作日期"
          :readonly="true"
        />
        <van-field
          :readonly="readonlys"
          clickable
          name="picker"
          :value="firm"
          label="业务公司"
          placeholder="点击业务公司"
          @click="chengefirm"
          :rules="[{ required: true, message: '请选择业务公司' }]"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            title="业务公司"
            value-key="name"
            :columns="companis"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>

        <van-field
          :readonly="readonlys"
          class="inpding"
          v-model="client"
          name="客户名称"
          label="客户名称"
          placeholder="请填写客户名称"
          @input="inps"
          :rules="[{ required: true, message: '请填写客户名称' }]"
        />

        <div class="suggest" v-show="flag" style="overflow: scroll">
          <ul>
            <li
              v-for="(item, index) in this.soulist"
              :key="index"
              @click="liClick(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>

        <van-field
          v-model="froms.customer_linkman"
          name="客户联系人"
          label="客户联系人"
          placeholder="客户联系人"
          :readonly="this.readonlys"
          :rules="[{ required: true, message: '请填写客户联系人' }]"
        />

        <van-field
          :readonly="readonlys"
          v-model="froms.customer_contact"
          name="联系方式"
          type="digit"
          label="联系方式"
          placeholder="联系人联系方式"
          :rules="[{ required: true, message: '请填写客户联系方式' }]"
        />

        <van-field
          :readonly="readonlys"
          v-model="froms.maintain_address"
          name="维修地点"
          label="维修地点"
          placeholder="维修地点"
          :rules="[{ required: true, message: '请输入维修地点' }]"
        />

        <van-field name="radio" label="维修类型" :readonly="this.readonlys">
          <template #input>
            <van-radio-group
              v-model="radio"
              direction="horizontal"
              @change="radiolick"
              :disabled="disabledflag"
            >
              <van-radio name="contract">合同维修</van-radio>
              <van-radio name="temp">临时维修</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field
          v-if="radio == 'contract'"
          v-model="froms.contract_name"
          name="所属合同"
          label="所属合同"
          placeholder="所属合同"
          :readonly="this.readonlys"
        />

        <van-field
          v-if="radio == 'contract'"
          v-model="froms.contract_no"
          name="合同编号"
          label="合同编号"
          placeholder="合同编号"
          :readonly="this.readonlys"
        />

        <div style="margin: 16px" class="btns" v-if="orderStatusxiu == 1">
          <van-button type="danger" @click="edit" class="sss">编辑</van-button>

          <van-button type="info" class="sss" @click="onSubmit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>

    <!-- 维修工单 -->
    <div class="nav">
      <div class="hint chen">
        维修工单
        <div style="margin-left: 20%">
          已完成营收总计{{ orderlist.work_amount / 100 }}元
        </div>
      </div>
      <van-list>
        <van-cell v-for="(item, index) in work_items" :key="index">
          <template>
            <div class="list">
              <div class="list-left" @click="workdetail(item.id)">
                <div class="let-one">工单编号:{{ item.work_order_no }}</div>
                <div class="let-two">维修工程师:{{ item.user_name }}</div>
                <div class="let-two">提报事项:{{ item.fault_desc }}</div>
                <div class="let-two">工单报价：{{ item.amount / 100 }}元</div>
              </div>
              <div
                class="deled"
                v-if="orderStatusxiu == 1"
                @click="del(item.id)"
              >
                <van-icon
                  name="https://qiniu.haiwojiamei.com/upload/meijia/20230720/e4f1866326ce513a11e75b674febf577.png"
                  size="25"
                />
              </div>
            </div>
          </template>
        </van-cell>
      </van-list>
      <div class="botn" v-if="orderStatusxiu == 1">
        <van-icon name="add-o" size="40" color="#1989fa" @click="newwork()" />
      </div>
      <div class="pings" v-if="orderStatusxiu == 1">
        <van-button type="danger" @click="Ordercancel" class="sss"
          >取消订单</van-button
        >
        <van-button type="info" class="sss" @click="Orderok"
          >完成订单</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Debounce } from "@/utils/common.js";
import { Dialog, Notify } from "vant";
import {
  Orderdetail,
  Upstatus,
  Reimburse,
  Client,
  Ordedit,
  Orddel,
} from "../../api/work.js";
export default {
  data() {
    return {
      order_status: 0,
      orderlist: {},
      order_id: "",
      matter_items: [], // 用料单
      work_items: [], // 工单
      dfrom: {
        other: ["work_item", "matter_item"],
      },
      froms: {
        id: "",
        business_company: "", // 业务公司id
        work_date: "", // 工作日期
        customer_id: "", // 客户id
        maintain_address: "", // 维修地点
        maintain_type: "", // 维修类型 contract-合同维修 temp-临时维修
        contract_name: "", // 合同名称
        contract_no: "", // 合同编号
        customer_linkman: "", // 客户联系人
        customer_contact: "", // 客户联系方式
      },
      showPicker: false,
      firm: "", // 业务公司
      client: "", // 客户名称
      companis: [],
      soulist: [],
      flag: false,
      radio: "",
      readonlys: true,
      disabledflag: true,
      orderStatusxiu: null, // 是否能编辑
      zheshow: false,
      loading: false,
    };
  },
  created() {},
  mounted() {
    this.order_id = this.$route.query.id;
    console.log(this.order_id, "订单详情页");
    this.Orderdetails();
    this.Reimburses();
  },
  activated() {
    this.order_id = this.$route.query.id;
    console.log("重复进来", this.$route.query.id);
    this.Orderdetails();
  },
  // 保留状态
  beforeRouteLeave(to, from, next) {
    to.meta.keepAlive = true;
    next(0);
  },
  methods: {
    // 详情
    detaile(id, matter_type) {
      // console.log(id, matter_type);
      if (matter_type === "output") {
        // console.log("跳出库");
        this.$router.push({
          path: `/deldetail?order_id=${id}`,
        });
      } else if (matter_type === "buy") {
        // console.log("跳自行采购");
        this.$router.push({
          path: `/purdetail?order_id=${id}`,
        });
      } else if (matter_type === "help") {
        // console.log("跳外协");
        this.$router.push({
          path: `/outdetail?order_id=${id}`,
        });
      } else if (matter_type === "business") {
        // console.log("跳业务");
        this.$router.push({
          path: `/busdetail?order_id=${id}`,
        });
      }
    },

    // 工单详情
    workdetail(id) {
      // console.log(id);
      this.$router.push({
        path: `/workdetail?order_id=${id}`,
      });
    },

    // 添加用料审批
    ingredient() {
      this.$router.push({
        path: `/ingredient?ticket_order=${this.order_id}`,
      });
    },

    // 维修工单
    newwork() {
      this.$router.push({
        path: `/newwork?ticket_order=${this.order_id}`,
      });
    },

    // 取消订单
    Ordercancel() {
      this.order_status = 2;
      this.Upstatuson();
    },

    // 订单完成
    Orderok() {
      this.order_status = 3;
      this.Upstatuson();
    },

    // 选中业务公司
    onConfirm(value) {
      console.log(value);
      this.froms.business_company = value.value;
      this.firm = value.name;
      this.showPicker = false;
    },

    // 输入框事件
    inps: Debounce(function (value) {
      if (this.client !== "") {
        // console.log(value);
        this.soulist = [];
        this.Fenzusou(value);
        // this.zheshow = true;
        // this.loading = true;
      } else {
        this.soulist = [];
        this.flag = false;
      }
    }, 600),

    // 选中搜索后的数据
    liClick(item) {
      console.log(item);
      this.froms.customer_id = item.id;
      this.client = item.name;
      this.flag = false;
    },

    onSubmit: Debounce(function (values) {
      console.log(this.froms);
      if (this.readonlys == true) {
        Dialog.alert({
          title: "提示",
          message: `请先进行编辑操作`,
        }).then(() => {});
      } else {
        this.zheshow = true;
        this.loading = true;
        this.Ordedits();
      }
    }),

    // 单选框选中
    radiolick(e) {
      // console.log(e);
      this.froms.contract_name = "";
      this.froms.contract_no = "";
      this.froms.maintain_type = e;
    },
    chengefirm() {
      if (this.readonlys === true) {
        console.log("禁用");
      } else {
        this.showPicker = true;
      }
    },

    // 编辑
    edit() {
      this.readonlys = false;
      this.disabledflag = false;
    },

    //  详情
    async Orderdetails() {
      const { data } = await Orderdetail({
        order_id: this.order_id,
        ...this.dfrom,
      });
      console.log(data);
      this.orderlist = data.data;
      this.matter_items = data.data.matter_items;
      this.work_items = data.data.work_items;
      this.froms.work_date = data.data.work_date;
      this.froms.business_company = data.data.business_company;
      this.froms.customer_id = data.data.customer_id;
      this.froms.maintain_address = data.data.maintain_address;
      this.froms.maintain_type = data.data.maintain_type;
      this.froms.contract_name = data.data.contract_name;
      this.froms.contract_no = data.data.contract_no;
      this.froms.customer_linkman = data.data.customer_linkman;
      this.froms.customer_contact = data.data.customer_contact;
      this.client = data.data.customer_name;
      this.firm = data.data.business_company_name;
      this.radio = data.data.maintain_type;
      this.orderStatusxiu = data.data.order_status;
      this.froms.id = data.data.order_id;
      console.log(this.orderStatusxiu);
    },

    // 订单状态
    async Upstatuson() {
      const { data } = await Upstatus({
        id: this.order_id,
        order_status: this.order_status,
      });
      // console.log(data, "订单状态");
      if (data.code == 200) {
        Dialog.confirm({
          title: "提示",
          message: "操作成功,是否返回上一页",
          confirmButtonText: "返回",
          cancelButtonText: "不返回",
        })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {
            console.log("2222");
          });
      }
    },

    // 工单删除
    del(id) {
      console.log(id);

      Dialog.confirm({
        title: "提示",
        message: "确认要删除工单吗？",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.zheshow = true;
          this.loading = true;
          this.Orddels(id);
        })
        .catch(() => {
          console.log("2222");
        });
    },

    // 业务公司
    async Reimburses() {
      const { data } = await Reimburse();
      this.companis = data.data.companis;
    },

    // 客户搜索
    async Fenzusou(name) {
      const { data } = await Client({ name: name, check_all: 1 });
      // this.zheshow = false;
      // this.loading = false;
      if (data.data.length !== 0) {
        this.flag = true;
        this.soulist = data.data.list;
      } else {
        Notify({ type: "success", message: "没有找到此设备" });
        this.soulist = [];
        this.flag = false;
      }
    },

    // 修改售后订单
    async Ordedits() {
      const { data } = await Ordedit({ ...this.froms });
      console.log(data);
      this.zheshow = false;
      this.loading = false;
      if (data.code == 200) {
        this.Orderdetails();
        this.readonlys = true;
        this.disabledflag = true;
        Dialog.alert({
          title: "提示",
          message: `操作成功`,
        }).then(() => {});
      }
    },

    // 删除

    async Orddels(order_id) {
      const { data } = await Orddel({ order_id: order_id });
      console.log(data);
      this.zheshow = false;
      this.loading = false;
      if (data.code == 200) {
        this.Orderdetails();
        Dialog.alert({
          title: "提示",
          message: `操作成功`,
        }).then(() => {});
      }
    },
  },
};
</script>

<style scoped lang='less'>
.top {
  background-color: #dbd5d5;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
.froms {
  width: 100%;
  margin-top: 18%;
}
.btns {
  width: 90%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.nav {
  margin-top: 8%;
  width: 100%;
  box-sizing: border-box;
  .hint {
    border-bottom: 1px solid #999;
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    box-sizing: border-box;
    text-align: left;
    padding-left: 4%;
    font-size: 14px;
    color: red;
    font-weight: bold;
  }
  .botn {
    margin-top: 5px;
    width: 100%;
    text-align: center;
  }
  .pings {
    box-sizing: border-box;
    margin-top: 5%;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .van-cell {
    margin: 0px !important;
    padding: 0px !important;
  }
  .list {
    box-sizing: border-box;
    margin: 0px 0px;
    padding: 8px 0px 16px 20px;
    border-bottom: 1px solid #999;
    position: relative;

    .list-left {
      width: 98%;
      .let-one {
        font-size: 14px;
        width: 100%;
      }
    }
    .deled {
      box-sizing: border-box;
      padding-top: 5px;
      text-align: center;
      background-color: rgb(225, 62, 62);
      border-radius: 5px;
      position: absolute;
      top: 30%;
      right: 6%;
      width: 10%;
      height: 30%;
    }
  }
}
.chen {
  display: flex;
}
</style> 