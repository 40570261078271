<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: 110,
    };
  },
  methods: {
    tiao() {
      console.log(66);
      this.$.router.push("/nav");
    },
  },
  watch: {
    $route(to, from) {
      // console.log(to, from);
      if (to.name === "index" && from.name) {
        window.location.reload()
      }
    },
  },
};
</script>

<style lang="less">
.top {
  width: 100%;
  height: 8vh;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  background-color: #ffffff;
}
.title {
  width: 80%;
  text-align: center;
  font-size: 24px;
}

.box {
  background-color: #f3f3f3;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.user-left-one{
  font-weight: bold;
}
</style>
