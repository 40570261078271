import request from "@/utils/request";

// 供应商分组搜索 
export const Fenzu = params => {
    return request({
        url: '/storehouse/goods/group/search',
        method: 'get',
        params
    })
}

// 获取可以货品分组列表 
export const Huofen = data => {
    return request({
        url: `/storehouse/goods/avail/list`,
        method: 'post',
        data
    })
}

// 配件调仓申请列表 
export const Huotiao = data => {
    return request({
        url: `/storehouse/adjust/list`,
        method: 'post',
        data
    })
}

// 仓储审核人员 
export const Canguser = params => {
    return request({
        url: '/storehouse/auth/users',
        method: 'get',
        params
    })
}

// 申请配件调仓 
export const Shentiao = data => {
    return request({
        url: `/storehouse/adjust/apply`,
        method: 'post',
        data
    })
}

// 调仓申请单详情 
export const Diaodetail = data => {
    return request({
        url: `/storehouse/adjust/info`,
        method: 'post',
        data
    })
}

// 调仓申请单附属信息列表 
export const Diaofushu = data => {
    return request({
        url: `/storehouse/adjust/addition/list`,
        method: 'post',
        data
    })
}

// 待处理调仓申请单附属信息列表 
export const Daichuli = data => {
    return request({
        url: `/storehouse/adjust/addition/wait/list`,
        method: 'post',
        data
    })
}

// 货物批次明细详情 
export const Huopixiang = data => {
    return request({
        url: `/storehouse/batch/item/info`,
        method: 'post',
        data
    })
}

// 配件调仓出库 
export const Deployoutput = data => {
    return request({
        url: `/storehouse/adjust/output`,
        method: 'post',
        data
    })
}

// 配件调仓入库 
export const Deployinput = data => {
    return request({
        url: `/storehouse/adjust/input`,
        method: 'post',
        data
    })
}

// 配件调仓报损 
export const Deployscrap = data => {
    return request({
        url: `/storehouse/adjust/scrap`,
        method: 'post',
        data
    })
}

// 配件报损原因 
export const Cause = data => {
    return request({
        url: `/storehouse/adjust/scrap/types`,
        method: 'post',
        data
    })
}


// 货物类型树 
export const Huotree = params => {
    return request({
        url: '/storehouse/goods/type/tree',
        method: 'get',
        params
    })
}

// 创建货物类型 
export const Huocreate = params => {
    return request({
        url: '/storehouse/goods/type/create',
        method: 'post',
        params
    })
}

// 添加货品 
export const Huogoods = params => {
    return request({
        url: '/storehouse/goods/create',
        method: 'post',
        params
    })
}