<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="fan" />
      <div class="title">公共仓库调配申请</div>
    </div>
    <div class="user">
      <div class="user-left">
        <div class="user-left-one">申请人</div>
        <div>{{ username }}</div>
      </div>
      <div class="user-left" @click="tan">
        <div class="user-left-one">调入仓库</div>
        <div>{{ cangname }}</div>
      </div>
      <!-- <div class="user-left" @click="userchenge">
        <div class="user-left-one">审核人</div>
        <div>{{ usershenname }}</div>
      </div> -->

      <van-popup
        v-model="show"
        position="bottom"
        :style="{ height: '30%' }"
        close-on-click-overlay
      >
        <van-picker
          title="选择仓库"
          value-key="name"
          show-toolbar
          :columns="cang"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
      <van-popup
        v-model="usershow"
        position="bottom"
        :style="{ height: '30%' }"
        close-on-click-overlay
      >
        <van-picker
          title="选择审核人"
          value-key="name"
          show-toolbar
          :columns="userlist"
          @confirm="onuserConfirm"
          @cancel="onuserCancel"
        />
      </van-popup>
    </div>
    <van-loading type="spinner" color="#1989fa" v-if="loading" />
    <div class="hint">货物信息</div>
    <div class="host">
      <div class="xu">序号</div>
      <div class="main-one">货物编号</div>
      <div class="main-two">货物名称</div>
      <div class="main-ser">货物详情</div>
      <div class="main-for">调出仓库</div>
      <div class="main-fum">需求数量</div>
    </div>
    <div class="biggestBox">
      <ul>
        <!-- data-type=0 隐藏删除按钮 data-type=1 显示删除按钮 -->
        <li
          class="li_vessel"
          v-for="(item, index) in shopping"
          data-type="0"
          :key="index"
        >
          <!-- "touchstart" 当手指触摸屏幕时候触发  "touchend"  当手指从屏幕上离开的时候触发  "capture" 用于事件捕获-->
          <div
            @touchstart.capture="touchStart"
            @touchend.capture="touchEnd"
            @click="oneself"
          >
            <div class="contant">
              <div class="main">
                <div class="xu">{{ index + 1 }}</div>
                <div class="main-one chaosum" style="text-align: left">
                  {{ item.goods_no }}
                </div>
                <div class="main-two chaosum" style="text-align: left">
                  {{ item.goods_name }}
                </div>
                <div class="main-ser chaosum" style="text-align: left">
                  {{ item.goods_desc }}
                </div>
                <div class="main-for chaosum">{{ item.house_name }}</div>
                <div class="main-fuw chaosum">{{ item.require_num }}</div>
              </div>
            </div>
          </div>
          <div class="removeBtn" @click="remove(index)">删除</div>
        </li>
      </ul>
    </div>
    <div class="botn">
      <van-icon name="add-o" size="40" @click="opend" />
    </div>
    <div class="xia">
      <van-button
        type="info"
        class="btns"
        @click="fanggetScroll"
        :disabled="flag"
        >提交</van-button
      >
    </div>
  </div>
</template>

<script>
import { Debounce } from "@/utils/common.js";
import { Dialog, Notify } from "vant";
import { Cang } from "../../api/index.js";
import { Canguser, Shentiao } from "../../api/store.js";
export default {
  name: "index",
  data() {
    return {
      flag: false,
      loading: true,
      cang: [],
      userlist: [],
      shopping: [],
      username: "",
      usershenname: "",
      show: false,
      usershow: false,
      cangname: "",
      lists: [],
      startX: 0, //滑动开始
      endX: 0, //滑动结束
      from: {
        order_type: 1,
        house_id: "",
        auth_user: 0,
        items: [],
      },
    };
  },
  created() {
    // console.log("啊实打实大");
    this.username = localStorage.getItem("username");
    this.Canglist();
    this.Canguserlist();
    let ss = JSON.parse(sessionStorage.getItem("obj"));
    // console.log(ss);
    if (ss == null) {
      console.log("1111");
    } else {
      this.shopping = ss.shopping;
    }
  },
  methods: {
    fanggetScroll: Debounce(function () {
      this.setopen();
    }, 600),

    setopen() {
      if (this.from.house_id !== "") {
        this.shopping.forEach((item) => {
          // console.log(item);
          let obj = {
            goods_id: item.good_id,
            output_house: item.house_id,
            require_num: item.require_num,
          };
          this.from.items.push(obj);
        });
        if (this.from.items.length === 0) {
          Notify({ type: "warning", message: "请添加数据" });
        } else {
          this.loading = true;
          this.flag = true;
          this.Shentiaocang();
        }
        // console.log(this.from);
      } else {
        Notify({ type: "warning", message: "请选择调入仓库" });
      }
    },

    onConfirm(val) {
      // console.log(val);
      this.cangname = val.name;
      this.from.house_id = val.value;
      this.show = false;
    },

    onuserConfirm(val) {
      // console.log(val);
      this.from.auth_user = val.value;
      this.show = false;
      this.usershow = false;
      this.usershenname = val.name;
    },

    fan() {
      if (this.shopping.length === 0) {
        this.$router.go(-1);
      } else {
        Dialog.confirm({
          title: "提示",
          message: "是否保存当前数据",
          confirmButtonText: "保存",
          cancelButtonText: "不保存",
        })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {
            this.shopping = [];
            this.$router.go(-1);
            this.from.items = [];
            this.from.house_id = "";
            this.from.auth_user = "";
            sessionStorage.clear();
          });
      }
    },

    // 弹出层
    tan() {
      this.show = true;
    },

    opend() {
      this.$router.push({
        path: `/shop`,
      });
    },

    userchenge() {
      this.usershow = true;
    },

    onCancel() {
      this.show = false;
    },
    onuserCancel() {
      this.usershow = false;
    },

    // 拉取仓库列表
    async Canglist() {
      const { data } = await Cang();
      this.cang = data.data;
      this.loading = false;
    },

    // 仓储审核人列表
    async Canguserlist() {
      const { data } = await Canguser({ is_manager: 1 });
      // console.log(data);
      this.userlist = data.data;
      this.loading = false;
    },

    async Shentiaocang() {
      const { data } = await Shentiao({ ...this.from });
      // console.log(data);
      this.loading = false;
      if (data.code === 200) {
        // Notify({ type: "success", message: "申请成功" });
        // sessionStorage.clear();
        // this.shopping = [];
        // this.from.house_id = "";
        // this.from.auth_user = "";
        // this.cangname = "";
        // this.usershenname = "";
        // this.flag = false;
        // this.fan()
        Dialog.alert({
          title: "提示",
          message: `申请成功`,
        }).then(() => {
          sessionStorage.clear();
          this.shopping = [];
          this.from.house_id = "";
          this.from.auth_user = "";
          this.cangname = "";
          this.usershenname = "";
          this.flag = false;
          this.fan();
        });
      } else {
        Notify({ type: "danger", message: `${data.message}` });
        sessionStorage.clear();
        this.shopping = [];
        this.from.house_id = "";
        this.from.auth_user = "";
        this.cangname = "";
        this.flag = false;
        this.usershenname = "";
      }
    },

    // 向左滑动出现删除按钮时，点击商品信息区域取消删除
    oneself() {
      if (this.checkSlide()) {
        this.restSlide();
      } else {
        // 点击商品信息弹出弹框
        console.log("点击当前商品触发事件...");
      }
    },
    //滑动开始
    touchStart(e) {
      // 记录初始位置
      this.startX = e.touches[0].clientX;
    },
    //滑动结束
    touchEnd(e) {
      // 当前滑动的父级元素
      let parentElement = e.currentTarget.parentElement;
      // 记录结束位置
      this.endX = e.changedTouches[0].clientX;
      // 左滑大于30距离删除出现
      if (parentElement.dataset.type == 0 && this.startX - this.endX > 30) {
        this.restSlide();
        parentElement.dataset.type = 1;
      }
      // 右滑
      if (parentElement.dataset.type == 1 && this.startX - this.endX < -30) {
        this.restSlide();
        parentElement.dataset.type = 0;
      }
      this.startX = 0;
      this.endX = 0;
    },
    //判断当前是否有滑块处于滑动状态
    checkSlide() {
      let listItems = document.querySelectorAll(".li_vessel");
      for (let i = 0; i < listItems.length; i++) {
        if (listItems[i].dataset.type == 1) {
          return true;
        }
      }
      return false;
    },
    //复位滑动状态
    restSlide() {
      let listItems = document.querySelectorAll(".li_vessel");
      // 复位
      for (let i = 0; i < listItems.length; i++) {
        listItems[i].dataset.type = 0;
      }
    },
    //删除数据信息
    remove(index) {
      console.log(index);
      // 当前索引值
      // 复位
      this.restSlide();
      // 删除数组lists中一个数据
      this.shopping.splice(index, 1);

      var obj = {
        data: 111,
        shopping: this.shopping,
      };
      sessionStorage.setItem("obj", JSON.stringify(obj));
    },
  },
};
</script>

<style scoped lang="less">
.hint {
  width: 80%;
  color: rgb(146, 128, 128);
  text-align: left;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}
.botn {
  width: 100%;
  text-align: center;
}
* {
  /* 消除默认内外边距 */
  margin: 0;
  padding: 0;
}

body {
  background: rgb(246, 245, 250);
}

.biggestBox {
  overflow: hidden;
  /*超出部分隐藏*/
}

ul {
  /* 消除 ul 默认样式 */
  list-style: none;
  padding: 0;
  margin: 0;
}

.li_vessel {
  /* 全部样式 0.2秒 缓动*/
  transition: all 0.2s;
}

/* =0隐藏 */
.li_vessel[data-type="0"] {
  transform: translate3d(0, 0, 0);
}

/* =1显示 */
.li_vessel[data-type="1"] {
  /* -64px 设置的越大可以左滑的距离越远，最好与下面删除按钮的宽度以及定位的距离设置一样的值*/
  transform: translate3d(-64px, 0, 0);
}

/* 删除按钮 */
.li_vessel .removeBtn {
  width: 64px;
  height: 60px;
  background: #ff4949;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 22px;
  position: absolute;
  top: 0px;
  right: -64px;
  line-height: 60px;
  text-align: center;
  border-radius: 2px;
}

/* 左边的图片样式 */
.contant {
  overflow: hidden;
  /*消除图片带来的浮动*/
  padding: 10px;
  background: #ffffff;
}

.contant .image {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  float: left;
}

/* 右边的文字信息样式 */
.rightBox {
  overflow: hidden;
  padding-left: 8px;
}

.rightBox div:first-child {
  font-weight: bold;
}

.rightBox div:nth-child(2) {
  margin-top: 4px;
  font-size: 14px;
}

.rightBox div:nth-child(3) {
  width: 36px;
  background: rgb(219, 91, 113);
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 2px 4px 2px 4px;
  margin-left: auto;
}

.rightBox div:last-child {
  color: red;
  font-size: 14px;
  font-weight: bold;
}

.user {
  margin-top: 15%;
  width: 100%;
  height: 15vh;
  /* border: 1px solid #000; */
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px 10px;
}
.user-left {
  display: flex;
  margin-top: 15px;
  align-items: center;
}
.user-left-one {
  width: 20%;
  text-align: left;
  margin-right: 10px;
}
.main {
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5vh;
  .chaosum {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .xu {
    width: 10%;
  }
  .main-one {
    width: 20%;
  }
  .main-two {
    width: 20%;
  }
  .main-ser {
    width: 20%;
  }
  .main-for {
    width: 20%;
  }
  .main.fuw {
    width: 20%;
  }
}
.host {
  display: flex;
  align-items: center;
  font-size: 12px;
  .xu {
    width: 10%;
  }
  .main-one {
    width: 20%;
  }
  .main-two {
    width: 20%;
  }
  .main-ser {
    width: 20%;
  }
  .main-for {
    width: 20%;
  }
  .main.fuw {
    width: 20%;
  }
}
.xia {
  width: 100%;
  .btns {
    width: 60%;
    border-radius: 5px;
  }
}
.top {
  background-color: #dbd5d5;
}
</style>