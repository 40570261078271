<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="fans" />
      <div class="title">新增维修工单</div>
    </div>
    <van-overlay :show="zheshow">
      <div class="wrapper">
        <van-loading type="spinner" v-if="loading" color="#0094ff"
          >加载中....</van-loading
        >
      </div>
    </van-overlay>
    <div class="from">
      <van-form @submit="onSubmit">
        <van-field
          v-model="dfrom.username"
          name="维修工程师"
          label="维修工程师"
          placeholder="维修工程师"
          readonly
        />

        <van-field
          class="inpding"
          v-model="dfrom.shebei"
          name="维修设备名称"
          label="维修设备名称"
          placeholder="请填写维修设备名称"
          @input="inps"
          :rules="[{ required: true, message: '请填写维修设备名称' }]"
        />

        <div class="suggest" v-show="flag" style="overflow: scroll">
          <ul>
            <li
              v-for="(item, index) in this.soulist"
              :key="index"
              @click="liClick(item)"
            >
              {{ item.goods_name }}
            </li>
          </ul>
        </div>

        <van-field
          readonly
          clickable
          name="维修设备生产日期"
          :value="dfrom.device_date"
          label="维修设备生产日期"
          placeholder="点击选择时间"
          @click="showCalendar = true"
          :rules="[{ required: true, message: '请选择时间' }]"
        />
        <van-popup v-model="showCalendar" position="bottom">
          <van-datetime-picker
            type="year-month"
            :min-date="minDate"
            @confirm="onConfirm"
            @cancel="showCalendar = false"
          />
        </van-popup>

        <van-field
          v-model="dfrom.fault_desc"
          rows="2"
          autosize
          name="故障描述"
          label="故障描述"
          type="textarea"
          maxlength="500"
          placeholder="请输入故障描述"
          show-word-limit
          :rules="[{ required: true, message: '请填写故障描述' }]"
        />

        <van-field
          v-model="dfrom.maintain_scheme"
          rows="2"
          autosize
          name="维修方案"
          label="维修方案"
          type="textarea"
          maxlength="500"
          placeholder="请输入维修方案"
          show-word-limit
          :rules="[{ required: true, message: '请填写维修方案' }]"
        />

        <van-field
          name="guuploader"
          label="故障照片"
          :rules="[{ required: true, message: '请上传故障照片' }]"
        >
          <template #input>
            <van-uploader
              v-model="guuploader"
              :after-read="guafterRead"
              :before-delete="gubefore"
            />
          </template>
        </van-field>

        <van-field
          name="guuploader"
          label="维修完成照片"
          :rules="[{ required: true, message: '请上传完成照片' }]"
        >
          <template #input>
            <van-uploader
              v-model="uploader"
              :after-read="afterRead"
              :before-delete="before"
            />
          </template>
        </van-field>

        <div class="hint">
          货物消耗
          <span style="color: red">请选择完配件之后再填写价格信息</span>
        </div>

        <section class="content">
          <div class="lings">
            <div class="item blod">货物编号</div>
            <div class="item blod">货物名称</div>
            <div class="item blod">销售单价</div>
            <div class="item blod">数量</div>
            <div class="item blod">小计</div>
          </div>
          <div v-for="(item, index) in this.parts" :key="index" class="lings">
            <div class="item">{{ item.goods_no }}</div>
            <div class="item">{{ item.goods_name }}</div>
            <div class="item">
              <input
                v-model="item.goods_price"
                @input="item.goods_price = inputCheck(item.goods_price)"
                class="inpsus"
              />
            </div>
            <div class="item">{{ item.require_num }}</div>
            <div class="item">
              {{ item | money }}
            </div>
          </div>
        </section>

        <div class="mayns">
          <van-icon name="add-o" size="30" @click="opend" />
        </div>

        <div class="mayns maring">
          <div>货物总计</div>
          <div>{{ totalCount }} 元</div>
        </div>

        <!-- <div class="botn">
          <van-icon name="add-o" size="30" @click="opend" />
        </div> -->

        <div class="hint">
        维修费用
          <!-- <span style="color: red">请选择完配件之后再填写价格信息</span> -->
        </div>
        <div class="worktime">
          <div>项目</div>
          <div>单价(元)</div>
          <div>时长(h)</div>
          <div>合计(元)</div>
        </div>
        <div class="worktime" style="border-top: none">
          <div>工单工时</div>

          <div>
            <input
              type="number"
              @input="dfrom.work_time_price = inputCheck(dfrom.work_time_price)"
              v-model="dfrom.work_time_price"
              style="width: 50%; margin-left: 12%; border: none"
            />
          </div>
          <div>
            <input
              type="number"
              @input="dfrom.work_time_num = inputCheck(dfrom.work_time_num)"
              v-model="dfrom.work_time_num"
              style="width: 50%; border: none; margin-left: 12%"
            />
          </div>
          <div>{{ workTimetol }}</div>
        </div>

        <div class="car" style="border-top: none">
          <div>出车费</div>
          <div>
            <input
              type="number"
              @input="dfrom.car_fee = inputCheck(dfrom.car_fee)"
              v-model="dfrom.car_fee"
              style="width: 90%; border: none"
              class="inp"
            />
          </div>
          <div>{{ dfrom.car_fee }}</div>
        </div>

        <div class="wokertitle">
          <div>本次维修工单含税总报价</div>
          <div>{{ workTotal }} 元</div>
        </div>

        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Newwork } from "../../api/work.js";
import { Dialog, Notify } from "vant";
import { Fenzu } from "../../api/store";
import { Debounce } from "@/utils/common.js";
import { Upimg } from "@/api/upimg.js";
export default {
  data() {
    return {
      minDate: new Date(2008, 0, 1),
      loading: false,
      flag: false,
      showCalendar: false,
      uploader: [],
      guuploader: [],
      loading: false,
      zheshow: false,
      parts: [],
      soulist: [],
      a: "",
      b: "",
      dfrom: {
        ticket_order: "",
        device_id: "", // 维修设备ID*
        device_date: "", // 维修设备日期* 2022-12
        fault_desc: "", // 故障原因**
        maintain_scheme: "", // 维修方案*
        fault_images: [], // 故障图片*
        maintain_images: [], // 维修完成图片*
        goods_items: [], // 配件列表配件
        work_time_num: "", // 工单时长
        work_time_price: "", // 工单单价
        work_time_amount: "", // 工单总计
        car_fee: "", // 出车费
      },
    };
  },
  filters: {
    money(val) {
      // console.log(val);
      let abc = val.goods_price * val.require_num;
      // console.log(abc);
      return abc.toFixed(2);
    },
  },
  created() {
    this.dfrom.ticket_order = this.$route.query.ticket_order;
    // console.log("维修工单", this.dfrom.ticket_order);
    this.dfrom.username = localStorage.getItem("username");
    let ss = JSON.parse(sessionStorage.getItem("sle"));
    // console.log(ss);
    if (ss == null) {
      console.log("1111");
    } else {
      let arrs = ss.parts;
      arrs.map((item) => {
        item.goods_price = 0;
      });
      this.parts = arrs;
    }
  },

  // 保留状态
  beforeRouteLeave(to, from, next) {
    to.meta.keepAlive = true;
    next(0);
  },

  // 实例被激活时使用，用于重复激活一个实例的时候
  activated() {
    this.dfrom.ticket_order = this.$route.query.ticket_order;
    // console.log("维修工单", this.dfrom.ticket_order);
    let ss = JSON.parse(sessionStorage.getItem("sle"));
    // console.log(ss);
    if (ss == null) {
      console.log("1111");
      this.parts = [];
    } else {
      let arrs = ss.parts;
      arrs.map((item) => {
        item.goods_price = 0;
      });
      this.parts = arrs;
    }
  },

  // 总计
  computed: {
    totalCount() {
      var total = 0;
      this.parts.forEach((item) => {
        total += item.goods_price * item.require_num;
      });
      this.a = total.toFixed(2);
      console.log(this.a);
      return total.toFixed(2);
    },
    workTimetol() {
      var tol = 0;
      tol += this.dfrom.work_time_price * this.dfrom.work_time_num;
      this.dfrom.work_time_amount = tol;
      console.log(this.dfrom.work_time_amount);
      return tol.toFixed(2);
    },
    workTotal() {
      var total = 0;
      let sc =
        this.a * 100 +
        this.dfrom.work_time_amount * 100 +
        this.dfrom.car_fee * 100;
      total = sc / 100;
      console.log(sc);
      return total;
    },
  },

  methods: {
    inputCheck(value) {
      return value
        .replace(/^0[0-9]+/, (val) => val[1])
        .replace(/^(\.)+/, "")
        .replace(/[^\d.]/g, "")
        .replace(/\.+/, ".")
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
    },

    // 输入框事件
    inps: Debounce(function (value) {
      if (this.dfrom.shebei !== "") {
        // console.log(value);
        this.soulist = [];
        this.Fenzusou(value);
        // this.zheshow = true;
        // this.loading = true;
      } else {
        this.soulist = [];
        this.flag = false;
      }
    }, 600),

    // 选中搜索后的数据
    liClick(item) {
      // console.log(item);
      this.dfrom.device_id = item.id;
      this.flag = false;
      this.dfrom.shebei = item.goods_name;
    },

    // 返回主页 清空缓存
    fans() {
      this.$router.go(-1);
      this.uploader = [];
      this.guuploader = [];
      this.dfrom.shebei = "";
      this.dfrom.ticket_order = "";
      this.dfrom.device_id = "";
      this.dfrom.device_date = "";
      this.dfrom.fault_desc = "";
      this.dfrom.maintain_scheme = "";
      this.dfrom.work_time_price = "";
      this.dfrom.work_time_num = "";
      this.dfrom.work_time_amount = "";
      this.dfrom.car_fee = "";
      this.dfrom.fault_images = [];
      this.dfrom.maintain_images = [];
      this.dfrom.goods_items = [];
      this.parts = [];
      sessionStorage.clear();
    },

    // 表单数据
    onSubmit: Debounce(function (values) {
      // console.log("submit", values);
      this.dfrom.goods_items = [];
      // console.log(this.parts);
      this.parts.forEach((item, index) => {
        // console.log(item, index);
        let obj = {
          goods_id: item.id,
          goods_price: item.goods_price * 100,
          goods_num: item.require_num,
          amount: item.goods_price * 100 * item.require_num,
        };
        this.dfrom.goods_items.push(obj);
      });
      if (this.dfrom.goods_items.length !== 0) {
        let poss = -1;
        for (let i = 0; i < this.dfrom.goods_items.length; i++) {
          if (this.dfrom.goods_items[i].goods_price === 0) {
            poss = i;
          }
        }
        if (poss === -1) {
          this.Newworks();
          this.zheshow = true;
          this.loading = true;
        } else {
          Dialog.alert({
            title: "提示",
            message: `请填写配件单价`,
          }).then(() => {
            // on close
          });
        }
      } else {
        Dialog.alert({
          title: "提示",
          message: `清添加消耗的配件`,
        }).then(() => {
          // on close
        });
      }
      // console.log(this.dfrom);
    }, 600),

    opend() {
      // console.log("点击了");
      this.$router.push({
        path: `/select`,
      });
    },

    // 转换时间戳
    onConfirm(date) {
      this.dfrom.device_date = `${date.getFullYear()}-${date.getMonth() + 1}`;
      this.showCalendar = false;
      // console.log(this.dfrom.device_date);
    },

    // 故障照片
    gubefore(file, detail) {
      // console.log(file, detail);
      this.dfrom.fault_images.forEach((item, index) => {
        if (detail.index === index) {
          this.dfrom.fault_images.splice(index, 1);
        }
      });
      this.guuploader.forEach((item, index) => {
        if (detail.index === index) {
          this.guuploader.splice(index, 1);
        }
      });
      // console.log(this.guuploader, this.dfrom);
    },

    // 故障图片上传
    async guafterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.zheshow = true;
      this.loading = true;
      // console.log(file);
      const fd = new FormData();
      fd.append("file[]", file.file);
      const data = await Upimg(fd);
      // console.log(data);
      if (data.status === 200) {
        this.zheshow = false;
        this.loading = false;
        // let obj = {
        //   url: data.data[0].url,
        // };
        this.dfrom.fault_images.push(data.data[0].url);
        // console.log(this.dfrom);
      } else {
        this.zheshow = false;
        this.loading = false;
      }
    },

    // 完成照片
    before(file, detail) {
      // console.log(file, detail);
      this.dfrom.maintain_images.forEach((item, index) => {
        // console.log(item, index);
        if (detail.index === index) {
          this.dfrom.maintain_images.splice(index, 1);
        }
      });
      this.uploader.forEach((item, index) => {
        // console.log(item, index);
        if (detail.index === index) {
          this.uploader.splice(index, 1);
        }
        // console.log(this.uploader);
      });
      // console.log(this.uploader, this.dfrom);
    },

    // 完成图片上传
    async afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.zheshow = true;
      this.loading = true;
      // console.log(file);
      const fd = new FormData();
      fd.append("file[]", file.file);
      const data = await Upimg(fd);
      // console.log(data);
      if (data.status === 200) {
        this.zheshow = false;
        this.loading = false;
        // let obj = {
        //   url: data.data[0].url,
        // };
        this.dfrom.maintain_images.push(data.data[0].url);
        // console.log(this.dfrom);
      } else {
        this.zheshow = false;
        this.loading = false;
      }
    },

    // 设备搜索
    async Fenzusou(name) {
      const { data } = await Fenzu({ name: name, check_all: 1, class: "XS" });
      // this.zheshow = false;
      // this.loading = false;
      if (data.data.length !== 0) {
        this.flag = true;
        let soudata = data.data;
        for (let i = 0; i < soudata.length; i++) {
          for (let j = 0; j < soudata[i].list.length; j++) {
            for (let k = 0; k < soudata[i].list[j].list.length; k++) {
              for (
                let index = 0;
                index < soudata[i].list[j].list[k].list.length;
                index++
              ) {
                this.soulist.push(soudata[i].list[j].list[k].list[index]);
              }
            }
          }
        }
        // console.log(this.soulist);
      } else {
        Notify({ type: "success", message: "没有找到此设备" });
        this.soulist = [];
        this.flag = false;
      }
    },

    // 新售后工单提交
    async Newworks() {
      var newdfrom = JSON.parse(JSON.stringify(this.dfrom));
      newdfrom.work_time_price =
        newdfrom.work_time_price !== undefined
          ? newdfrom.work_time_price * 100
          : 0;
      newdfrom.work_time_amount =
        newdfrom.work_time_amount !== undefined
          ? newdfrom.work_time_amount * 100
          : 0;
      newdfrom.car_fee =
        newdfrom.car_fee !== undefined ? newdfrom.car_fee * 100 : 0;
      console.log(newdfrom);
      const { data } = await Newwork({ ...newdfrom });
      // console.log(data);
      this.zheshow = false;
      this.loading = false;
      if (data.code === 200) {
        Dialog.alert({
          title: "提示",
          message: `提交成功，等待维修工单审批中，将返回订单详情页`,
        }).then(() => {
          // on close
          this.uploader = [];
          this.guuploader = [];
          this.dfrom.shebei = "";
          this.dfrom.ticket_order = "";
          this.dfrom.device_id = "";
          this.dfrom.device_date = "";
          this.dfrom.fault_desc = "";
          this.dfrom.maintain_scheme = "";
          this.dfrom.work_time_price = "";
          this.dfrom.work_time_num = "";
          this.dfrom.work_time_amount = "";
          this.dfrom.car_fee = "";
          this.dfrom.fault_images = [];
          this.dfrom.maintain_images = [];
          this.dfrom.goods_items = [];
          this.parts = [];
          this.$router.go(-1);
          sessionStorage.clear();
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: `${data.message}请重新提交`,
        }).then(() => {
          // on close
        });
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.botn {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.hint {
  width: 80%;
  text-align: left;
  height: 5vh;
  margin-left: 15px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}
.from {
  width: 100%;
  overflow: hidden;
  margin-top: 18%;
}
.content {
  width: 100%;
  background-color: #fff;
}
.item {
  box-sizing: border-box;
  display: inline-block;
  /* margin: 10px 0px; */
  padding: 10px 0px;
  white-space: pre-wrap;
  font-size: 12px;
  /* line-height: 4vh; */
  /* height: 100%; */
  text-align: center;
}
.lings {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-top: 1px solid #999;
  border-right: 1px solid #999;
}
.lings :nth-of-type(1) {
  border-left: 1px solid #999;
  width: 27%;
  border-right: 1px solid #999;
}
.lings :nth-of-type(2) {
  width: 27%;
  border-right: 1px solid #999;
}
.lings :nth-of-type(3) {
  width: 15%;
  border-right: 1px solid #999;
}
.lings :nth-of-type(4) {
  width: 15%;
  border-right: 1px solid #999;
}
.lings :nth-of-type(5) {
  width: 15%;
}
.blod {
  font-weight: bold;
}
.inpding {
  position: relative;
}
.suggest {
  position: absolute;
  top: 24%;
  left: 0%;
  width: 70%;
  border-radius: 5px;
  /* height: 25vh; */
  background-color: #fff;
  border: 1px solid #d3d4d5;
  margin-left: 17%;
  overflow: hidden;
  z-index: 1000;
  height: 30vh;
}
li {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0px 5px;
}
.top {
  position: fixed;
  top: 0%;
  left: 0%;
  align-items: center;
  background-color: #dbd5d5;
  z-index: 1000;
}
.mayns {
  box-sizing: border-box;
  width: 100%;
  height: 5vh;
  line-height: 5vh;
  border: 1px solid #999;
  display: flex;
  justify-content: space-around;
  font-size: 14px;
}
.inpsus {
  width: 90% !important;
  height: 100% !important;
  box-sizing: border-box;
  padding-left: 25%;
  border: none !important;
}
.worktime {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #999;
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-size: 14px;
}
.worktime div {
  line-height: 5vh !important;
}
.worktime :nth-of-type(1) {
  width: 25%;
}
.worktime :nth-of-type(2) {
  width: 25%;
  border-left: 1px solid #999;
}
.worktime :nth-of-type(3) {
  width: 25%;
  border-left: 1px solid #999;
}
.worktime :nth-of-type(4) {
  border-left: 1px solid #999;
  width: 25%;
}
.car {
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  width: 100%;
  border: 1px solid #999;
  text-align: center;
  font-size: 14px;
  line-height: 5vh !important;
  margin-bottom: 20px;
}
.car :nth-of-type(1) {
  width: 25%;
  border-right: 1px solid #999;
}
.car :nth-of-type(2) {
  box-sizing: border-box;
  padding-left: 9%;
  width: 51%;
  border-right: 1px solid #999;
}
.car :nth-of-type(3) {
  width: 25%;
}
.inp {
  box-sizing: border-box;
  padding-left: 25%;
}
.maring {
  margin-bottom: 20px;
}
.wokertitle{
  margin-top: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}
</style>