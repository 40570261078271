import { render, staticRenderFns } from "./purchase-detail.vue?vue&type=template&id=61c572fb&scoped=true&"
import script from "./purchase-detail.vue?vue&type=script&lang=js&"
export * from "./purchase-detail.vue?vue&type=script&lang=js&"
import style0 from "./purchase-detail.vue?vue&type=style&index=0&id=61c572fb&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c572fb",
  null
  
)

export default component.exports