<template>
  <div class="box">
    <van-overlay :show="zheshow">
      <div class="wrapper">
        <van-loading type="spinner" v-if="loading" color="#0094ff"
          >加载中....</van-loading
        >
      </div>
    </van-overlay>
    <div class="boos">
      <img
        src="https://qiniu.haiwojiamei.com/logo/JAMI%20logo.png"
        alt=""
        id="pic"
      />
    </div>
    <div class="shou">
      <van-field
        v-model="tel"
        type="tel"
        label="手机号"
        class="inp"
        placeholder="请输入手机号"
      />
    </div>
    <div>
      <van-button type="info" class="btn" size="large " @click="btns"
        >登录</van-button
      >
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
import { login } from "../api/index";
export default {
  data() {
    return {
      tel: "",
      zheshow: false,
      loading: false,
    };
  },
  methods: {
    btns() {
      console.log("111");
      if (this.tel == "") {
        Notify({ type: "danger", message: "请输入手机号" });
      } else {
        this.zheshow = true;
        this.loading = true;
        this.danglu();
      }
    },

    async danglu() {
      const { data } = await login({ mobile: this.tel });
      // console.log(data);
      this.zheshow = false;
      this.loading = false;
      if (data.code != 200) {
        return Notify({ type: "danger", message: `${data.message}` });
      } else {
        Notify({ type: "primary", message: "登录成功" });
        localStorage.setItem("username", data.data.name);
        localStorage.setItem("token", data.data.ticket_token);
        this.$router.push({
          path: `/index`,
        });
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.box {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
.boos {
  margin-top: 10px;
  width: 100%;
  height: 10vh;
  /* border: 1px solid #000; */
  text-align: center;
}
#pic {
  width: 60%;
  height: 10vh;
}
.shou {
  margin-top: 30px;
  width: 100%;
  /* border: 1px solid #000; */
}
.inp {
  background-color: #f5f5f5 !important;
  height: 8vh;
  line-height: 6vh;
  font-size: 20px;
}
.btn {
  border-radius: 5px;
  margin-top: 15px;
}
</style>