<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">业务费用详情</div>
    </div>
    <div class="froms">
      <van-field v-model="amount" label="业务费用" readonly />
      <van-field
        v-model="business_detail"
        rows="2"
        autosize
        label="业务费用详情"
        type="textarea"
        maxlength="500"
        show-word-limit
        readonly
      />
    </div>
    <div class="xia">
      <van-button type="info" @click="$router.go(-1)" class="btns"
        >返回上一层</van-button
      >
    </div>
  </div>
</template>

<script>
// 用料单类型 output-出库 buy-自行采购 help-外协 business-业务费用
import { Orderinfo } from "../../api/work.js";
import { Dialog } from "vant";
export default {
  data() {
    return {
      amount: "",
      business_detail: "",
    };
  },
  created() {
    let order_id = this.$route.query.order_id;
    this.Orderinfos(order_id);
  },
  methods: {
    async Orderinfos(order_id) {
      const { data } = await Orderinfo({ order_id: order_id });
      if (data.data.matter_type === "business") {
        this.amount = Number(data.data.amount / 100);
        this.business_detail = data.data.business_detail;
      } else {
        Dialog.alert({
          title: "提示",
          message: `暂无数据`,
        }).then(() => {
          this.$router.go(-1);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>