import request from "@/utils/request";

// 新售后工单提交 
export const Newwork = data => {
    return request({
        url: `/sale_ticket/work/order/create`,
        method: 'post',
        data
    })
}

// 新售后工单详情 
export const Workdetaill = data => {
    return request({
        url: `/sale_ticket/work/order/info`,
        method: 'post',
        data
    })
}

// 新售后用料单提交 
export const Billof = data => {
    return request({
        url: `/sale_ticket/matter/order/create`,
        method: 'post',
        data
    })
}


// 新售后用料单详情 
export const Orderinfo = data => {
    return request({
        url: `/sale_ticket/matter/order/info`,
        method: 'post',
        data
    })
}


// 业务公司 
export const Reimburse = params => {
    return request({
        url: '/sale/reimburse/options',
        method: 'get',
        params
    })
}

// 客户列表 
export const Client = params => {
    return request({
        url: '/customer/list',
        method: 'get',
        params
    })
}

// 新售后订单提交 
export const Ordercreate = data => {
    return request({
        url: `/sale_ticket/order/create`,
        method: 'post',
        data
    })
}


// 新售后订单列表 
export const Orderlist = data => {
    return request({
        url: `/sale_ticket/order/list`,
        method: 'post',
        data
    })
}

// 新售后订单详情 
export const Orderdetail = data => {
    return request({
        url: `/sale_ticket/order/info`,
        method: 'post',
        data
    })
}

// 修改售后订单状态
export const Upstatus = data => {
    return request({
        url: `/sale_ticket/order/up_status`,
        method: 'post',
        data
    })
}

// 修改售后订单
export const Ordedit = data => {
    return request({
        url: `/sale_ticket/order/edit`,
        method: 'post',
        data
    })
}

// 工单删除 

export const Orddel = data => {
    return request({
        url: `/sale_ticket/work/order/del`,
        method: 'post',
        data
    })
}
