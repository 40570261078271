<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">货物调配列表</div>
    </div>

    <div class="shai">
      <div class="shai-left" @click="cangshai">
        <div class="let">状态筛选</div>
        <div class="let">{{ shainame }}</div>
      </div>
      <div class="shai-right" @click="rishai">
        <div class="let">时间筛选</div>
        <div class="let">{{ date }}</div>
      </div>
    </div>

    <div class="shen">
      <van-button type="info" @click="deployfor" class="btns"
        >公共仓库调配</van-button
      >
      <!-- <van-button type="info"  class="btns">返回上一层</van-button> -->
    </div>

    <van-loading type="spinner" color="#1989fa" v-if="loading" />
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell v-for="(item, index) in list" :key="index">
        <template>
          <div class="list" @click="xiang(item.id)">
            <div class="list-left">
              <div class="let-one">调库编号:{{ item.order_no }}</div>
              <div class="let-two">调出仓库:{{ item.output_house }}</div>
              <div class="let-two">调入仓库:{{ item.input_house_name }}</div>
              <div class="let-two">进行耗时:{{ item.waiting_days }}</div>
            </div>
            <div class="list-right">
              <div class="zhuang">{{ item.adjust_status_desc }}</div>
            </div>
          </div>
        </template>
      </van-cell>
    </van-list>

    <!-- 状态弹窗  -->
    <van-popup
      v-model="show"
      position="bottom"
      :style="{ height: '40%' }"
      close-on-click-overlay
    >
      <van-picker
        title="选择状态"
        value-key="name"
        show-toolbar
        :columns="cang"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>

    <van-calendar
      v-model="rishow"
      :min-date="minDate"
      :max-date="maxDate"
      type="range"
      @confirm="onTime"
    />
  </div>
</template>

<script>
import { Notify } from "vant";
import { Huotiao } from "../../api/store.js";
export default {
  data() {
    return {
      from_time: "",
      end_time: "",
      date: "",
      show: false,
      //列表
      list: [],
      // 加载状态
      loading: true,
      // 是否加载结束
      finished: false,
      // 页码值
      pageNo: 1,
      //条数
      pageSize: 10,
      // 数据总条数
      total: "",
      status: -1,
      rishow: false,
      shainame: "",
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2025, 0, 31),
      cang: [
        { name: "全部", value: -1 },
        { name: "草稿", value: 0 },
        { name: "已完成", value: 1 },
        { name: "审批中", value: 2 },
        { name: "已拒绝", value: 3 },
        { name: "已撤回", value: 4 },
        { name: "正在出库", value: 5 },
        { name: "正在入库", value: 6 },
        { name: "运输中", value: 7 },
      ],
    };
  },
  created() {
    this.Huotiaolist();
  },
  methods: {
    xiang(id) {
      // console.log(id);
      this.$router.push({
        path: `/trade?id=${id}`,
      });
    },

    deployfor() {
      this.$router.push({
        path: `/deployfor`,
      });
    },

    // 筛选状态选中
    onConfirm(val) {
      this.list = [];
      // console.log(val);
      this.status = val.value;
      this.shainame = val.name;
      this.show = false;
      this.Huotiaolist();
    },

    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },

    onTime(date) {
      this.list = [];
      const [start, end] = date;
      // console.log(date);
      this.rishow = false;
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.from_time = `${this.formatDate(start)}`;
      this.end_time = `${this.formatDate(end)}`;
      this.Huotiaolist();
    },

    rishai() {
      this.rishow = true;
    },

    onCancel() {
      this.show = false;
    },

    cangshai() {
      this.show = true;
    },

    onLoad() {
      this.pageNo += 1;
      this.Huotiaolist();
    },

    async Huotiaolist() {
      const { data } = await Huotiao({
        order_type: 1,
        status: this.status,
        page: this.pageNo,
        size: this.pageSize,
        from_time: this.from_time,
        end_time: this.end_time,
      });
      // console.log(data);
      let rows = data.data.data;
      if (rows === [] || rows.length === 0) {
        // this.list = [];
        Notify({ type: "success", message: "没有数据了" });
        this.loading = false;
        this.finished = true;
        return;
      }
      this.loading = false; // 关闭加载状态
      this.total = data.data.count; //数据总条数
      this.list = this.list.concat(rows); //合并数组
      // console.log(this.list);
      if (this.list.length >= this.total) {
        this.finished = true; // 加载结束
      }
    },
  },
};
</script>

<style scoped>
.top {
  width: 100%;
  height: 8vh;
  /* border: 1px solid #000; */
  display: flex;
  position: fixed;
  top: 0%;
  left: 0%;
  align-items: center;
  background-color: #dbd5d5;
  z-index: 1000;
}
.box {
  background-color: #f5f5f5;
}
.list {
  width: 100%;
  background-color: #ffffff;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  margin-top: 7px;
  box-sizing: border-box;
  padding: 6px 10px;
}
.list-left {
  width: 75%;
  /* border: 1px solid red; */
  text-align: left;
}
.list-right {
  width: 24%;
  height: 6vh;
  line-height: 6vh;
  /* text-align: center; */
}
.box {
  background-color: #f3f3f3;
}
.let-one {
  font-size: 17px;
  margin-top: 5px;
}
.let-two {
  font-size: 15px;
  color: #999;
  margin-top: 3px;
}
.zhuang {
  text-align: center;
  background-color: #0052d9;
  color: white;
  border-radius: 5px;
}
.van-cell {
  margin-top: 5px !important;
}
.shai-left {
  width: 49%;
  display: flex;
  align-items: center;
}
.shai-right {
  box-sizing: border-box;
  width: 49%;
  display: flex;
  align-items: center;
}
.let {
  margin-left: 5px;
  margin-right: 6px;
}
.shai {
  width: 100%;
  height: 8vh;
  margin-top: 13%;
  display: flex;
  box-sizing: border-box;
  color: blue;
}
.shen {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.btns {
  border-radius: 5px;
  width: 40%;
}
</style>