import { render, staticRenderFns } from "./neworder.vue?vue&type=template&id=55efb6be&"
import script from "./neworder.vue?vue&type=script&lang=js&"
export * from "./neworder.vue?vue&type=script&lang=js&"
import style0 from "./neworder.vue?vue&type=style&index=0&id=55efb6be&prod&scopde=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports