<template>
  <div class="box">
    <van-overlay :show="zheshow">
      <div class="wrapper">
        <van-loading type="spinner" v-if="loading" color="#0094ff"
          >加载中....</van-loading
        >
      </div>
    </van-overlay>
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">外协事项</div>
    </div>
    <div class="from">
      <van-form @submit="onSubmit">
        <van-field
          v-model="dfrom.help_name"
          name="外协事务名称"
          label="外协事务名称"
          placeholder="外协事务名称"
          :rules="[{ required: true, message: '请输入外协事务名称' }]"
        />

        <van-field
          v-model="dfrom.help_detail"
          rows="2"
          autosize
          name="外协事务详情"
          label="外协事务详情"
          type="textarea"
          maxlength="500"
          placeholder="请输入外协事务详情"
          show-word-limit
          :rules="[{ required: true, message: '请填写外协事务详情' }]"
        />

        <van-field
          v-model="dfrom.amount"
          type="number"
          label="外协费用"
          placeholder="请输入外协费用"
          :rules="[{ required: true, message: '请输入外协费用' }]"
        />

        <van-field
          name="guuploader"
          label="支付截图"
          :rules="[{ required: true, message: '请上传支付截图' }]"
        >
          <template #input>
            <van-uploader
              v-model="guuploader"
              :after-read="guafterRead"
              :before-delete="gubefore"
            />
          </template>
        </van-field>

        <van-field
          name="guuploader"
          label="收据截图"
          :rules="[{ required: true, message: '请上传收据截图' }]"
        >
          <template #input>
            <van-uploader
              v-model="uploader"
              :after-read="afterRead"
              :before-delete="before"
            />
          </template>
        </van-field>

        <van-field name="radio" label="是否有发票">
          <template #input>
            <van-radio-group
              v-model="radio"
              direction="horizontal"
              @change="radiolick"
            >
              <van-radio name="1">有</van-radio>
              <van-radio name="2">无</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Dialog, Notify } from "vant";
import { Debounce } from "@/utils/common.js";
import { Upimg } from "@/api/upimg.js";
import { Billof } from "../../api/work.js";
export default {
  data() {
    return {
      zheshow: false,
      loading: false,
      radio: "",
      uploader: [],
      guuploader: [],
      dfrom: {
        ticket_order:'',
        matter_type: "help",
        buy_bill: "",
        help_name: "", // 外协事务名称
        help_detail: "", // 外协事务详情
        amount: "", // 外协业务费用
        pay_images: [], // 支付截图
        receipt_images: [], // 收据截图
      },
    };
  },
  created() {
    this.dfrom.ticket_order = this.$route.query.ticket_order;
    // console.log("外协事项", this.dfrom.ticket_order);
  },
  methods: {
    onSubmit: Debounce(function (values) {
      this.zheshow = true;
      this.loading = true;
      this.Billofs(this.dfrom);
    }),

    // 单选框选中
    radiolick(e) {
      // console.log(e);
      this.dfrom.buy_bill = e;
    },

    // 支付截图
    gubefore(file, detail) {
      this.dfrom.pay_images.forEach((item, index) => {
        if (detail.index === index) {
          this.dfrom.pay_images.splice(index, 1);
        }
      });
      this.guuploader.forEach((item, index) => {
        if (detail.index === index) {
          this.guuploader.splice(index, 1);
        }
      });
      // console.log(this.guuploader, this.dfrom);
    },

    // 支付截图上传
    async guafterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.zheshow = true;
      this.loading = true;
      const fd = new FormData();
      fd.append("file[]", file.file);
      const data = await Upimg(fd);
      if (data.status === 200) {
        this.zheshow = false;
        this.loading = false;
        this.dfrom.pay_images.push(data.data[0].url);
      } else {
        this.zheshow = false;
        this.loading = false;
      }
    },

    // 收据照片
    before(file, detail) {
      //   console.log(file, detail);
      this.dfrom.receipt_images.forEach((item, index) => {
        // console.log(item, index);
        if (detail.index === index) {
          this.dfrom.receipt_images.splice(index, 1);
        }
      });
      this.uploader.forEach((item, index) => {
        // console.log(item, index);
        if (detail.index === index) {
          this.uploader.splice(index, 1);
        }
        // console.log(this.uploader);
      });
      //   console.log(this.uploader, this.dfrom);
    },

    //收据图片上传
    async afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.zheshow = true;
      this.loading = true;
      const fd = new FormData();
      fd.append("file[]", file.file);
      const data = await Upimg(fd);
      if (data.status === 200) {
        this.zheshow = false;
        this.loading = false;
        this.dfrom.receipt_images.push(data.data[0].url);
      } else {
        this.zheshow = false;
        this.loading = false;
      }
    },

    // 新售后用料单交
    async Billofs(v) {
      let ss = JSON.parse(JSON.stringify(v));
      ss.amount = JSON.stringify(ss.amount * 100);
      const { data } = await Billof(ss);
      this.zheshow = false;
      this.loading = false;
      if (data.code === 200) {
        Dialog.alert({
          title: "提示",
          message: `提交成功,将返回订单详情页`,
        }).then(() => {
          // on close
          this.$router.go(-2);
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: `${data.message}请重新提交`,
        }).then(() => {
          // on close
        });
      }
    },
  },
};
</script>

<style scoped>
.box {
  background-color: #fff;
}
.from {
  margin-top: 16%;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>