<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">出库记录详情</div>
    </div>
    <div class="user">
      <div class="user-left">
        <div class="user-left-one">出库仓库</div>
        <div>{{ xianglist.house_info }}</div>
      </div>
      <div class="user-left">
        <div class="user-left-one">验收人</div>
        <div>{{ xianglist.receive_username }}</div>
      </div>
      <div class="user-left">
        <div class="user-left-one">出库人</div>
        <div>{{ xianglist.order_username }}</div>
      </div>
      <div class="user-left">
        <div class="user-left-one">出库时间</div>
        <div>{{ xianglist.order_time }}</div>
      </div>
    </div>

    <div class="biao">出库信息</div>
    <!-- <div class="list-box">
      <div class="huolist">
        <div class="huo-one">批次号</div>
        <div class="huo-onr">货物名称</div>
        <div class="huo-ont">货物详情</div>
        <div class="huo-ony">数量</div>
      </div>
      <div
        class="huolist lie"
        v-for="item in xianglist.goods_list"
        :key="item.index"
      >
        <div class="huo-one size">{{ item.batch_item_no }}</div>
        <div class="huo-onr size">{{ item.goods_name }}</div>
        <div class="huo-ont size">{{ item.goods_desc }}</div>
        <div class="huo-ony size">{{ item.goods_num }}</div>
      </div>
    </div> -->

    <section class="content">
      <div class="lings">
        <div class="item blod">批次号</div>
        <div class="item blod">货物名称</div>
        <div class="item blod">货物详情</div>
        <div class="item blod">数量</div>
      </div>
      <div v-for="(item, index) in xianglist.goods_list" :key="index" class="lings">
        <div class="item">{{ item.batch_item_no }}</div>
        <div class="item">{{ item.goods_name }}</div>
        <div class="item">{{ item.goods_desc }}</div>
        <div class="item">{{ item.goods_num }}</div>
      </div>
    </section>

    <div class="xia">
      <van-button type="info" @click="fan" class="btns">返回上一层</van-button>
    </div>
  </div>
</template>

<script>
import { Churulist } from "../api/index";
export default {
  data() {
    return {
      tablist: [],
      ids: "",
      xianglist: {},
    };
  },
  created() {
    this.Churulists();
    this.ids = this.$route.query.id; // 获取上个页面传过来的id
  },
  mounted() {},
  methods: {
    // 返回上一层
    fan() {
      this.$router.go(-1);
    },
    // 入库记录列表
    async Churulists() {
      const { data } = await Churulist({
        order_type: 2,
      });
      this.tablist = data.data.data;
      //   console.log(this.tablist);
      for (let i = 0; i < this.tablist.length; i++) {
        if (this.ids == this.tablist[i].order_id) {
          this.xianglist = this.tablist[i];
          //   console.log(this.xianglist);
        }
      }
    },
  },
};
</script>

<style scoped>
.top {
  width: 100%;
  height: 8vh;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  background-color: #dbd5d5;
}
.title {
  width: 80%;
  text-align: center;
  font-size: 24px;
}

.box {
  background-color: #f3f3f3;
}
.user {
  margin-top: 15%;
  width: 100%;
  /* border: 1px solid #000; */
  background-color: #ffffff;
  height: 25vh;
  box-sizing: border-box;
  padding: 5px 10px;
}
.user-left {
  display: flex;
  margin-top: 15px;
  align-items: center;
}
.user-left-one {
  width: 20%;
  text-align: left;
  margin-right: 10px;
}

.biao {
  width: 100%;
  height: 5vh;
  line-height: 5vh;
  box-sizing: border-box;
  padding-left: 10px;
  text-align: left;
}

.huolist {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px 5px;
}
.huo-one {
  width: 30%;
}
.huo-onr {
  width: 30%;
}

.huo-ont {
  width: 30%;
}

.huo-ony {
  width: 10%;
}
.size {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.lie {
  margin-top: 15px;
  text-align: center;
}
.list-box {
  background-color: #ffffff;
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
}
.xia {
  width: 100%;
  height: 10vh;
  margin-top: 15px;
  text-align: center;
  line-height: 10vh;
}

.content {
  margin-top: 1%;
  width: 100%;
  background-color: #fff;
}
.item {
  display: inline-block;
  margin: 10px 0px;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
  padding: 5px 5px;
}
.lings {
  display: flex;
  flex-wrap: nowrap;
}
.lings :nth-of-type(1) {
  width: 30%;
  word-break: break-all
}
.lings :nth-of-type(2) {
  width: 30%;
}
.lings :nth-of-type(3) {
  width: 30%;
}
.lings :nth-of-type(4) {
  width: 15%;
}
.blod {
  font-weight: bold;
}
</style>