<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">售后订单管理</div>
    </div>
    <div class="ping">
      <van-button type="info" @click="neworder" class="sss"
        >新建售后订单</van-button
      >
      <van-button type="info" class="sss" @click="screen">筛选</van-button>
    </div>

    <van-popup
      class="popups"
      v-model="showPopup"
      position="right"
      :style="{ width: '80%', height: '100%' }"
    >
      <div>
        <van-overlay :show="zheshow">
          <div class="wrapper">
            <van-loading type="spinner" v-if="loading" color="#0094ff"
              >加载中....</van-loading
            >
          </div>
        </van-overlay>
        <div class="tit">筛选</div>
        <div class="time">
          <van-cell title="选择日期区间" :value="date" @click="show = true" />
          <van-calendar
            :min-date="minDate"
            v-model="show"
            type="range"
            @confirm="onConfirm"
            color="#1989fa"
          />
        </div>
      </div>
      <div class="hint">维修类型</div>
      <div class="an">
        <van-button
          @click="contract"
          :class="{ active: ss == 'contract' }"
          class="ssss"
          >合同维修</van-button
        >
        <van-button @click="temp" :class="{ active: ss == 'temp' }" class="ssss"
          >临时维修</van-button
        >
      </div>

      <div style="margin-top: 15px">
        <van-field
          class="inpding"
          v-model="client"
          name="客户名称"
          label="客户名称"
          placeholder="请填写客户名称"
          @input="inps"
        />

        <div class="suggest" v-show="flag" style="overflow: scroll">
          <ul>
            <li
              v-for="(item, index) in this.soulist"
              :key="index"
              @click="liClick(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="an ods">
        <van-button type="info" class="sss" @click="chongzhi">重 置</van-button>
        <van-button type="info" class="sss" @click="pantys">确 定</van-button>
      </div>
    </van-popup>

    <van-overlay :show="zheshow">
      <div class="wrapper">
        <van-loading type="spinner" v-if="loading" color="#0094ff"
          >加载中....</van-loading
        >
      </div>
    </van-overlay>

    <!-- <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell v-for="(item, index) in list" :key="index">
        <template>
          <div class="list" @click="xiang(item.order_id)">
            <div class="list-left">
              <div class="let-one">订单编号:{{ item.ticket_order_no }}</div>
              <div class="let-two">客户名称:{{ item.customer_name }}</div>
              <div class="let-two">维修类型:{{ item.maintain_type_desc }}</div>
              <div class="let-two">进行耗时:{{ item.waiting_days }}</div>
            </div>
          </div>
        </template>
      </van-cell>
    </van-list> -->
    <van-cell v-for="(item, index) in list" :key="index">
      <template>
        <div class="list" @click="xiang(item.order_id)">
          <div class="list-left">
            <div class="let-one">订单编号:{{ item.ticket_order_no }}</div>
            <div class="let-two">客户名称:{{ item.customer_name }}</div>
            <div class="let-two">维修类型:{{ item.maintain_type_desc }}</div>
            <div class="let-two">进行耗时:{{ item.waiting_days }}</div>
          </div>
        </div>
      </template>
    </van-cell>
    <van-loading color="#0094ff" v-show="logs" />
    <div style="fontsize: 12px" v-if="this.list.length == this.total">
      没有更多了
    </div>
  </div>
</template>

<script>
import { Notify, loading } from "vant";
import { Debounce } from "@/utils/common.js";
import { Orderlist, Client } from "../../api/work.js";
export default {
  data() {
    return {
      minDate: new Date(2022, 0, 1),
      soulist: [],
      client: "", // 客户名称
      flag: false,
      date: "",
      show: false,
      showPopup: false,
      zheshow: false,
      loading: false,
      list: [], //列表
      finished: false, // 是否加载结束
      pageNo: 1, // 页码值
      pageSize: 10, //条数
      total: "", // 数据总条数
      from_time: "", // 开始时间
      end_time: "", // 结束时间
      ss: "",
      maintain_type: "",
      customer_id: "",
      logs: true,
    };
  },
  created() {
    this.Orderlists();
  },
  activated() {
    this.chongzhi();
    console.log("触发了");
    this.Orderlists();
  },
  mounted() {
    window.addEventListener("scroll", this.fanggetScroll);
    let windowHeight =
      document.documentElement.clientHeight || document.body.clientHeight; //变量windowHeight是 可视区的高度
    let scrollHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight; //变量scrollHeight是 滚动条的总高度 /可视化的高度与溢出的距离（总高度）
    if (windowHeight < scrollHeight) {
      this.Fullscreen = false; //是否满屏
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.fanggetScroll);
  },
  methods: {
    fanggetScroll: Debounce(function () {
      this.getScroll();
    }, 600),

    getScroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; //变量scrollTop是滚动条滚动时，距离顶部的距离
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight; //变量windowHeight是 可视区的高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight; //变量scrollHeight是 滚动条的总高度 /可视化的高度与溢出的距离（总高度）
      //滚动条到底部的条件
      setTimeout(() => {
        console.log("触底了");

        if (scrollTop + windowHeight + 150 >= scrollHeight) {
          if (this.list.length == this.total) {
            return;
          } else {
            console.log("到条件了，请求数据");
            this.logs = true;
            this.pageNo = this.pageNo + 1;
            this.Orderlists();
          }
        }
      }, 500);
    },

    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.from_time = `${start.getFullYear()}-${
        start.getMonth() + 1
      }-${start.getDate()}`;
      this.end_time = `${end.getFullYear()}-${
        end.getMonth() + 1
      }-${end.getDate()}`;
    },

    // 输入框事件
    inps: Debounce(function (value) {
      if (this.client !== "") {
        this.soulist = [];
        this.Fenzusou(value);
        this.zheshow = true;
        this.loading = true;
      } else {
        this.soulist = [];
        this.flag = false;
      }
    }, 600),

    // 客户搜索
    async Fenzusou(name) {
      const { data } = await Client({ name: name, check_all: 1 });
      this.zheshow = false;
      this.loading = false;
      if (data.data.length !== 0) {
        this.flag = true;
        this.soulist = data.data.list;
      } else {
        Notify({ type: "success", message: "没有找到此设备" });
        this.soulist = [];
        this.flag = false;
      }
    },

    // 选中搜索后的数据
    liClick(item) {
      // console.log(item);
      this.customer_id = item.id;
      this.client = item.name;
      this.flag = false;
    },

    // 筛选
    screen() {
      this.showPopup = true;
    },

    // 重置
    chongzhi() {
      this.date = "";
      this.ss = "";
      this.from_time = "";
      this.end_time = "";
      this.maintain_type = "";
      this.customer_id = "";
      this.client = "";
      this.pageNo = 1; // 页码值
      this.pageSize = 10;
      this.Orderlists();
    },

    // 确定筛选
    pantys() {
      this.showPopup = false;
      this.list = [];
      this.pageNo = 1; // 页码值
      this.pageSize = 10;
      this.zheshow = true;
      this.loading = true;
      this.Orderlists();
    },

    // 新建订单
    neworder() {
      this.$router.push({
        path: `/neworder`,
      });
    },

    // 筛选选中合同维修
    contract() {
      this.ss = "contract";
      this.maintain_type = "contract";
    },

    // 筛选选中临时维修
    temp() {
      this.ss = "temp";
      this.maintain_type = "temp";
    },

    // 订单详情
    xiang(order_id) {
      console.log(order_id, "售后订单管理页");
      this.$router.push({
        path: `/orderdetail?id=${order_id}`,
      });
    },

    onLoad() {
      this.pageNo + 1;
      this.Orderlists();
    },

    async Orderlists() {
      const { data } = await Orderlist({
        page: this.pageNo,
        size: this.pageSize,
        customer_id: this.customer_id,
        maintain_type: this.maintain_type,
        from_time: this.from_time,
        end_time: this.end_time,
      });
      this.logs = false;
      this.loading = false;
      this.zheshow = false;
      this.total = data.data.count; //数据总条数
      if (data.data.data.length == 0) {
        this.logs = false;
        this.loading = false;
        this.zheshow = false;
        Notify({ type: "success", message: "没有数据了" });
      } else {
        this.loading = false;
        this.zheshow = false;
        if (this.pageNo == 1) {
          this.list = data.data.data;
          console.log("6666");
        } else {
          console.log("8888");
          const datas = data.data.data;
          for (let i = 0; i < datas.length; i++) {
            this.list.push(datas[i]);
          }
        }
      }
    },
  },
};
</script>

<style scopde lang="less">
.box {
  background-color: #fff;
}
.top {
  background-color: #dbd5d5;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
.ping {
  margin-top: 20%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  height: 8vh;
  text-align: left;
  line-height: 7vh;
  padding-left: 10px;
}
.btns {
  border-radius: 10px;
}
.list {
  width: 100%;
  background-color: #ffffff;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  margin-top: 7px;
  box-sizing: border-box;
  padding: 6px 10px;
}
.list-left {
  width: 75%;
  /* border: 1px solid red; */
  text-align: left;
}
.list-right {
  width: 24%;
  height: 6vh;
  line-height: 6vh;
  /* text-align: center; */
}
.box {
  background-color: #f3f3f3;
}
.let-one {
  font-size: 14px;
  margin-top: 5px;
}
.let-two {
  font-size: 15px;
  color: #999;
  margin-top: 3px;
}
.zhuang {
  text-align: center;
  background-color: #0052d9;
  color: white;
  border-radius: 5px;
}
.van-cell {
  margin-top: 5px !important;
}
.active {
  color: white !important;
  // border: 1px solid red !important;
  font-weight: bold;
  background-color: #66b1ff !important;
}
.an {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.ssss {
  width: 40% !important;
  border-radius: 5px !important;
  border: 1px solid #999 !important;
}
.sss {
  width: 35% !important;
  border-radius: 5px !important;
}
.hint {
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  padding-left: 15px;
}
.time {
  width: 100%;
  text-align: left;
}
.tit {
  margin-top: 5%;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.ods {
  position: fixed;
  bottom: 5%;
  left: 0%;
}
.popups {
  font-size: 14px !important;
}
</style>