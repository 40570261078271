<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">添加货物信息</div>
    </div>
    <div class="nav">
      <van-loading type="spinner" color="#1989fa" v-if="loading" />
      <van-form @submit="submit">
        <van-field
          v-model="dfrom.goods_name"
          name="goods_name"
          label="货物名称"
          placeholder="货物名称"
          :rules="[{ required: true, message: '请填写货物名称' }]"
        />

        <van-field
          v-model="type"
          is-link
          readonly
          label="货物类型"
          name="type"
          placeholder="请选择货物类型"
          @click="show = true"
        />
        <van-popup v-model="show" round closeable position="bottom">
          <van-cascader
            v-model="cascaderValue"
            title="货物类型"
            :options="options"
            :field-names="fieldNames"
            @finish="onFinish"
          />
        </van-popup>

        <van-field
          v-model="dfrom.goods_desc"
          name="goods_desc"
          label="货物描述"
          placeholder="货物描述"
        />
        <van-field
          v-model="dfrom.goods_remark"
          name="goods_remark"
          label="货物备注"
          placeholder="货物备注"
        />

        <!--  -->
        <van-field
          readonly
          clickable
          name="unit"
          :value="unit"
          label="货物单位"
          placeholder="点击选择货物单位"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            value-key="name"
            :columns="danwei"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <!--  -->

        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { Huotree, Huogoods } from "../../api/store.js";
export default {
  data() {
    return {
      loading: false,
      showPicker: false,
      cascaderValue: "",
      show: false,
      options: [],
      danwei: [
        { name: "请选择", value: "" },
        { name: "个", value: 1 },
        { name: "包", value: 2 },
        { name: "公斤", value: 3 },
        { name: "克", value: 4 },
      ],
      type: "",
      unit: "",
      fieldNames: {
        text: "title",
        value: "id",
        children: "list",
      },

      dfrom: {
        goods_name: "",
        goods_desc: "",
        goods_remark: "",
        unit: "",
        type: "",
      },
    };
  },
  created() {
    this.Huotrees();
  },
  methods: {
    submit(values) {
      // console.log("submit", values);
      // console.log(this.dfrom);
      this.Huogoodss();
      this.loading = true
    },

    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.type = selectedOptions[selectedOptions.length - 1].title;
      this.dfrom.type = selectedOptions[selectedOptions.length - 1].id;
      // console.log(selectedOptions);
    },

    onConfirm(value) {
      // console.log(value);
      this.dfrom.unit = value.value;
      this.unit = value.name;
      this.showPicker = false;
    },

    // 拉取货物类型树
    async Huotrees() {
      const { data } = await Huotree();
      //   console.log(data);
      this.options = data.data;
    },

    async Huogoodss() {
      const { data } = await Huogoods({ ...this.dfrom });
      // console.log(data);
      this.loading = false
      if (data.code === 200) {
        Dialog.alert({
          title: "提示",
          message: `添加货物信息成功`,
        }).then(() => {
          // on close
          this.$router.go(-1);
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: `添加货物信息失败,${data.message}`,
        }).then(() => {
          this.type = "";
          this.unit = "";
          this.dfrom.goods_name = "";
          this.dfrom.goods_desc = "";
          this.dfrom.goods_remark = "";
          this.dfrom.unit = "";
          this.dfrom.type = "";
        });
      }
    },
  },
};
</script>

<style scoped>
.nav {
  margin-top: 16%;
}
</style>