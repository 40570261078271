<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="oks" />
      <div class="title">货物选择</div>
    </div>
    <van-search
      class="inp"
      v-model="inp"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="swipes">
      <van-swipe :loop="false" :width="80" :show-indicators="false">
        <van-swipe-item
          :class="{ active: ss == item.title }"
          v-for="(item, index) in list"
          :key="index"
          @click="dao(item.list, item)"
          >{{ item.title }}</van-swipe-item
        >
      </van-swipe>
    </div>
    <van-loading type="spinner" color="#1989fa" v-if="loading" />
    <div class="main">
      <div class="main-left" style="overflow: scroll">
        <van-sidebar v-model="activeKey">
          <van-sidebar-item
            class="let-title"
            v-for="(items, index) in arr"
            :key="index"
            :title="items.title"
            @click="onChange(items)"
          />
        </van-sidebar>
      </div>
      <div class="main-right" style="overflow: scroll">
        <div class="content" v-for="(it, index) in rightlist" :key="index">
          <div class="content-left">
            <img
              src="https://img01.yzcdn.cn/vant/ipad.jpeg"
              class="pic"
              alt=""
            />
          </div>
          <div class="content-right">
            <div>
              <div class="mingmu-title">{{ it.goods_name }}</div>
              <div class="mingmu-title bot">货物详情：</div>
              <div class="mingmu-xiang">{{ it.goods_desc }}</div>
            </div>
            <van-stepper
              v-model="it.require_num"
              min="0"
              theme="round"
              button-size="22"
              disable-input
              @plus="plus(it)"
              @minus="minus(it, index)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bottms">
      <van-icon name="shopping-cart" size="45" />
      <div class="navs" @click="che">
        <div>共</div>
        <div class="zhongs">{{ totalCount }}</div>
        <div>件</div>
      </div>
      <van-goods-action-button type="danger" text="确定" @click="oks" />
    </div>

    <van-popup
      v-model="bottomshow"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="mains">
        <div class="xu bold">序号</div>
        <div class="name bold">名称</div>
        <div class="nums bold">需求数量</div>
      </div>

      <div class="mains" v-for="(item, index) in this.parts" :key="index">
        <div class="xu bold">{{ index + 1 }}</div>
        <div class="name bold">{{ item.goods_name }}</div>
        <div class="nums">
          <van-stepper
            v-model="item.require_num"
            min="0"
            theme="round"
            button-size="22"
            disable-input
            @plus="plus(item)"
            @minus="minus(item, index)"
          />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Icon, Notify } from "vant";
import { Fenzu } from "../../api/store";
export default {
  data() {
    return {
      inp: "",
      activeKey: 0,
      list: [],
      arr: [],
      rightlist: [],
      ss: "",
      loading: true,
      parts: [], // 配件清单
      bottomshow: false,
    };
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => {
      this.unloadFn(e);
    });
  },

  destroyed() {
    window.removeEventListener("beforeunload", (e) => {
      this.unloadFn(e);
    });
  },
  created() {
    for (let i = 0; i < this.parts.length; i++) {
      this.parts[i].require_num = 0;
    }

    this.parts = [];
    this.Fenzulist();
  },

  beforeRouteLeave(to, from, next) {
    to.meta.keepAlive = true;
    next(0);
  },
  activated() {
    let ss = JSON.parse(sessionStorage.getItem("sle"));
    // console.log(ss);
    if (ss === null) {
      // this.Fenzulist();
      // if (location.href.indexOf("#reloaded") == -1) {
      //   location.href = location.href + "#reloaded";
      //   location.reload();
      // }

      // console.log("进来了进来了");
      for (let i = 0; i < this.parts.length; i++) {
        this.parts[i].require_num = 0;
      }

      this.parts = [];
    } else {
      // console.log("2222");
    }
  },
  computed: {
    totalCount() {
      var total = 0;
      this.parts.forEach((item) => {
        total += item.require_num;
      });
      return total;
    },
  },
  methods: {
    unloadFn() {
      // console.log("浏览器刷新了");
      sessionStorage.clear();
    },
    // 主页点击增加按钮触发
    plus(item) {
      // console.log(item);
      if (this.parts.length === 0) {
        this.parts.push(item);
        item.require_num++;
      } else {
        let pos = -1;
        for (let i = 0; i < this.parts.length; i++) {
          if (item.id === this.parts[i].id) {
            pos = i;
            this.parts[i].require_num += 1;
            break;
          }
        }
        if (pos === -1) {
          item.require_num++;
          this.parts.push(item);
        }
      }
      // console.log(this.parts);
    },

    // 主页点击减少按钮
    minus(item, index) {
      // console.log(item, index);
      for (let i = 0; i < this.parts.length; i++) {
        if (item.id === this.parts[i].id) {
          if (this.parts[i].require_num > 0) {
            this.parts[i].require_num--;
            if (this.parts[i].require_num === 0) {
              Notify({ type: "danger", message: "已经减完了" });
              this.parts.splice(i, 1);
            }
          }
        }
      }
    },

    oks() {
      // console.log("点击了确定");
      // console.log("确定");
      var sle = {
        data: "222",
        parts: this.parts,
      };
      sessionStorage.setItem("sle", JSON.stringify(sle));
      this.$router.go(-1);
    },

    // 添加货物类型
    fromtype() {
      this.$router.push({
        path: `/fromtype`,
      });
    },

    // 货物信息
    fromlist() {
      this.$router.push({
        path: `/fromlist`,
      });
    },

    // 点击购物车
    che() {
      this.bottomshow = true;
    },

    // 点击搜索
    onSearch(inp) {
      // console.log(this.inp);
      this.Fenzusou(this.inp);
      this.loading = true;
    },
    // 点击侧边导航
    onChange(items) {
      this.rightlist = items.list;
    },

    // 点击轮播导航
    dao(itemlist, os) {
      this.ss = os.title;
      this.arr.length = 0;
      for (let i = 0; i < itemlist.length; i++) {
        for (let j = 0; j < itemlist[i].list.length; j++) {
          this.arr.push(itemlist[i].list[j]);
        }
      }
    },
    // 供应商分组搜索
    async Fenzulist() {
      const { data } = await Fenzu();
      this.list = data.data;
      let itemlist = data.data[0].list;
      for (let i = 0; i < itemlist.length; i++) {
        for (let j = 0; j < itemlist[i].list.length; j++) {
          this.arr.push(itemlist[i].list[j]);
        }
      }
      this.rightlist = this.arr[0].list;
      this.ss = this.list[0].title;
      this.loading = false;
    },

    async Fenzusou(name) {
      const { data } = await Fenzu({ name: name });
      this.loading = false;
      if (data.data.length !== 0) {
        this.rightlist.length = 0;
        let soudata = data.data;
        for (let i = 0; i < soudata.length; i++) {
          for (let j = 0; j < soudata[i].list.length; j++) {
            for (let k = 0; k < soudata[i].list[j].list.length; k++) {
              for (
                let index = 0;
                index < soudata[i].list[j].list[k].list.length;
                index++
              ) {
                this.rightlist.push(soudata[i].list[j].list[k].list[index]);
              }
            }
          }
        }
      } else {
        Notify({ type: "success", message: "没有找到此配件" });
      }
    },
  },
};
</script>

<style scoped  lang="less">
.swipes {
  width: 100%;
  height: 50px;
  line-height: 50px;
  /* border: 1px solid #000; */
}
.main {
  width: 100%;
  height: 100%;
  /* border: 1px solid #000; */
  display: flex;
}
.main-left {
  width: 25%;
  min-width: 25%;
  height: 90vh;
  /* border: 1px solid red; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main-right {
  width: 74%;
  height: 90vh;
  /* border: 1px solid cornflowerblue; */
}
.van-swipe-item {
  height: 47px !important;
  width: 80px !important;
  background-color: #f7f8fa;
}
.box {
  background-color: white;
}
.let-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content {
  width: 100%;
  /* border: 1px solid #000; */
  height: 160px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #f7f8fa;
  margin-top: 10px;
}
.content-left {
  min-width: 40%;
  width: 40%;
  height: 75%;
  line-height: 60%;
}
.pic {
  width: 100%;
  height: 100%;
}
.content-right {
  box-sizing: border-box;
  padding: 20px 0px;
  width: 59%;
  height: 100%;
  text-align: center;
}
.content-right-title {
  text-align: left;
  width: 100%;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.right-top-cang {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content-right-top {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 5%;
}
.active {
  color: red;
  background-color: white;
  overflow: hidden;
  font-weight: bold;
}
.top {
  background-color: #dbd5d5;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 1000;
}
.inp {
  margin-top: 15%;
}
.tian {
  margin-top: 18%;
  display: flex;
  justify-content: space-around;
}
.btns {
  border-radius: 5px;
}
.mingmu-title {
  width: 100%;
  text-align: left;
  font-size: 13px;
}
.mingmu-xiang {
  width: 80%;
  font-size: 13px;
  margin-top: 5px;
  text-align: left;
  height: 6vh;
  /* background-color: red; */
}
.bot {
  margin-top: 10px;
}
.bottms {
  padding-left: 10px;
  padding-right: 20px;
  height: 10vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0%;
  left: 0%;
  /* border: 1px solid #000; */
  background-color: #dbd5d5;
}
.zhongs {
  width: 30%;
  height: 6vh;
  border: 1px solid #000;
  line-height: 6vh;
  border-radius: 60%;
  margin-left: 5px;
  margin-right: 5px;
}
.navs {
  box-sizing: border-box;
  width: 40%;
  display: flex;
  align-items: center;
}
.mains {
  height: 6vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .xu {
    width: 20%;
  }
  .name {
    width: 60%;
  }
  .num {
    width: 20%;
  }
  .nums {
    width: 30%;
  }
}
.bold {
  font-weight: bold;
}
</style>