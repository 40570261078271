<template>
  <div class="box">
    <div class="top">
      <van-icon name="arrow-left" :size="38" @click="$router.go(-1)" />
      <div class="title">审批</div>
    </div>

    <div class="nav">
      <div class="user">
        <div class="user-left">
          <div class="user-left-one">申请人</div>
          <div>{{ username }}</div>
        </div>
        <div class="user-left" @click="tan">
          <div class="user-left-one">出库仓库</div>
          <div>{{ cangname }}</div>
        </div>
        <div class="user-left">
          <div class="user-left-one">审批人</div>
          <div>{{ shenname }}</div>
        </div>
      </div>
      <van-popup
        v-model="show"
        position="bottom"
        :style="{ height: '30%' }"
        close-on-click-overlay
      >
        <van-picker
          title="选择仓库"
          value-key="name"
          show-toolbar
          :columns="cang"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
    </div>
    <div class="hint">货物信息</div>
    <section class="content">
      <div class="lings">
        <div class="item blod">序号</div>
        <div class="item blod">货物编号</div>
        <div class="item blod">货物名称</div>
        <div class="item blod">货物详情</div>
        <div class="item blod">调出仓库</div>
        <div class="item blod">可用数量</div>
        <div class="item blod">需求数量</div>
        <div class="item blod">调配数量</div>
      </div>
      <!-- <div v-for="(item, index) in this.output" :key="index" class="lings">
        <div class="item">{{ index + 1 }}</div>
        <div class="item">{{ item.goods_no }}</div>
        <div class="item">{{ item.goods_name }}</div>
        <div class="item">{{ item.goods_desc }}</div>
        <div class="item">{{ item.house_name }}</div>
        <div class="item">{{ item.output_num }}</div>
        <div class="item">{{ item.time }}</div>
        <div class="item">{{ item.time }}</div>
      </div> -->
    </section>
    <div class="btns">
      <!--这是一个按钮，赋予click事件-->
      <van-button type="info" @click="onClick" class="on">通 过</van-button>
      <van-button type="danger" @click="juonClick" class="on">拒 绝</van-button>
      <!--弹出框，赋予chargeBtn事件-->
      <van-dialog
        v-model="shows"
        title="通过"
        show-cancel-button
        :beforeClose="chargeBtn"
      >
        <!--输入框-->
        <van-field
          v-model="message"
          rows="1"
          autosize
          label="同意审核备注"
          type="textarea"
          placeholder="同意审核备注"
        />
      </van-dialog>
      <van-dialog
        v-model="jushows"
        title="拒绝"
        show-cancel-button
        :beforeClose="juchargeBtn"
      >
        <!--输入框-->
        <van-field
          v-model="jumessage"
          rows="1"
          autosize
          label="拒绝审核备注"
          type="textarea"
          placeholder="拒绝审核备注"
        />
      </van-dialog>
    </div>
  </div>
</template>

<script>
import { Cang } from "../../api/index.js";
export default {
  data() {
    return {
      username: "申请人",
      show: false,
      cang: [],
      cangname: "",
      shenname: "郑创鑫",
      shows: false,
      message: "",
      jushows: false,
      jumessage: "",
    };
  },
  created() {
    this.Canglist();
  },
  methods: {
    onClick() {
      //当点击按钮时，显示弹出框
      this.shows = true;
    },
    chargeBtn(action, done) {
      //确认or取消
      if (action === "cancel") {
        //取消按钮
        done();
      } else if (action === "confirm") {
        //确定按钮
        //向后端传值并关闭dialog弹出框
        this.shows = false;
        //  console.log(this.message);
      }
      this.message = "";
      done();
    },

    juonClick() {
      //当点击按钮时，显示弹出框
      this.jushows = true;
    },

    juchargeBtn(action, done) {
      //确认or取消
      if (action === "cancel") {
        //取消按钮
        done();
      } else if (action === "confirm") {
        //确定按钮
        //向后端传值并关闭dialog弹出框
        // console.log(this.jumessage);
        this.jushows = false;
      }
      this.jumessage = "";
      done();
    },

    // 弹出层
    tan() {
      this.show = true;
    },

    // 仓库选中
    onConfirm(val) {
      console.log(val);
      this.show = false;
      this.cangname = val.name;
    },

    onCancel() {
      this.show = false;
    },

    // 拉取仓库列表
    async Canglist() {
      const { data } = await Cang();
      this.cang = data.data;
    },
  },
};
</script>

<style scoped>
.user {
  height: 19vh;
}
.content {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  background-color: #fff;
}
.item {
  display: inline-block;
  margin: 10px;
  height: 3vh;
  white-space: pre-wrap;
  font-size: 12px;
  text-align: center;
}
.lings :nth-of-type(1) {
  width: 8%;
}
.lings :nth-of-type(2) {
  width: 27%;
}
.lings :nth-of-type(3) {
  width: 30%;
}
.lings :nth-of-type(4) {
  width: 30%;
}
.lings :nth-of-type(5) {
  width: 30%;
}
.lings :nth-of-type(6) {
  width: 15%;
}
.lings :nth-of-type(7) {
  width: 15%;
}
.lings :nth-of-type(8) {
  width: 25%;
}
.blod {
  font-weight: bold;
}
.hint {
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  text-align: left;
  font-size: 14px;
  margin: 20px 0px;
  color: red;
  font-weight: bold;
}
.btns {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
}
.on {
  width: 40%;
  border-radius: 5px;
}
</style>